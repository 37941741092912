import React from 'react';
import './DonateButton.css';

const DonateButton = () => {

  return (
    <div>
        <br/>
        <br/>
      <small>You can support using Payment for maintaining this site expenses. </small> <br/><br/>Follow Our UPI ID:<br/>
      <b className='yellow_color' >bible@upi</b>&ensp;&ensp;&ensp;|&ensp;&ensp;&ensp;<b className='yellow_color' >9444414229@ptyes</b><br />
      <b>
        Name: Jegan.M&ensp; <small>(Admin & Tamil Bible) </small> 
        </b>
      <br/>
      <br/>
    <div>
      <br/>
    </div>
    </div>
  );
};

export default DonateButton;
