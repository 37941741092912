import logo from './logo.png';
import { IonIcon } from '@ionic/react';
import './App.css';
import React, { lazy , Suspense } from 'react';
import { BrowserRouter as Router, Link, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from './components/home';
import BibleIndex from './components/bibleindex';
import Audiobible from './components/AudioBible';
import Social from './components/Social';
import Contact from './components/contact';
import Downloads from './components/Downloads';
import Privacy from './components/privacy_policy';


import { logoFacebook } from 'ionicons/icons';
import { logoTwitter } from 'ionicons/icons';
import { logoYoutube } from 'ionicons/icons';
import { send } from 'ionicons/icons';

import Ecclesiastes    from './components/Ecclesiastes/Ecclesiastes';
import Ecclesiastes1   from './components/Ecclesiastes/Ecclesiastes1';
import Ecclesiastes2   from './components/Ecclesiastes/Ecclesiastes2';
import Ecclesiastes3   from './components/Ecclesiastes/Ecclesiastes3';
import Ecclesiastes4   from './components/Ecclesiastes/Ecclesiastes4';
import Ecclesiastes5   from './components/Ecclesiastes/Ecclesiastes5';
import Ecclesiastes6   from './components/Ecclesiastes/Ecclesiastes6';
import Ecclesiastes7   from './components/Ecclesiastes/Ecclesiastes7';
import Ecclesiastes8   from './components/Ecclesiastes/Ecclesiastes8';
import Ecclesiastes9   from './components/Ecclesiastes/Ecclesiastes9';
import Ecclesiastes10  from './components/Ecclesiastes/Ecclesiastes10';
import Ecclesiastes11  from './components/Ecclesiastes/Ecclesiastes11';
import Ecclesiastes12  from './components/Ecclesiastes/Ecclesiastes12';

import Songs    from './components/Song/Song';
import Songs1   from './components/Song/Song1';
import Songs2   from './components/Song/Song2';
import Songs3   from './components/Song/Song3';
import Songs4   from './components/Song/Song4';
import Songs5   from './components/Song/Song5';
import Songs6   from './components/Song/Song6';
import Songs7   from './components/Song/Song7';
import Songs8   from './components/Song/Song8';

import Isaiah    from './components/Isaiah/Isaiah';
import Isaiah1   from './components/Isaiah/Isaiah1';
import Isaiah2   from './components/Isaiah/Isaiah2';
import Isaiah3   from './components/Isaiah/Isaiah3';
import Isaiah4   from './components/Isaiah/Isaiah4';
import Isaiah5   from './components/Isaiah/Isaiah5';
import Isaiah6   from './components/Isaiah/Isaiah6';
import Isaiah7   from './components/Isaiah/Isaiah7';
import Isaiah8   from './components/Isaiah/Isaiah8';
import Isaiah9   from './components/Isaiah/Isaiah9';
import Isaiah10  from './components/Isaiah/Isaiah10';
import Isaiah11  from './components/Isaiah/Isaiah11';
import Isaiah12  from './components/Isaiah/Isaiah12';
import Isaiah13  from './components/Isaiah/Isaiah13';
import Isaiah14  from './components/Isaiah/Isaiah14';
import Isaiah15  from './components/Isaiah/Isaiah15';
import Isaiah16  from './components/Isaiah/Isaiah16';
import Isaiah17  from './components/Isaiah/Isaiah17';
import Isaiah18  from './components/Isaiah/Isaiah18';
import Isaiah19  from './components/Isaiah/Isaiah19';
import Isaiah20  from './components/Isaiah/Isaiah20';
import Isaiah21  from './components/Isaiah/Isaiah21';
import Isaiah22  from './components/Isaiah/Isaiah22';
import Isaiah23  from './components/Isaiah/Isaiah23';
import Isaiah24  from './components/Isaiah/Isaiah24';
import Isaiah25  from './components/Isaiah/Isaiah25';
import Isaiah26  from './components/Isaiah/Isaiah26';
import Isaiah27  from './components/Isaiah/Isaiah27';
import Isaiah28  from './components/Isaiah/Isaiah28';
import Isaiah29  from './components/Isaiah/Isaiah29';
import Isaiah30  from './components/Isaiah/Isaiah30';
import Isaiah31  from './components/Isaiah/Isaiah31';
import Isaiah32  from './components/Isaiah/Isaiah32';
import Isaiah33  from './components/Isaiah/Isaiah33';
import Isaiah34  from './components/Isaiah/Isaiah34';
import Isaiah35  from './components/Isaiah/Isaiah35';
import Isaiah36  from './components/Isaiah/Isaiah36';
import Isaiah37  from './components/Isaiah/Isaiah37';
import Isaiah38  from './components/Isaiah/Isaiah38';
import Isaiah39  from './components/Isaiah/Isaiah39';
import Isaiah40  from './components/Isaiah/Isaiah40';
import Isaiah41  from './components/Isaiah/Isaiah41';
import Isaiah42  from './components/Isaiah/Isaiah42';
import Isaiah43  from './components/Isaiah/Isaiah43';
import Isaiah44  from './components/Isaiah/Isaiah44';
import Isaiah45  from './components/Isaiah/Isaiah45';
import Isaiah46  from './components/Isaiah/Isaiah46';
import Isaiah47  from './components/Isaiah/Isaiah47';
import Isaiah48  from './components/Isaiah/Isaiah48';
import Isaiah49  from './components/Isaiah/Isaiah49';
import Isaiah50  from './components/Isaiah/Isaiah50';
import Isaiah51  from './components/Isaiah/Isaiah51';
import Isaiah52  from './components/Isaiah/Isaiah52';
import Isaiah53  from './components/Isaiah/Isaiah53';
import Isaiah54  from './components/Isaiah/Isaiah54';
import Isaiah55  from './components/Isaiah/Isaiah55';
import Isaiah56  from './components/Isaiah/Isaiah56';
import Isaiah57  from './components/Isaiah/Isaiah57';
import Isaiah58  from './components/Isaiah/Isaiah58';
import Isaiah59  from './components/Isaiah/Isaiah59';
import Isaiah60  from './components/Isaiah/Isaiah60';
import Isaiah61  from './components/Isaiah/Isaiah61';
import Isaiah62  from './components/Isaiah/Isaiah62';
import Isaiah63  from './components/Isaiah/Isaiah63';
import Isaiah64  from './components/Isaiah/Isaiah64';
import Isaiah65  from './components/Isaiah/Isaiah65';
import Isaiah66  from './components/Isaiah/Isaiah66';

import  Jeremiah    from './components/Jeremiah/Jeremiah';
import  Jeremiah1   from './components/Jeremiah/Jeremiah1';
import  Jeremiah2   from './components/Jeremiah/Jeremiah2';
import  Jeremiah3   from './components/Jeremiah/Jeremiah3';
import  Jeremiah4   from './components/Jeremiah/Jeremiah4';
import  Jeremiah5   from './components/Jeremiah/Jeremiah5';
import  Jeremiah6   from './components/Jeremiah/Jeremiah6';
import  Jeremiah7   from './components/Jeremiah/Jeremiah7';
import  Jeremiah8   from './components/Jeremiah/Jeremiah8';
import  Jeremiah9   from './components/Jeremiah/Jeremiah9';
import  Jeremiah10  from './components/Jeremiah/Jeremiah10';
import  Jeremiah11  from './components/Jeremiah/Jeremiah11';
import  Jeremiah12  from './components/Jeremiah/Jeremiah12';
import  Jeremiah13  from './components/Jeremiah/Jeremiah13';
import  Jeremiah14  from './components/Jeremiah/Jeremiah14';
import  Jeremiah15  from './components/Jeremiah/Jeremiah15';
import  Jeremiah16  from './components/Jeremiah/Jeremiah16';
import  Jeremiah17  from './components/Jeremiah/Jeremiah17';
import  Jeremiah18  from './components/Jeremiah/Jeremiah18';
import  Jeremiah19  from './components/Jeremiah/Jeremiah19';
import  Jeremiah20  from './components/Jeremiah/Jeremiah20';
import  Jeremiah21  from './components/Jeremiah/Jeremiah21';
import  Jeremiah22  from './components/Jeremiah/Jeremiah22';
import  Jeremiah23  from './components/Jeremiah/Jeremiah23';
import  Jeremiah24  from './components/Jeremiah/Jeremiah24';
import  Jeremiah25  from './components/Jeremiah/Jeremiah25';
import  Jeremiah26  from './components/Jeremiah/Jeremiah26';
import  Jeremiah27  from './components/Jeremiah/Jeremiah27';
import  Jeremiah28  from './components/Jeremiah/Jeremiah28';
import  Jeremiah29  from './components/Jeremiah/Jeremiah29';
import  Jeremiah30  from './components/Jeremiah/Jeremiah30';
import  Jeremiah31  from './components/Jeremiah/Jeremiah31';
import  Jeremiah32  from './components/Jeremiah/Jeremiah32';
import  Jeremiah33  from './components/Jeremiah/Jeremiah33';
import  Jeremiah34  from './components/Jeremiah/Jeremiah34';
import  Jeremiah35  from './components/Jeremiah/Jeremiah35';
import  Jeremiah36  from './components/Jeremiah/Jeremiah36';
import  Jeremiah37  from './components/Jeremiah/Jeremiah37';
import  Jeremiah38  from './components/Jeremiah/Jeremiah38';
import  Jeremiah39  from './components/Jeremiah/Jeremiah39';
import  Jeremiah40  from './components/Jeremiah/Jeremiah40';
import  Jeremiah41  from './components/Jeremiah/Jeremiah41';
import  Jeremiah42  from './components/Jeremiah/Jeremiah42';
import  Jeremiah43  from './components/Jeremiah/Jeremiah43';
import  Jeremiah44  from './components/Jeremiah/Jeremiah44';
import  Jeremiah45  from './components/Jeremiah/Jeremiah45';
import  Jeremiah46  from './components/Jeremiah/Jeremiah46';
import  Jeremiah47  from './components/Jeremiah/Jeremiah47';
import  Jeremiah48  from './components/Jeremiah/Jeremiah48';
import  Jeremiah49  from './components/Jeremiah/Jeremiah49';
import  Jeremiah50  from './components/Jeremiah/Jeremiah50';
import  Jeremiah51  from './components/Jeremiah/Jeremiah51';
import  Jeremiah52  from './components/Jeremiah/Jeremiah52';

import  Lamentations    from './components/Lamentations/Lamentations';
import  Lamentations1   from './components/Lamentations/Lamentations1';
import  Lamentations2   from './components/Lamentations/Lamentations2';
import  Lamentations3   from './components/Lamentations/Lamentations3';
import  Lamentations4   from './components/Lamentations/Lamentations4';
import  Lamentations5   from './components/Lamentations/Lamentations5';

import  Ezekiel    from './components/Ezekiel/Ezekial';
import  Ezekiel1   from './components/Ezekiel/Ezekial1';
import  Ezekiel2   from './components/Ezekiel/Ezekial2';
import  Ezekiel3   from './components/Ezekiel/Ezekial3';
import  Ezekiel4   from './components/Ezekiel/Ezekial4';
import  Ezekiel5   from './components/Ezekiel/Ezekial5';
import  Ezekiel6   from './components/Ezekiel/Ezekial6';
import  Ezekiel7   from './components/Ezekiel/Ezekial7';
import  Ezekiel8   from './components/Ezekiel/Ezekial8';
import  Ezekiel9   from './components/Ezekiel/Ezekial9';
import  Ezekiel10  from './components/Ezekiel/Ezekial10';
import  Ezekiel11  from './components/Ezekiel/Ezekial11';
import  Ezekiel12  from './components/Ezekiel/Ezekial12';
import  Ezekiel13  from './components/Ezekiel/Ezekial13';
import  Ezekiel14  from './components/Ezekiel/Ezekial14';
import  Ezekiel15  from './components/Ezekiel/Ezekial15';
import  Ezekiel16  from './components/Ezekiel/Ezekial16';
import  Ezekiel17  from './components/Ezekiel/Ezekial17';
import  Ezekiel18  from './components/Ezekiel/Ezekial18';
import  Ezekiel19  from './components/Ezekiel/Ezekial19';
import  Ezekiel20  from './components/Ezekiel/Ezekial20';
import  Ezekiel21  from './components/Ezekiel/Ezekial21';
import  Ezekiel22  from './components/Ezekiel/Ezekial22';
import  Ezekiel23  from './components/Ezekiel/Ezekial23';
import  Ezekiel24  from './components/Ezekiel/Ezekial24';
import  Ezekiel25  from './components/Ezekiel/Ezekial25';
import  Ezekiel26  from './components/Ezekiel/Ezekial26';
import  Ezekiel27  from './components/Ezekiel/Ezekial27';
import  Ezekiel28  from './components/Ezekiel/Ezekial28';
import  Ezekiel29  from './components/Ezekiel/Ezekial29';
import  Ezekiel30  from './components/Ezekiel/Ezekial30';
import  Ezekiel31  from './components/Ezekiel/Ezekial31';
import  Ezekiel32  from './components/Ezekiel/Ezekial32';
import  Ezekiel33  from './components/Ezekiel/Ezekial33';
import  Ezekiel34  from './components/Ezekiel/Ezekial34';
import  Ezekiel35  from './components/Ezekiel/Ezekial35';
import  Ezekiel36  from './components/Ezekiel/Ezekial36';
import  Ezekiel37  from './components/Ezekiel/Ezekial37';
import  Ezekiel38  from './components/Ezekiel/Ezekial38';
import  Ezekiel39  from './components/Ezekiel/Ezekial39';
import  Ezekiel40  from './components/Ezekiel/Ezekial40';
import  Ezekiel41  from './components/Ezekiel/Ezekial41';
import  Ezekiel42  from './components/Ezekiel/Ezekial42';
import  Ezekiel43  from './components/Ezekiel/Ezekial43';
import  Ezekiel44  from './components/Ezekiel/Ezekial44';
import  Ezekiel45  from './components/Ezekiel/Ezekial45';
import  Ezekiel46  from './components/Ezekiel/Ezekial46';
import  Ezekiel47  from './components/Ezekiel/Ezekial47';
import  Ezekiel48  from './components/Ezekiel/Ezekial48';

import  Daniel    from './components/Daniel/Daniel';
import  Daniel1   from './components/Daniel/Daniel1';
import  Daniel2   from './components/Daniel/Daniel2';
import  Daniel3   from './components/Daniel/Daniel3';
import  Daniel4   from './components/Daniel/Daniel4';
import  Daniel5   from './components/Daniel/Daniel5';
import  Daniel6   from './components/Daniel/Daniel6';
import  Daniel7   from './components/Daniel/Daniel7';
import  Daniel8   from './components/Daniel/Daniel8';
import  Daniel9   from './components/Daniel/Daniel9';
import  Daniel10  from './components/Daniel/Daniel10';
import  Daniel11  from './components/Daniel/Daniel11';
import  Daniel12  from './components/Daniel/Daniel12';

import  Hosea    from './components/Hosea/Hosea';
import  Hosea1   from './components/Hosea/Hosea1';
import  Hosea2   from './components/Hosea/Hosea2';
import  Hosea3   from './components/Hosea/Hosea3';
import  Hosea4   from './components/Hosea/Hosea4';
import  Hosea5   from './components/Hosea/Hosea5';
import  Hosea6   from './components/Hosea/Hosea6';
import  Hosea7   from './components/Hosea/Hosea7';
import  Hosea8   from './components/Hosea/Hosea8';
import  Hosea9   from './components/Hosea/Hosea9';
import  Hosea10  from './components/Hosea/Hosea10';
import  Hosea11  from './components/Hosea/Hosea11';
import  Hosea12  from './components/Hosea/Hosea12';
import  Hosea13  from './components/Hosea/Hosea13';
import  Hosea14  from './components/Hosea/Hosea14';

import  Joel    from './components/Joel/Joel';
import  Joel1   from './components/Joel/Joel1';
import  Joel2   from './components/Joel/Joel2';
import  Joel3   from './components/Joel/Joel3';

import  Amos    from './components/Amos/Amos';
import  Amos1   from './components/Amos/Amos1';
import  Amos2   from './components/Amos/Amos2';
import  Amos3   from './components/Amos/Amos3';
import  Amos4   from './components/Amos/Amos4';
import  Amos5   from './components/Amos/Amos5';
import  Amos6   from './components/Amos/Amos6';
import  Amos7   from './components/Amos/Amos7';
import  Amos8   from './components/Amos/Amos8';
import  Amos9   from './components/Amos/Amos9';

import  Jonah    from './components/Jonah/Jonah';
import  Jonah1   from './components/Jonah/Jonah1';
import  Jonah2   from './components/Jonah/Jonah2';
import  Jonah3   from './components/Jonah/Jonah3';
import  Jonah4   from './components/Jonah/Jonah4';

import  Obadiah    from './components/Obadiah/Obadiah';
import  Obadiah1   from './components/Obadiah/Obadiah1';

import  Micah    from './components/Micah/Micah';
import  Micah1   from './components/Micah/Micah1';
import  Micah2   from './components/Micah/Micah2';
import  Micah3   from './components/Micah/Micah3';
import  Micah4   from './components/Micah/Micah4';
import  Micah5   from './components/Micah/Micah5';
import  Micah6   from './components/Micah/Micah6';
import  Micah7   from './components/Micah/Micah7';

import  Nahum    from './components/Nahum/Nahum';
import  Nahum1   from './components/Nahum/Nahum1';
import  Nahum2   from './components/Nahum/Nahum2';
import  Nahum3   from './components/Nahum/Nahum3';

import  Habakkuk    from './components/Habakkuk/Habakkuk';
import  Habakkuk1   from './components/Habakkuk/Habakkuk1';
import  Habakkuk2   from './components/Habakkuk/Habakkuk2';
import  Habakkuk3   from './components/Habakkuk/Habakkuk3';

import  Zephaniah    from './components/Zephaniah/Zephaniah';
import  Zephaniah1   from './components/Zephaniah/Zephaniah1';
import  Zephaniah2   from './components/Zephaniah/Zephaniah2';
import  Zephaniah3   from './components/Zephaniah/Zephaniah3';

import  Haggai    from './components/Haggai/Haggai';
import  Haggai1   from './components/Haggai/Haggai1';
import  Haggai2   from './components/Haggai/Haggai2';

import  Zechariah    from './components/Zechariah/Zechariah';
import  Zechariah1   from './components/Zechariah/Zechariah1';
import  Zechariah2   from './components/Zechariah/Zechariah2';
import  Zechariah3   from './components/Zechariah/Zechariah3';
import  Zechariah4   from './components/Zechariah/Zechariah4';
import  Zechariah5   from './components/Zechariah/Zechariah5';
import  Zechariah6   from './components/Zechariah/Zechariah6';
import  Zechariah7   from './components/Zechariah/Zechariah7';
import  Zechariah8   from './components/Zechariah/Zechariah8';
import  Zechariah9   from './components/Zechariah/Zechariah9';
import  Zechariah10  from './components/Zechariah/Zechariah10';
import  Zechariah11  from './components/Zechariah/Zechariah11';
import  Zechariah12  from './components/Zechariah/Zechariah12';
import  Zechariah13  from './components/Zechariah/Zechariah13';
import  Zechariah14  from './components/Zechariah/Zechariah14';

import  Malachi    from './components/Malachi/Malachi';
import  Malachi1   from './components/Malachi/Malachi1';
import  Malachi2   from './components/Malachi/Malachi2';
import  Malachi3   from './components/Malachi/Malachi3';
import  Malachi4   from './components/Malachi/Malachi4';

import  Matthew    from './components/Matthew/Matthew';
import  Matthew1   from './components/Matthew/Matthew1';
import  Matthew2   from './components/Matthew/Matthew2';
import  Matthew3   from './components/Matthew/Matthew3';
import  Matthew4   from './components/Matthew/Matthew4';
import  Matthew5   from './components/Matthew/Matthew5';
import  Matthew6   from './components/Matthew/Matthew6';
import  Matthew7   from './components/Matthew/Matthew7';
import  Matthew8   from './components/Matthew/Matthew8';
import  Matthew9   from './components/Matthew/Matthew9';
import  Matthew10  from './components/Matthew/Matthew10';
import  Matthew11  from './components/Matthew/Matthew11';
import  Matthew12  from './components/Matthew/Matthew12';
import  Matthew13  from './components/Matthew/Matthew13';
import  Matthew14  from './components/Matthew/Matthew14';
import  Matthew15  from './components/Matthew/Matthew15';
import  Matthew16  from './components/Matthew/Matthew16';
import  Matthew17  from './components/Matthew/Matthew17';
import  Matthew18  from './components/Matthew/Matthew18';
import  Matthew19  from './components/Matthew/Matthew19';
import  Matthew20  from './components/Matthew/Matthew20';
import  Matthew21  from './components/Matthew/Matthew21';
import  Matthew22  from './components/Matthew/Matthew22';
import  Matthew23  from './components/Matthew/Matthew23';
import  Matthew24  from './components/Matthew/Matthew24';
import  Matthew25  from './components/Matthew/Matthew25';
import  Matthew26  from './components/Matthew/Matthew26';
import  Matthew27  from './components/Matthew/Matthew27';
import  Matthew28  from './components/Matthew/Matthew28';

import  Mark    from './components/Mark/Mark';
import  Mark1   from './components/Mark/Mark1';
import  Mark2   from './components/Mark/Mark2';
import  Mark3   from './components/Mark/Mark3';
import  Mark4   from './components/Mark/Mark4';
import  Mark5   from './components/Mark/Mark5';
import  Mark6   from './components/Mark/Mark6';
import  Mark7   from './components/Mark/Mark7';
import  Mark8   from './components/Mark/Mark8';
import  Mark9   from './components/Mark/Mark9';
import  Mark10  from './components/Mark/Mark10';
import  Mark11  from './components/Mark/Mark11';
import  Mark12  from './components/Mark/Mark12';
import  Mark13  from './components/Mark/Mark13';
import  Mark14  from './components/Mark/Mark14';
import  Mark15  from './components/Mark/Mark15';
import  Mark16  from './components/Mark/Mark16';

import  Luke    from './components/Luke/Luke';
import  Luke1   from './components/Luke/Luke1';
import  Luke2   from './components/Luke/Luke2';
import  Luke3   from './components/Luke/Luke3';
import  Luke4   from './components/Luke/Luke4';
import  Luke5   from './components/Luke/Luke5';
import  Luke6   from './components/Luke/Luke6';
import  Luke7   from './components/Luke/Luke7';
import  Luke8   from './components/Luke/Luke8';
import  Luke9   from './components/Luke/Luke9';
import  Luke10  from './components/Luke/Luke10';
import  Luke11  from './components/Luke/Luke11';
import  Luke12  from './components/Luke/Luke12';
import  Luke13  from './components/Luke/Luke13';
import  Luke14  from './components/Luke/Luke14';
import  Luke15  from './components/Luke/Luke15';
import  Luke16  from './components/Luke/Luke16';
import  Luke17  from './components/Luke/Luke17';
import  Luke18  from './components/Luke/Luke18';
import  Luke19  from './components/Luke/Luke19';
import  Luke20  from './components/Luke/Luke20';
import  Luke21  from './components/Luke/Luke21';
import  Luke22  from './components/Luke/Luke22';
import  Luke23  from './components/Luke/Luke23';
import  Luke24  from './components/Luke/Luke24';

import  John    from './components/John/John';
import  John1   from './components/John/John1';
import  John2   from './components/John/John2';
import  John3   from './components/John/John3';
import  John4   from './components/John/John4';
import  John5   from './components/John/John5';
import  John6   from './components/John/John6';
import  John7   from './components/John/John7';
import  John8   from './components/John/John8';
import  John9   from './components/John/John9';
import  John10  from './components/John/John10';
import  John11  from './components/John/John11';
import  John12  from './components/John/John12';
import  John13  from './components/John/John13';
import  John14  from './components/John/John14';
import  John15  from './components/John/John15';
import  John16  from './components/John/John16';
import  John17  from './components/John/John17';
import  John18  from './components/John/John18';
import  John19  from './components/John/John19';
import  John20  from './components/John/John20';
import  John21  from './components/John/John21';

import  Acts    from './components/Acts/Acts';
import  Acts1   from './components/Acts/Acts1';
import  Acts2   from './components/Acts/Acts2';
import  Acts3   from './components/Acts/Acts3';
import  Acts4   from './components/Acts/Acts4';
import  Acts5   from './components/Acts/Acts5';
import  Acts6   from './components/Acts/Acts6';
import  Acts7   from './components/Acts/Acts7';
import  Acts8   from './components/Acts/Acts8';
import  Acts9   from './components/Acts/Acts9';
import  Acts10  from './components/Acts/Acts10';
import  Acts11  from './components/Acts/Acts11';
import  Acts12  from './components/Acts/Acts12';
import  Acts13  from './components/Acts/Acts13';
import  Acts14  from './components/Acts/Acts14';
import  Acts15  from './components/Acts/Acts15';
import  Acts16  from './components/Acts/Acts16';
import  Acts17  from './components/Acts/Acts17';
import  Acts18  from './components/Acts/Acts18';
import  Acts19  from './components/Acts/Acts19';
import  Acts20  from './components/Acts/Acts20';
import  Acts21  from './components/Acts/Acts21';
import  Acts22  from './components/Acts/Acts22';
import  Acts23  from './components/Acts/Acts23';
import  Acts24  from './components/Acts/Acts24';
import  Acts25  from './components/Acts/Acts25';
import  Acts26  from './components/Acts/Acts26';
import  Acts27  from './components/Acts/Acts27';
import  Acts28  from './components/Acts/Acts28';

import  Romans    from './components/Romans/Romans';
import  Romans1   from './components/Romans/Romans1';
import  Romans2   from './components/Romans/Romans2';
import  Romans3   from './components/Romans/Romans3';
import  Romans4   from './components/Romans/Romans4';
import  Romans5   from './components/Romans/Romans5';
import  Romans6   from './components/Romans/Romans6';
import  Romans7   from './components/Romans/Romans7';
import  Romans8   from './components/Romans/Romans8';
import  Romans9   from './components/Romans/Romans9';
import  Romans10  from './components/Romans/Romans10';
import  Romans11  from './components/Romans/Romans11';
import  Romans12  from './components/Romans/Romans12';
import  Romans13  from './components/Romans/Romans13';
import  Romans14  from './components/Romans/Romans14';
import  Romans15  from './components/Romans/Romans15';
import  Romans16  from './components/Romans/Romans16';

import  OneCorinthians    from './components/1Corinthians/1Corinthians';
import  OneCorinthians1   from './components/1Corinthians/1Corinthians1';
import  OneCorinthians2   from './components/1Corinthians/1Corinthians2';
import  OneCorinthians3   from './components/1Corinthians/1Corinthians3';
import  OneCorinthians4   from './components/1Corinthians/1Corinthians4';
import  OneCorinthians5   from './components/1Corinthians/1Corinthians5';
import  OneCorinthians6   from './components/1Corinthians/1Corinthians6';
import  OneCorinthians7   from './components/1Corinthians/1Corinthians7';
import  OneCorinthians8   from './components/1Corinthians/1Corinthians8';
import  OneCorinthians9   from './components/1Corinthians/1Corinthians9';
import  OneCorinthians10  from './components/1Corinthians/1Corinthians10';
import  OneCorinthians11  from './components/1Corinthians/1Corinthians11';
import  OneCorinthians12  from './components/1Corinthians/1Corinthians12';
import  OneCorinthians13  from './components/1Corinthians/1Corinthians13';
import  OneCorinthians14  from './components/1Corinthians/1Corinthians14';
import  OneCorinthians15  from './components/1Corinthians/1Corinthians15';
import  OneCorinthians16  from './components/1Corinthians/1Corinthians16';

import  TwoCorinthians    from './components/2Corinthians/2Corinthians';
import  TwoCorinthians1   from './components/2Corinthians/2Corinthians1';
import  TwoCorinthians2   from './components/2Corinthians/2Corinthians2';
import  TwoCorinthians3   from './components/2Corinthians/2Corinthians3';
import  TwoCorinthians4   from './components/2Corinthians/2Corinthians4';
import  TwoCorinthians5   from './components/2Corinthians/2Corinthians5';
import  TwoCorinthians6   from './components/2Corinthians/2Corinthians6';
import  TwoCorinthians7   from './components/2Corinthians/2Corinthians7';
import  TwoCorinthians8   from './components/2Corinthians/2Corinthians8';
import  TwoCorinthians9   from './components/2Corinthians/2Corinthians9';
import  TwoCorinthians10  from './components/2Corinthians/2Corinthians10';
import  TwoCorinthians11  from './components/2Corinthians/2Corinthians11';
import  TwoCorinthians12  from './components/2Corinthians/2Corinthians12';
import  TwoCorinthians13  from './components/2Corinthians/2Corinthians13';

import  Galatians    from './components/Galatians/Galatians';
import  Galatians1   from './components/Galatians/Galatians1';
import  Galatians2   from './components/Galatians/Galatians2';
import  Galatians3   from './components/Galatians/Galatians3';
import  Galatians4   from './components/Galatians/Galatians4';
import  Galatians5   from './components/Galatians/Galatians5';
import  Galatians6   from './components/Galatians/Galatians6';

import  Ephesians    from './components/Ephesians/Ephesians';
import  Ephesians1   from './components/Ephesians/Ephesians1';
import  Ephesians2   from './components/Ephesians/Ephesians2';
import  Ephesians3   from './components/Ephesians/Ephesians3';
import  Ephesians4   from './components/Ephesians/Ephesians4';
import  Ephesians5   from './components/Ephesians/Ephesians5';
import  Ephesians6   from './components/Ephesians/Ephesians6';

import  Philippians    from './components/Philippians/Philippians';
import  Philippians1   from './components/Philippians/Philippians1';
import  Philippians2   from './components/Philippians/Philippians2';
import  Philippians3   from './components/Philippians/Philippians3';
import  Philippians4   from './components/Philippians/Philippians4';

import  Colossians    from './components/Colossians/Colossians';
import  Colossians1   from './components/Colossians/Colossians1';
import  Colossians2   from './components/Colossians/Colossians2';
import  Colossians3   from './components/Colossians/Colossians3';
import  Colossians4   from './components/Colossians/Colossians4';

import  OneThessalonians    from './components/1Thessalonians/1Thessalonians';
import  OneThessalonians1   from './components/1Thessalonians/1Thessalonians1';
import  OneThessalonians2   from './components/1Thessalonians/1Thessalonians2';
import  OneThessalonians3   from './components/1Thessalonians/1Thessalonians3';
import  OneThessalonians4   from './components/1Thessalonians/1Thessalonians4';
import  OneThessalonians5   from './components/1Thessalonians/1Thessalonians5';

import  TwoThessalonians    from './components/2Thessalonians/2Thessalonians';
import  TwoThessalonians1   from './components/2Thessalonians/2Thessalonians1';
import  TwoThessalonians2   from './components/2Thessalonians/2Thessalonians2';
import  TwoThessalonians3   from './components/2Thessalonians/2Thessalonians3';

import  OneTimothy    from './components/1Timothy/1Timothy';
import  OneTimothy1   from './components/1Timothy/1Timothy1';
import  OneTimothy2   from './components/1Timothy/1Timothy2';
import  OneTimothy3   from './components/1Timothy/1Timothy3';
import  OneTimothy4   from './components/1Timothy/1Timothy4';
import  OneTimothy5   from './components/1Timothy/1Timothy5';
import  OneTimothy6   from './components/1Timothy/1Timothy6';

import  TwoTimothy    from './components/2Timothy/2Timothy';
import  TwoTimothy1   from './components/2Timothy/2Timothy1';
import  TwoTimothy2   from './components/2Timothy/2Timothy2';
import  TwoTimothy3   from './components/2Timothy/2Timothy3';
import  TwoTimothy4   from './components/2Timothy/2Timothy4';

import  Titus    from './components/Titus/Titus';
import  Titus1   from './components/Titus/Titus1';
import  Titus2   from './components/Titus/Titus2';
import  Titus3   from './components/Titus/Titus3';

import  Philemon    from './components/Philemon/Philemon';
import  Philemon1   from './components/Philemon/Philemon1';

import  Hebrews    from './components/Hebrews/Hebrews';
import  Hebrews1   from './components/Hebrews/Hebrews1';
import  Hebrews2   from './components/Hebrews/Hebrews2';
import  Hebrews3   from './components/Hebrews/Hebrews3';
import  Hebrews4   from './components/Hebrews/Hebrews4';
import  Hebrews5   from './components/Hebrews/Hebrews5';
import  Hebrews6   from './components/Hebrews/Hebrews6';
import  Hebrews7   from './components/Hebrews/Hebrews7';
import  Hebrews8   from './components/Hebrews/Hebrews8';
import  Hebrews9   from './components/Hebrews/Hebrews9';
import  Hebrews10  from './components/Hebrews/Hebrews10';
import  Hebrews11  from './components/Hebrews/Hebrews11';
import  Hebrews12  from './components/Hebrews/Hebrews12';
import  Hebrews13  from './components/Hebrews/Hebrews13';

import  James    from './components/James/James';
import  James1   from './components/James/James1';
import  James2   from './components/James/James2';
import  James3   from './components/James/James3';
import  James4   from './components/James/James4';
import  James5   from './components/James/James5';

import  OnePeter    from './components/1Peter/1Peter';
import  OnePeter1   from './components/1Peter/1Peter1';
import  OnePeter2   from './components/1Peter/1Peter2';
import  OnePeter3   from './components/1Peter/1Peter3';
import  OnePeter4   from './components/1Peter/1Peter4';
import  OnePeter5   from './components/1Peter/1Peter5';

import  TwoPeter    from './components/2Peter/2Peter';
import  TwoPeter1   from './components/2Peter/2Peter1';
import  TwoPeter2   from './components/2Peter/2Peter2';
import  TwoPeter3   from './components/2Peter/2Peter3';

import  OneJohn    from './components/1John/1John';
import  OneJohn1   from './components/1John/1John1';
import  OneJohn2   from './components/1John/1John2';
import  OneJohn3   from './components/1John/1John3';
import  OneJohn4   from './components/1John/1John4';
import  OneJohn5   from './components/1John/1John5';

import  TwoJohn    from './components/2John/2John';
import  TwoJohn1   from './components/2John/2John1';

import  ThreeJohn    from './components/3John/3John';
import  ThreeJohn1   from './components/3John/3John1';

import  Jude    from './components/Jude/Jude';
import  Jude1   from './components/Jude/Jude1';

import  Revelation    from './components/Revelation/Revelation';
import  Revelation1   from './components/Revelation/Revelation1';
import  Revelation2   from './components/Revelation/Revelation2';
import  Revelation3   from './components/Revelation/Revelation3';
import  Revelation4   from './components/Revelation/Revelation4';
import  Revelation5   from './components/Revelation/Revelation5';
import  Revelation6   from './components/Revelation/Revelation6';
import  Revelation7   from './components/Revelation/Revelation7';
import  Revelation8   from './components/Revelation/Revelation8';
import  Revelation9   from './components/Revelation/Revelation9';
import  Revelation10  from './components/Revelation/Revelation10';
import  Revelation11  from './components/Revelation/Revelation11';
import  Revelation12  from './components/Revelation/Revelation12';
import  Revelation13  from './components/Revelation/Revelation13';
import  Revelation14  from './components/Revelation/Revelation14';
import  Revelation15  from './components/Revelation/Revelation15';
import  Revelation16  from './components/Revelation/Revelation16';
import  Revelation17  from './components/Revelation/Revelation17';
import  Revelation18  from './components/Revelation/Revelation18';
import  Revelation19  from './components/Revelation/Revelation19';
import  Revelation20  from './components/Revelation/Revelation20';
import  Revelation21  from './components/Revelation/Revelation21';
import  Revelation22  from './components/Revelation/Revelation22';

import OLDTESTAMENT from './components/Bible/OLD-TESTAMENT/OLD-TESTAMENT'

import VerseOfTheDay from './components/VerseOfTheDay';

import share from './assets/share.png';
import { RWebShare } from "react-web-share";
import NEWTESTAMENT from './components/Bible/NEW-TESTAMENT/NEW-TESTAMENT';

function App() {

  const Genesis = lazy(() => import("./components/genesis/genesis"));
  const Genesis1 = lazy(() => import("./components/genesis/genesis1"));
  const Genesis2 = lazy(() => import("./components/genesis/genesis2"));
  const Genesis3 = lazy(() => import("./components/genesis/genesis3"));
  const Genesis4 = lazy(() => import("./components/genesis/genesis4"));
  const Genesis5 = lazy(() => import("./components/genesis/genesis5"));
  const Genesis6 = lazy(() => import("./components/genesis/genesis6"));
  const Genesis7 = lazy(() => import("./components/genesis/genesis7"));
  const Genesis8 = lazy(() => import("./components/genesis/genesis8"));
  const Genesis9 = lazy(() => import("./components/genesis/genesis9"));
  const Genesis10 = lazy(() => import("./components/genesis/genesis10"));
  const Genesis11 = lazy(() => import("./components/genesis/genesis11"));
  const Genesis12 = lazy(() => import("./components/genesis/genesis12"));
  const Genesis13 = lazy(() => import("./components/genesis/genesis13"));
  const Genesis14 = lazy(() => import("./components/genesis/genesis14"));
  const Genesis15 = lazy(() => import("./components/genesis/genesis15"));
  const Genesis16 = lazy(() => import("./components/genesis/genesis16"));
  const Genesis17 = lazy(() => import("./components/genesis/genesis17"));
  const Genesis18 = lazy(() => import("./components/genesis/genesis18"));
  const Genesis19 = lazy(() => import("./components/genesis/genesis19"));
  const Genesis20 = lazy(() => import("./components/genesis/genesis20"));
  const Genesis21 = lazy(() => import("./components/genesis/genesis21"));
  const Genesis22 = lazy(() => import("./components/genesis/genesis22"));
  const Genesis23 = lazy(() => import("./components/genesis/genesis23"));
  const Genesis24 = lazy(() => import("./components/genesis/genesis24"));
  const Genesis25 = lazy(() => import("./components/genesis/genesis25"));
  const Genesis26 = lazy(() => import("./components/genesis/genesis26"));
  const Genesis27 = lazy(() => import("./components/genesis/genesis27"));
  const Genesis28 = lazy(() => import("./components/genesis/genesis28"));
  const Genesis29 = lazy(() => import("./components/genesis/genesis29"));
  const Genesis30 = lazy(() => import("./components/genesis/genesis30"));
  const Genesis31 = lazy(() => import("./components/genesis/genesis31"));
  const Genesis32 = lazy(() => import("./components/genesis/genesis32"));
  const Genesis33 = lazy(() => import("./components/genesis/genesis33"));
  const Genesis34 = lazy(() => import("./components/genesis/genesis34"));
  const Genesis35 = lazy(() => import("./components/genesis/genesis35"));
  const Genesis36 = lazy(() => import("./components/genesis/genesis36"));
  const Genesis37 = lazy(() => import("./components/genesis/genesis37"));
  const Genesis38 = lazy(() => import("./components/genesis/genesis38"));
  const Genesis39 = lazy(() => import("./components/genesis/genesis39"));
  const Genesis40 = lazy(() => import("./components/genesis/genesis40"));
  const Genesis41 = lazy(() => import("./components/genesis/genesis41"));
  const Genesis42 = lazy(() => import("./components/genesis/genesis42"));
  const Genesis43 = lazy(() => import("./components/genesis/genesis43"));
  const Genesis44 = lazy(() => import("./components/genesis/genesis44"));
  const Genesis45 = lazy(() => import("./components/genesis/genesis45"));
  const Genesis46 = lazy(() => import("./components/genesis/genesis46"));
  const Genesis47 = lazy(() => import("./components/genesis/genesis47"));
  const Genesis48 = lazy(() => import("./components/genesis/genesis48"));
  const Genesis49 = lazy(() => import("./components/genesis/genesis49"));
  const Genesis50 = lazy(() => import("./components/genesis/genesis50"));
  
  const Exodus   = lazy(() => import("./components/exodus/exodus"));
  const Exodus1  = lazy(() => import("./components/exodus/exodus1"));
  const Exodus2  = lazy(() => import("./components/exodus/exodus2"));
  const Exodus3  = lazy(() => import("./components/exodus/exodus3"));
  const Exodus4  = lazy(() => import("./components/exodus/exodus4"));
  const Exodus5  = lazy(() => import("./components/exodus/exodus5"));
  const Exodus6  = lazy(() => import("./components/exodus/exodus6"));
  const Exodus7  = lazy(() => import("./components/exodus/exodus7"));
  const Exodus8  = lazy(() => import("./components/exodus/exodus8"));
  const Exodus9  = lazy(() => import("./components/exodus/exodus9"));
  const Exodus10 = lazy(() => import("./components/exodus/exodus10"));
  const Exodus11 = lazy(() => import("./components/exodus/exodus11"));
  const Exodus12 = lazy(() => import("./components/exodus/exodus12"));
  const Exodus13 = lazy(() => import("./components/exodus/exodus13"));
  const Exodus14 = lazy(() => import("./components/exodus/exodus14"));
  const Exodus15 = lazy(() => import("./components/exodus/exodus15"));
  const Exodus16 = lazy(() => import("./components/exodus/exodus16"));
  const Exodus17 = lazy(() => import("./components/exodus/exodus17"));
  const Exodus18 = lazy(() => import("./components/exodus/exodus18"));
  const Exodus19 = lazy(() => import("./components/exodus/exodus19"));
  const Exodus20 = lazy(() => import("./components/exodus/exodus20"));
  const Exodus21 = lazy(() => import("./components/exodus/exodus21"));
  const Exodus22 = lazy(() => import("./components/exodus/exodus22"));
  const Exodus23 = lazy(() => import("./components/exodus/exodus23"));
  const Exodus24 = lazy(() => import("./components/exodus/exodus24"));
  const Exodus25 = lazy(() => import("./components/exodus/exodus25"));
  const Exodus26 = lazy(() => import("./components/exodus/exodus26"));
  const Exodus27 = lazy(() => import("./components/exodus/exodus27"));
  const Exodus28 = lazy(() => import("./components/exodus/exodus28"));
  const Exodus29 = lazy(() => import("./components/exodus/exodus29"));
  const Exodus30 = lazy(() => import("./components/exodus/exodus30"));
  const Exodus31 = lazy(() => import("./components/exodus/exodus31"));
  const Exodus32 = lazy(() => import("./components/exodus/exodus32"));
  const Exodus33 = lazy(() => import("./components/exodus/exodus33"));
  const Exodus34 = lazy(() => import("./components/exodus/exodus34"));
  const Exodus35 = lazy(() => import("./components/exodus/exodus35"));
  const Exodus36 = lazy(() => import("./components/exodus/exodus36"));
  const Exodus37 = lazy(() => import("./components/exodus/exodus37"));
  const Exodus38 = lazy(() => import("./components/exodus/exodus38"));
  const Exodus39 = lazy(() => import("./components/exodus/exodus39"));
  const Exodus40 = lazy(() => import("./components/exodus/exodus40"));
  

  const Leviticus   = lazy(() => import("./components/leviticus/leviticus"));
  const Leviticus1  = lazy(() => import("./components/leviticus/leviticus1"));
  const Leviticus2  = lazy(() => import("./components/leviticus/leviticus2"));
  const Leviticus3  = lazy(() => import("./components/leviticus/leviticus3"));
  const Leviticus4  = lazy(() => import("./components/leviticus/leviticus4"));
  const Leviticus5  = lazy(() => import("./components/leviticus/leviticus5"));
  const Leviticus6  = lazy(() => import("./components/leviticus/leviticus6"));
  const Leviticus7  = lazy(() => import("./components/leviticus/leviticus7"));
  const Leviticus8  = lazy(() => import("./components/leviticus/leviticus8"));
  const Leviticus9  = lazy(() => import("./components/leviticus/leviticus9"));
  const Leviticus10 = lazy(() => import("./components/leviticus/leviticus10"));
  const Leviticus11 = lazy(() => import("./components/leviticus/leviticus11"));
  const Leviticus12 = lazy(() => import("./components/leviticus/leviticus12"));
  const Leviticus13 = lazy(() => import("./components/leviticus/leviticus13"));
  const Leviticus14 = lazy(() => import("./components/leviticus/leviticus14"));
  const Leviticus15 = lazy(() => import("./components/leviticus/leviticus15"));
  const Leviticus16 = lazy(() => import("./components/leviticus/leviticus16"));
  const Leviticus17 = lazy(() => import("./components/leviticus/leviticus17"));
  const Leviticus18 = lazy(() => import("./components/leviticus/leviticus18"));
  const Leviticus19 = lazy(() => import("./components/leviticus/leviticus19"));
  const Leviticus20 = lazy(() => import("./components/leviticus/leviticus20"));
  const Leviticus21 = lazy(() => import("./components/leviticus/leviticus21"));
  const Leviticus22 = lazy(() => import("./components/leviticus/leviticus22"));
  const Leviticus23 = lazy(() => import("./components/leviticus/leviticus23"));
  const Leviticus24 = lazy(() => import("./components/leviticus/leviticus24"));
  const Leviticus25 = lazy(() => import("./components/leviticus/leviticus25"));
  const Leviticus26 = lazy(() => import("./components/leviticus/leviticus26"));
  const Leviticus27 = lazy(() => import("./components/leviticus/leviticus27"));
  
  const Numbers   = lazy(() => import("./components/numbers/numbers"));
  const Numbers1  = lazy(() => import("./components/numbers/numbers1"));
  const Numbers2  = lazy(() => import("./components/numbers/numbers2"));
  const Numbers3  = lazy(() => import("./components/numbers/numbers3"));
  const Numbers4  = lazy(() => import("./components/numbers/numbers4"));
  const Numbers5  = lazy(() => import("./components/numbers/numbers5"));
  const Numbers6  = lazy(() => import("./components/numbers/numbers6"));
  const Numbers7  = lazy(() => import("./components/numbers/numbers7"));
  const Numbers8  = lazy(() => import("./components/numbers/numbers8"));
  const Numbers9  = lazy(() => import("./components/numbers/numbers9"));
  const Numbers10 = lazy(() => import("./components/numbers/numbers10"));
  const Numbers11 = lazy(() => import("./components/numbers/numbers11"));
  const Numbers12 = lazy(() => import("./components/numbers/numbers12"));
  const Numbers13 = lazy(() => import("./components/numbers/numbers13"));
  const Numbers14 = lazy(() => import("./components/numbers/numbers14"));
  const Numbers15 = lazy(() => import("./components/numbers/numbers15"));
  const Numbers16 = lazy(() => import("./components/numbers/numbers16"));
  const Numbers17 = lazy(() => import("./components/numbers/numbers17"));
  const Numbers18 = lazy(() => import("./components/numbers/numbers18"));
  const Numbers19 = lazy(() => import("./components/numbers/numbers19"));
  const Numbers20 = lazy(() => import("./components/numbers/numbers20"));
  const Numbers21 = lazy(() => import("./components/numbers/numbers21"));
  const Numbers22 = lazy(() => import("./components/numbers/numbers22"));
  const Numbers23 = lazy(() => import("./components/numbers/numbers23"));
  const Numbers24 = lazy(() => import("./components/numbers/numbers24"));
  const Numbers25 = lazy(() => import("./components/numbers/numbers25"));
  const Numbers26 = lazy(() => import("./components/numbers/numbers26"));
  const Numbers27 = lazy(() => import("./components/numbers/numbers27"));
  const Numbers28 = lazy(() => import("./components/numbers/numbers28"));
  const Numbers29 = lazy(() => import("./components/numbers/numbers29"));
  const Numbers30 = lazy(() => import("./components/numbers/numbers30"));
  const Numbers31 = lazy(() => import("./components/numbers/numbers31"));
  const Numbers32 = lazy(() => import("./components/numbers/numbers32"));
  const Numbers33 = lazy(() => import("./components/numbers/numbers33"));
  const Numbers34 = lazy(() => import("./components/numbers/numbers34"));
  const Numbers35 = lazy(() => import("./components/numbers/numbers35"));
  const Numbers36 = lazy(() => import("./components/numbers/numbers36"));

   
  const Ubagamam   = lazy(() => import("./components/Deuteronomy/Deuteronomy"));
  const Ubagamam1  = lazy(() => import("./components/Deuteronomy/Deuteronomy1"));
  const Ubagamam2  = lazy(() => import("./components/Deuteronomy/Deuteronomy2"));
  const Ubagamam3  = lazy(() => import("./components/Deuteronomy/Deuteronomy3"));
  const Ubagamam4  = lazy(() => import("./components/Deuteronomy/Deuteronomy4"));
  const Ubagamam5  = lazy(() => import("./components/Deuteronomy/Deuteronomy5"));
  const Ubagamam6  = lazy(() => import("./components/Deuteronomy/Deuteronomy6"));
  const Ubagamam7  = lazy(() => import("./components/Deuteronomy/Deuteronomy7"));
  const Ubagamam8  = lazy(() => import("./components/Deuteronomy/Deuteronomy8"));
  const Ubagamam9  = lazy(() => import("./components/Deuteronomy/Deuteronomy9"));
  const Ubagamam10 = lazy(() => import("./components/Deuteronomy/Deuteronomy10"));
  const Ubagamam11 = lazy(() => import("./components/Deuteronomy/Deuteronomy11"));
  const Ubagamam12 = lazy(() => import("./components/Deuteronomy/Deuteronomy12"));
  const Ubagamam13 = lazy(() => import("./components/Deuteronomy/Deuteronomy13"));
  const Ubagamam14 = lazy(() => import("./components/Deuteronomy/Deuteronomy14"));
  const Ubagamam15 = lazy(() => import("./components/Deuteronomy/Deuteronomy15"));
  const Ubagamam16 = lazy(() => import("./components/Deuteronomy/Deuteronomy16"));
  const Ubagamam17 = lazy(() => import("./components/Deuteronomy/Deuteronomy17"));
  const Ubagamam18 = lazy(() => import("./components/Deuteronomy/Deuteronomy18"));
  const Ubagamam19 = lazy(() => import("./components/Deuteronomy/Deuteronomy19"));
  const Ubagamam20 = lazy(() => import("./components/Deuteronomy/Deuteronomy20"));
  const Ubagamam21 = lazy(() => import("./components/Deuteronomy/Deuteronomy21"));
  const Ubagamam22 = lazy(() => import("./components/Deuteronomy/Deuteronomy22"));
  const Ubagamam23 = lazy(() => import("./components/Deuteronomy/Deuteronomy23"));
  const Ubagamam24 = lazy(() => import("./components/Deuteronomy/Deuteronomy24"));
  const Ubagamam25 = lazy(() => import("./components/Deuteronomy/Deuteronomy25"));
  const Ubagamam26 = lazy(() => import("./components/Deuteronomy/Deuteronomy26"));
  const Ubagamam27 = lazy(() => import("./components/Deuteronomy/Deuteronomy27"));
  const Ubagamam28 = lazy(() => import("./components/Deuteronomy/Deuteronomy28"));
  const Ubagamam29 = lazy(() => import("./components/Deuteronomy/Deuteronomy29"));
  const Ubagamam30 = lazy(() => import("./components/Deuteronomy/Deuteronomy30"));
  const Ubagamam31 = lazy(() => import("./components/Deuteronomy/Deuteronomy31"));
  const Ubagamam32 = lazy(() => import("./components/Deuteronomy/Deuteronomy32"));
  const Ubagamam33 = lazy(() => import("./components/Deuteronomy/Deuteronomy33"));
  const Ubagamam34 = lazy(() => import("./components/Deuteronomy/Deuteronomy34"));

   
  const Joshua   = lazy(() => import("./components/Joshua/joshua"));
  const Joshua1  = lazy(() => import("./components/Joshua/joshua1"));
  const Joshua2  = lazy(() => import("./components/Joshua/joshua2"));
  const Joshua3  = lazy(() => import("./components/Joshua/joshua3"));
  const Joshua4  = lazy(() => import("./components/Joshua/joshua4"));
  const Joshua5  = lazy(() => import("./components/Joshua/joshua5"));
  const Joshua6  = lazy(() => import("./components/Joshua/joshua6"));
  const Joshua7  = lazy(() => import("./components/Joshua/joshua7"));
  const Joshua8  = lazy(() => import("./components/Joshua/joshua8"));
  const Joshua9  = lazy(() => import("./components/Joshua/joshua9"));
  const Joshua10 = lazy(() => import("./components/Joshua/joshua10"));
  const Joshua11 = lazy(() => import("./components/Joshua/joshua11"));
  const Joshua12 = lazy(() => import("./components/Joshua/joshua12"));
  const Joshua13 = lazy(() => import("./components/Joshua/joshua13"));
  const Joshua14 = lazy(() => import("./components/Joshua/joshua14"));
  const Joshua15 = lazy(() => import("./components/Joshua/joshua15"));
  const Joshua16 = lazy(() => import("./components/Joshua/joshua16"));
  const Joshua17 = lazy(() => import("./components/Joshua/joshua17"));
  const Joshua18 = lazy(() => import("./components/Joshua/joshua18"));
  const Joshua19 = lazy(() => import("./components/Joshua/joshua19"));
  const Joshua20 = lazy(() => import("./components/Joshua/joshua20"));
  const Joshua21 = lazy(() => import("./components/Joshua/joshua21"));
  const Joshua22 = lazy(() => import("./components/Joshua/joshua22"));
  const Joshua23 = lazy(() => import("./components/Joshua/joshua23"));
  const Joshua24 = lazy(() => import("./components/Joshua/joshua24"));
   
  const Judges   = lazy(() => import("./components/Judges/Judges"));
  const Judges1  = lazy(() => import("./components/Judges/Judges1"));
  const Judges2  = lazy(() => import("./components/Judges/Judges2"));
  const Judges3  = lazy(() => import("./components/Judges/Judges3"));
  const Judges4  = lazy(() => import("./components/Judges/Judges4"));
  const Judges5  = lazy(() => import("./components/Judges/Judges5"));
  const Judges6  = lazy(() => import("./components/Judges/Judges6"));
  const Judges7  = lazy(() => import("./components/Judges/Judges7"));
  const Judges8  = lazy(() => import("./components/Judges/Judges8"));
  const Judges9  = lazy(() => import("./components/Judges/Judges9"));
  const Judges10 = lazy(() => import("./components/Judges/Judges10"));
  const Judges11 = lazy(() => import("./components/Judges/Judges11"));
  const Judges12 = lazy(() => import("./components/Judges/Judges12"));
  const Judges13 = lazy(() => import("./components/Judges/Judges13"));
  const Judges14 = lazy(() => import("./components/Judges/Judges14"));
  const Judges15 = lazy(() => import("./components/Judges/Judges15"));
  const Judges16 = lazy(() => import("./components/Judges/Judges16"));
  const Judges17 = lazy(() => import("./components/Judges/Judges17"));
  const Judges18 = lazy(() => import("./components/Judges/Judges18"));
  const Judges19 = lazy(() => import("./components/Judges/Judges19"));
  const Judges20 = lazy(() => import("./components/Judges/Judges20"));
  const Judges21 = lazy(() => import("./components/Judges/Judges21"));


  const Ruth   = lazy(() => import("./components/ruth/ruth"));
  const Ruth1  = lazy(() => import("./components/ruth/ruth1"));
  const Ruth2  = lazy(() => import("./components/ruth/ruth2"));
  const Ruth3  = lazy(() => import("./components/ruth/ruth3"));
  const Ruth4  = lazy(() => import("./components/ruth/ruth4"));

   
  const OneSamuel   = lazy(() => import("./components/1Samuel/1Samuel"));
  const OneSamuel1  = lazy(() => import("./components/1Samuel/1Samuel1"));
  const OneSamuel2  = lazy(() => import("./components/1Samuel/1Samuel2"));
  const OneSamuel3  = lazy(() => import("./components/1Samuel/1Samuel3"));
  const OneSamuel4  = lazy(() => import("./components/1Samuel/1Samuel4"));
  const OneSamuel5  = lazy(() => import("./components/1Samuel/1Samuel5"));
  const OneSamuel6  = lazy(() => import("./components/1Samuel/1Samuel6"));
  const OneSamuel7  = lazy(() => import("./components/1Samuel/1Samuel7"));
  const OneSamuel8  = lazy(() => import("./components/1Samuel/1Samuel8"));
  const OneSamuel9  = lazy(() => import("./components/1Samuel/1Samuel9"));
  const OneSamuel10 = lazy(() => import("./components/1Samuel/1Samuel10"));
  const OneSamuel11 = lazy(() => import("./components/1Samuel/1Samuel11"));
  const OneSamuel12 = lazy(() => import("./components/1Samuel/1Samuel12"));
  const OneSamuel13 = lazy(() => import("./components/1Samuel/1Samuel13"));
  const OneSamuel14 = lazy(() => import("./components/1Samuel/1Samuel14"));
  const OneSamuel15 = lazy(() => import("./components/1Samuel/1Samuel15"));
  const OneSamuel16 = lazy(() => import("./components/1Samuel/1Samuel16"));
  const OneSamuel17 = lazy(() => import("./components/1Samuel/1Samuel17"));
  const OneSamuel18 = lazy(() => import("./components/1Samuel/1Samuel18"));
  const OneSamuel19 = lazy(() => import("./components/1Samuel/1Samuel19"));
  const OneSamuel20 = lazy(() => import("./components/1Samuel/1Samuel20"));
  const OneSamuel21 = lazy(() => import("./components/1Samuel/1Samuel21"));
  const OneSamuel22 = lazy(() => import("./components/1Samuel/1Samuel22"));
  const OneSamuel23 = lazy(() => import("./components/1Samuel/1Samuel23"));
  const OneSamuel24 = lazy(() => import("./components/1Samuel/1Samuel24"));
  const OneSamuel25 = lazy(() => import("./components/1Samuel/1Samuel25"));
  const OneSamuel26 = lazy(() => import("./components/1Samuel/1Samuel26"));
  const OneSamuel27 = lazy(() => import("./components/1Samuel/1Samuel27"));
  const OneSamuel28 = lazy(() => import("./components/1Samuel/1Samuel28"));
  const OneSamuel29 = lazy(() => import("./components/1Samuel/1Samuel29"));
  const OneSamuel30 = lazy(() => import("./components/1Samuel/1Samuel30"));
  const OneSamuel31 = lazy(() => import("./components/1Samuel/1Samuel31"));
 
   
  const TwoSamuel   = lazy(() => import("./components/2Samuel/2Samuel"));
  const TwoSamuel1  = lazy(() => import("./components/2Samuel/2Samuel1"));
  const TwoSamuel2  = lazy(() => import("./components/2Samuel/2Samuel2"));
  const TwoSamuel3  = lazy(() => import("./components/2Samuel/2Samuel3"));
  const TwoSamuel4  = lazy(() => import("./components/2Samuel/2Samuel4"));
  const TwoSamuel5  = lazy(() => import("./components/2Samuel/2Samuel5"));
  const TwoSamuel6  = lazy(() => import("./components/2Samuel/2Samuel6"));
  const TwoSamuel7  = lazy(() => import("./components/2Samuel/2Samuel7"));
  const TwoSamuel8  = lazy(() => import("./components/2Samuel/2Samuel8"));
  const TwoSamuel9  = lazy(() => import("./components/2Samuel/2Samuel9"));
  const TwoSamuel10 = lazy(() => import("./components/2Samuel/2Samuel10"));
  const TwoSamuel11 = lazy(() => import("./components/2Samuel/2Samuel11"));
  const TwoSamuel12 = lazy(() => import("./components/2Samuel/2Samuel12"));
  const TwoSamuel13 = lazy(() => import("./components/2Samuel/2Samuel13"));
  const TwoSamuel14 = lazy(() => import("./components/2Samuel/2Samuel14"));
  const TwoSamuel15 = lazy(() => import("./components/2Samuel/2Samuel15"));
  const TwoSamuel16 = lazy(() => import("./components/2Samuel/2Samuel16"));
  const TwoSamuel17 = lazy(() => import("./components/2Samuel/2Samuel17"));
  const TwoSamuel18 = lazy(() => import("./components/2Samuel/2Samuel18"));
  const TwoSamuel19 = lazy(() => import("./components/2Samuel/2Samuel19"));
  const TwoSamuel20 = lazy(() => import("./components/2Samuel/2Samuel20"));
  const TwoSamuel21 = lazy(() => import("./components/2Samuel/2Samuel21"));
  const TwoSamuel22 = lazy(() => import("./components/2Samuel/2Samuel22"));
  const TwoSamuel23 = lazy(() => import("./components/2Samuel/2Samuel23"));
  const TwoSamuel24 = lazy(() => import("./components/2Samuel/2Samuel24"));
  
   
  const OneKings   = lazy(() => import("./components/1Kings/1kings"));
  const OneRajakkal1  = lazy(() => import("./components/1Kings/1kings1"));
  const OneRajakkal2  = lazy(() => import("./components/1Kings/1kings2"));
  const OneRajakkal3  = lazy(() => import("./components/1Kings/1kings3"));
  const OneRajakkal4  = lazy(() => import("./components/1Kings/1kings4"));
  const OneRajakkal5  = lazy(() => import("./components/1Kings/1kings5"));
  const OneRajakkal6  = lazy(() => import("./components/1Kings/1kings6"));
  const OneRajakkal7  = lazy(() => import("./components/1Kings/1kings7"));
  const OneRajakkal8  = lazy(() => import("./components/1Kings/1kings8"));
  const OneRajakkal9  = lazy(() => import("./components/1Kings/1kings9"));
  const OneRajakkal10 = lazy(() => import("./components/1Kings/1kings10"));
  const OneRajakkal11 = lazy(() => import("./components/1Kings/1kings11"));
  const OneRajakkal12 = lazy(() => import("./components/1Kings/1kings12"));
  const OneRajakkal13 = lazy(() => import("./components/1Kings/1kings13"));
  const OneRajakkal14 = lazy(() => import("./components/1Kings/1kings14"));
  const OneRajakkal15 = lazy(() => import("./components/1Kings/1kings15"));
  const OneRajakkal16 = lazy(() => import("./components/1Kings/1kings16"));
  const OneRajakkal17 = lazy(() => import("./components/1Kings/1kings17"));
  const OneRajakkal18 = lazy(() => import("./components/1Kings/1kings18"));
  const OneRajakkal19 = lazy(() => import("./components/1Kings/1kings19"));
  const OneRajakkal20 = lazy(() => import("./components/1Kings/1kings20"));
  const OneRajakkal21 = lazy(() => import("./components/1Kings/1kings21"));
  const OneRajakkal22 = lazy(() => import("./components/1Kings/1kings22"));

   
  const TwoKings   = lazy(() => import("./components/2Kings/2Kings"));
  const TwoRajakkal1  = lazy(() => import("./components/2Kings/2kings1"));
  const TwoRajakkal2  = lazy(() => import("./components/2Kings/2kings2"));
  const TwoRajakkal3  = lazy(() => import("./components/2Kings/2kings3"));
  const TwoRajakkal4  = lazy(() => import("./components/2Kings/2kings4"));
  const TwoRajakkal5  = lazy(() => import("./components/2Kings/2kings5"));
  const TwoRajakkal6  = lazy(() => import("./components/2Kings/2kings6"));
  const TwoRajakkal7  = lazy(() => import("./components/2Kings/2kings7"));
  const TwoRajakkal8  = lazy(() => import("./components/2Kings/2kings8"));
  const TwoRajakkal9  = lazy(() => import("./components/2Kings/2kings9"));
  const TwoRajakkal10 = lazy(() => import("./components/2Kings/2kings10"));
  const TwoRajakkal11 = lazy(() => import("./components/2Kings/2kings11"));
  const TwoRajakkal12 = lazy(() => import("./components/2Kings/2kings12"));
  const TwoRajakkal13 = lazy(() => import("./components/2Kings/2kings13"));
  const TwoRajakkal14 = lazy(() => import("./components/2Kings/2kings14"));
  const TwoRajakkal15 = lazy(() => import("./components/2Kings/2kings15"));
  const TwoRajakkal16 = lazy(() => import("./components/2Kings/2kings16"));
  const TwoRajakkal17 = lazy(() => import("./components/2Kings/2kings17"));
  const TwoRajakkal18 = lazy(() => import("./components/2Kings/2kings18"));
  const TwoRajakkal19 = lazy(() => import("./components/2Kings/2kings19"));
  const TwoRajakkal20 = lazy(() => import("./components/2Kings/2kings20"));
  const TwoRajakkal21 = lazy(() => import("./components/2Kings/2kings21"));
  const TwoRajakkal22 = lazy(() => import("./components/2Kings/2kings22"));
  const TwoRajakkal23 = lazy(() => import("./components/2Kings/2kings23"));
  const TwoRajakkal24 = lazy(() => import("./components/2Kings/2kings24"));
  const TwoRajakkal25 = lazy(() => import("./components/2Kings/2kings25"));
   
  const OneChronicles   = lazy(() => import("./components/1Chronicles/1Chronicles"));
  const OneChronicles1  = lazy(() => import("./components/1Chronicles/1Chronicles1"));
  const OneChronicles2  = lazy(() => import("./components/1Chronicles/1Chronicles2"));
  const OneChronicles3  = lazy(() => import("./components/1Chronicles/1Chronicles3"));
  const OneChronicles4  = lazy(() => import("./components/1Chronicles/1Chronicles4"));
  const OneChronicles5  = lazy(() => import("./components/1Chronicles/1Chronicles5"));
  const OneChronicles6  = lazy(() => import("./components/1Chronicles/1Chronicles6"));
  const OneChronicles7  = lazy(() => import("./components/1Chronicles/1Chronicles7"));
  const OneChronicles8  = lazy(() => import("./components/1Chronicles/1Chronicles8"));
  const OneChronicles9  = lazy(() => import("./components/1Chronicles/1Chronicles9"));
  const OneChronicles10 = lazy(() => import("./components/1Chronicles/1Chronicles10"));
  const OneChronicles11 = lazy(() => import("./components/1Chronicles/1Chronicles11"));
  const OneChronicles12 = lazy(() => import("./components/1Chronicles/1Chronicles12"));
  const OneChronicles13 = lazy(() => import("./components/1Chronicles/1Chronicles13"));
  const OneChronicles14 = lazy(() => import("./components/1Chronicles/1Chronicles14"));
  const OneChronicles15 = lazy(() => import("./components/1Chronicles/1Chronicles15"));
  const OneChronicles16 = lazy(() => import("./components/1Chronicles/1Chronicles16"));
  const OneChronicles17 = lazy(() => import("./components/1Chronicles/1Chronicles17"));
  const OneChronicles18 = lazy(() => import("./components/1Chronicles/1Chronicles18"));
  const OneChronicles19 = lazy(() => import("./components/1Chronicles/1Chronicles19"));
  const OneChronicles20 = lazy(() => import("./components/1Chronicles/1Chronicles20"));
  const OneChronicles21 = lazy(() => import("./components/1Chronicles/1Chronicles21"));
  const OneChronicles22 = lazy(() => import("./components/1Chronicles/1Chronicles22"));
  const OneChronicles23 = lazy(() => import("./components/1Chronicles/1Chronicles23"));
  const OneChronicles24 = lazy(() => import("./components/1Chronicles/1Chronicles24"));
  const OneChronicles25 = lazy(() => import("./components/1Chronicles/1Chronicles25"));
  const OneChronicles26 = lazy(() => import("./components/1Chronicles/1Chronicles26"));
  const OneChronicles27 = lazy(() => import("./components/1Chronicles/1Chronicles27"));
  const OneChronicles28 = lazy(() => import("./components/1Chronicles/1Chronicles28"));
  const OneChronicles29 = lazy(() => import("./components/1Chronicles/1Chronicles29"));
    
  const TwoChronicles   = lazy(() => import("./components/2Chronicles/2Chronicles"));
  const TwoChronicles1  = lazy(() => import("./components/2Chronicles/2Chronicles1"));
  const TwoChronicles2  = lazy(() => import("./components/2Chronicles/2Chronicles2"));
  const TwoChronicles3  = lazy(() => import("./components/2Chronicles/2Chronicles3"));
  const TwoChronicles4  = lazy(() => import("./components/2Chronicles/2Chronicles4"));
  const TwoChronicles5  = lazy(() => import("./components/2Chronicles/2Chronicles5"));
  const TwoChronicles6  = lazy(() => import("./components/2Chronicles/2Chronicles6"));
  const TwoChronicles7  = lazy(() => import("./components/2Chronicles/2Chronicles7"));
  const TwoChronicles8  = lazy(() => import("./components/2Chronicles/2Chronicles8"));
  const TwoChronicles9  = lazy(() => import("./components/2Chronicles/2Chronicles9"));
  const TwoChronicles10 = lazy(() => import("./components/2Chronicles/2Chronicles10"));
  const TwoChronicles11 = lazy(() => import("./components/2Chronicles/2Chronicles11"));
  const TwoChronicles12 = lazy(() => import("./components/2Chronicles/2Chronicles12"));
  const TwoChronicles13 = lazy(() => import("./components/2Chronicles/2Chronicles13"));
  const TwoChronicles14 = lazy(() => import("./components/2Chronicles/2Chronicles14"));
  const TwoChronicles15 = lazy(() => import("./components/2Chronicles/2Chronicles15"));
  const TwoChronicles16 = lazy(() => import("./components/2Chronicles/2Chronicles16"));
  const TwoChronicles17 = lazy(() => import("./components/2Chronicles/2Chronicles17"));
  const TwoChronicles18 = lazy(() => import("./components/2Chronicles/2Chronicles18"));
  const TwoChronicles19 = lazy(() => import("./components/2Chronicles/2Chronicles19"));
  const TwoChronicles20 = lazy(() => import("./components/2Chronicles/2Chronicles20"));
  const TwoChronicles21 = lazy(() => import("./components/2Chronicles/2Chronicles21"));
  const TwoChronicles22 = lazy(() => import("./components/2Chronicles/2Chronicles22"));
  const TwoChronicles23 = lazy(() => import("./components/2Chronicles/2Chronicles23"));
  const TwoChronicles24 = lazy(() => import("./components/2Chronicles/2Chronicles24"));
  const TwoChronicles25 = lazy(() => import("./components/2Chronicles/2Chronicles25"));
  const TwoChronicles26 = lazy(() => import("./components/2Chronicles/2Chronicles26"));
  const TwoChronicles27 = lazy(() => import("./components/2Chronicles/2Chronicles27"));
  const TwoChronicles28 = lazy(() => import("./components/2Chronicles/2Chronicles28"));
  const TwoChronicles29 = lazy(() => import("./components/2Chronicles/2Chronicles29"));
  const TwoChronicles30 = lazy(() => import("./components/2Chronicles/2Chronicles30"));
  const TwoChronicles31 = lazy(() => import("./components/2Chronicles/2Chronicles31"));
  const TwoChronicles32 = lazy(() => import("./components/2Chronicles/2Chronicles32"));
  const TwoChronicles33 = lazy(() => import("./components/2Chronicles/2Chronicles33"));
  const TwoChronicles34 = lazy(() => import("./components/2Chronicles/2Chronicles34"));
  const TwoChronicles35 = lazy(() => import("./components/2Chronicles/2Chronicles35"));
  const TwoChronicles36 = lazy(() => import("./components/2Chronicles/2Chronicles36"));
 
  const Ezra   = lazy(() => import("./components/Ezra/Ezra"));
  const Ezra1  = lazy(() => import("./components/Ezra/Ezra1"));
  const Ezra2  = lazy(() => import("./components/Ezra/Ezra2"));
  const Ezra3  = lazy(() => import("./components/Ezra/Ezra3"));
  const Ezra4  = lazy(() => import("./components/Ezra/Ezra4"));
  const Ezra5  = lazy(() => import("./components/Ezra/Ezra5"));
  const Ezra6  = lazy(() => import("./components/Ezra/Ezra6"));
  const Ezra7  = lazy(() => import("./components/Ezra/Ezra7"));
  const Ezra8  = lazy(() => import("./components/Ezra/Ezra8"));
  const Ezra9  = lazy(() => import("./components/Ezra/Ezra9"));
  const Ezra10 = lazy(() => import("./components/Ezra/Ezra10"));
   
  const Nehemiah   = lazy(() => import("./components/Nehemiah/Nehemiah"));
  const Nehemiah1  = lazy(() => import("./components/Nehemiah/Nehemiah1"));
  const Nehemiah2  = lazy(() => import("./components/Nehemiah/Nehemiah2"));
  const Nehemiah3  = lazy(() => import("./components/Nehemiah/Nehemiah3"));
  const Nehemiah4  = lazy(() => import("./components/Nehemiah/Nehemiah4"));
  const Nehemiah5  = lazy(() => import("./components/Nehemiah/Nehemiah5"));
  const Nehemiah6  = lazy(() => import("./components/Nehemiah/Nehemiah6"));
  const Nehemiah7  = lazy(() => import("./components/Nehemiah/Nehemiah7"));
  const Nehemiah8  = lazy(() => import("./components/Nehemiah/Nehemiah8"));
  const Nehemiah9  = lazy(() => import("./components/Nehemiah/Nehemiah9"));
  const Nehemiah10 = lazy(() => import("./components/Nehemiah/Nehemiah10"));
  const Nehemiah11 = lazy(() => import("./components/Nehemiah/Nehemiah11"));
  const Nehemiah12 = lazy(() => import("./components/Nehemiah/Nehemiah12"));
  const Nehemiah13 = lazy(() => import("./components/Nehemiah/Nehemiah13"));

  const Esther   = lazy(() => import("./components/Esther/Esther"));
  const Esther1  = lazy(() => import("./components/Esther/Esther1"));
  const Esther2  = lazy(() => import("./components/Esther/Esther2"));
  const Esther3  = lazy(() => import("./components/Esther/Esther3"));
  const Esther4  = lazy(() => import("./components/Esther/Esther4"));
  const Esther5  = lazy(() => import("./components/Esther/Esther5"));
  const Esther6  = lazy(() => import("./components/Esther/Esther6"));
  const Esther7  = lazy(() => import("./components/Esther/Esther7"));
  const Esther8  = lazy(() => import("./components/Esther/Esther8"));
  const Esther9  = lazy(() => import("./components/Esther/Esther9"));
  const Esther10 = lazy(() => import("./components/Esther/Esther10"));

  const Job   = lazy(() => import("./components/Job/Job"));
  const Job1  = lazy(() => import("./components/Job/Job1"));
  const Job2  = lazy(() => import("./components/Job/Job2"));
  const Job3  = lazy(() => import("./components/Job/Job3"));
  const Job4  = lazy(() => import("./components/Job/Job4"));
  const Job5  = lazy(() => import("./components/Job/Job5"));
  const Job6  = lazy(() => import("./components/Job/Job6"));
  const Job7  = lazy(() => import("./components/Job/Job7"));
  const Job8  = lazy(() => import("./components/Job/Job8"));
  const Job9  = lazy(() => import("./components/Job/Job9"));
  const Job10 = lazy(() => import("./components/Job/Job10"));
  const Job11 = lazy(() => import("./components/Job/Job11"));
  const Job12 = lazy(() => import("./components/Job/Job12"));
  const Job13 = lazy(() => import("./components/Job/Job13"));
  const Job14 = lazy(() => import("./components/Job/Job14"));
  const Job15 = lazy(() => import("./components/Job/Job15"));
  const Job16 = lazy(() => import("./components/Job/Job16"));
  const Job17 = lazy(() => import("./components/Job/Job17"));
  const Job18 = lazy(() => import("./components/Job/Job18"));
  const Job19 = lazy(() => import("./components/Job/Job19"));
  const Job20 = lazy(() => import("./components/Job/Job20"));
  const Job21 = lazy(() => import("./components/Job/Job21"));
  const Job22 = lazy(() => import("./components/Job/Job22"));
  const Job23 = lazy(() => import("./components/Job/Job23"));
  const Job24 = lazy(() => import("./components/Job/Job24"));
  const Job25 = lazy(() => import("./components/Job/Job25"));
  const Job26 = lazy(() => import("./components/Job/Job26"));
  const Job27 = lazy(() => import("./components/Job/Job27"));
  const Job28 = lazy(() => import("./components/Job/Job28"));
  const Job29 = lazy(() => import("./components/Job/Job29"));
  const Job30 = lazy(() => import("./components/Job/Job30"));
  const Job31 = lazy(() => import("./components/Job/Job31"));
  const Job32 = lazy(() => import("./components/Job/Job32"));
  const Job33 = lazy(() => import("./components/Job/Job33"));
  const Job34 = lazy(() => import("./components/Job/Job34"));
  const Job35 = lazy(() => import("./components/Job/Job35"));
  const Job36 = lazy(() => import("./components/Job/Job36"));
  const Job37 = lazy(() => import("./components/Job/Job37"));
  const Job38 = lazy(() => import("./components/Job/Job38"));
  const Job39 = lazy(() => import("./components/Job/Job39"));
  const Job40 = lazy(() => import("./components/Job/Job40"));
  const Job41 = lazy(() => import("./components/Job/Job41"));
  const Job42 = lazy(() => import("./components/Job/Job42"));

  const Sangeetham  = lazy(() => import("./components/Psalms/Psalms"));
  const Sangeetham1 = lazy(() => import("./components/Psalms/Psalms1"));
  const Sangeetham2 = lazy(() => import("./components/Psalms/Psalms2"));
  const Sangeetham3 = lazy(() => import("./components/Psalms/Psalms3"));
  const Sangeetham4 = lazy(() => import("./components/Psalms/Psalms4"));
  const Sangeetham5 = lazy(() => import("./components/Psalms/Psalms5"));
  const Sangeetham6 = lazy(() => import("./components/Psalms/Psalms6"));
  const Sangeetham7 = lazy(() => import("./components/Psalms/Psalms7"));
  const Sangeetham8 = lazy(() => import("./components/Psalms/Psalms8"));
  const Sangeetham9 = lazy(() => import("./components/Psalms/Psalms9"));
  const Sangeetham10 = lazy(() => import("./components/Psalms/Psalms10"));
  const Sangeetham11 = lazy(() => import("./components/Psalms/Psalms11"));
  const Sangeetham12 = lazy(() => import("./components/Psalms/Psalms12"));
  const Sangeetham13 = lazy(() => import("./components/Psalms/Psalms13"));
  const Sangeetham14 = lazy(() => import("./components/Psalms/Psalms14"));
  const Sangeetham15 = lazy(() => import("./components/Psalms/Psalms15"));
  const Sangeetham16 = lazy(() => import("./components/Psalms/Psalms16"));
  const Sangeetham17 = lazy(() => import("./components/Psalms/Psalms17"));
  const Sangeetham18 = lazy(() => import("./components/Psalms/Psalms18"));
  const Sangeetham19 = lazy(() => import("./components/Psalms/Psalms19"));
  const Sangeetham20 = lazy(() => import("./components/Psalms/Psalms20"));
  const Sangeetham21 = lazy(() => import("./components/Psalms/Psalms21"));
  const Sangeetham22 = lazy(() => import("./components/Psalms/Psalms22"));
  const Sangeetham23 = lazy(() => import("./components/Psalms/Psalms23"));
  const Sangeetham24 = lazy(() => import("./components/Psalms/Psalms24"));
  const Sangeetham25 = lazy(() => import("./components/Psalms/Psalms25"));
  const Sangeetham26 = lazy(() => import("./components/Psalms/Psalms26"));
  const Sangeetham27 = lazy(() => import("./components/Psalms/Psalms27"));
  const Sangeetham28 = lazy(() => import("./components/Psalms/Psalms28"));
  const Sangeetham29 = lazy(() => import("./components/Psalms/Psalms29"));
  const Sangeetham30 = lazy(() => import("./components/Psalms/Psalms30"));
  const Sangeetham31 = lazy(() => import("./components/Psalms/Psalms31"));
  const Sangeetham32 = lazy(() => import("./components/Psalms/Psalms32"));
  const Sangeetham33 = lazy(() => import("./components/Psalms/Psalms33"));
  const Sangeetham34 = lazy(() => import("./components/Psalms/Psalms34"));
  const Sangeetham35 = lazy(() => import("./components/Psalms/Psalms35"));
  const Sangeetham36 = lazy(() => import("./components/Psalms/Psalms36"));
  const Sangeetham37 = lazy(() => import("./components/Psalms/Psalms37"));
  const Sangeetham38 = lazy(() => import("./components/Psalms/Psalms38"));
  const Sangeetham39 = lazy(() => import("./components/Psalms/Psalms39"));
  const Sangeetham40 = lazy(() => import("./components/Psalms/Psalms40"));
  const Sangeetham41 = lazy(() => import("./components/Psalms/Psalms41"));
  const Sangeetham42 = lazy(() => import("./components/Psalms/Psalms42"));
  const Sangeetham43 = lazy(() => import("./components/Psalms/Psalms43"));
  const Sangeetham44 = lazy(() => import("./components/Psalms/Psalms44"));
  const Sangeetham45 = lazy(() => import("./components/Psalms/Psalms45"));
  const Sangeetham46 = lazy(() => import("./components/Psalms/Psalms46"));
  const Sangeetham47 = lazy(() => import("./components/Psalms/Psalms47"));
  const Sangeetham48 = lazy(() => import("./components/Psalms/Psalms48"));
  const Sangeetham49 = lazy(() => import("./components/Psalms/Psalms49"));
  const Sangeetham50 = lazy(() => import("./components/Psalms/Psalms50"));
  const Sangeetham51 = lazy(() => import("./components/Psalms/Psalms51"));
  const Sangeetham52 = lazy(() => import("./components/Psalms/Psalms52"));
  const Sangeetham53 = lazy(() => import("./components/Psalms/Psalms53"));
  const Sangeetham54 = lazy(() => import("./components/Psalms/Psalms54"));
  const Sangeetham55 = lazy(() => import("./components/Psalms/Psalms55"));
  const Sangeetham56 = lazy(() => import("./components/Psalms/Psalms56"));
  const Sangeetham57 = lazy(() => import("./components/Psalms/Psalms57"));
  const Sangeetham58 = lazy(() => import("./components/Psalms/Psalms58"));
  const Sangeetham59 = lazy(() => import("./components/Psalms/Psalms59"));
  const Sangeetham60 = lazy(() => import("./components/Psalms/Psalms60"));
  const Sangeetham61 = lazy(() => import("./components/Psalms/Psalms61"));
  const Sangeetham62 = lazy(() => import("./components/Psalms/Psalms62"));
  const Sangeetham63 = lazy(() => import("./components/Psalms/Psalms63"));
  const Sangeetham64 = lazy(() => import("./components/Psalms/Psalms64"));
  const Sangeetham65 = lazy(() => import("./components/Psalms/Psalms65"));
  const Sangeetham66 = lazy(() => import("./components/Psalms/Psalms66"));
  const Sangeetham67 = lazy(() => import("./components/Psalms/Psalms67"));
  const Sangeetham68 = lazy(() => import("./components/Psalms/Psalms68"));
  const Sangeetham69 = lazy(() => import("./components/Psalms/Psalms69"));
  const Sangeetham70 = lazy(() => import("./components/Psalms/Psalms70"));
  const Sangeetham71 = lazy(() => import("./components/Psalms/Psalms71"));
  const Sangeetham72 = lazy(() => import("./components/Psalms/Psalms72"));
  const Sangeetham73 = lazy(() => import("./components/Psalms/Psalms73"));
  const Sangeetham74 = lazy(() => import("./components/Psalms/Psalms74"));
  const Sangeetham75 = lazy(() => import("./components/Psalms/Psalms75"));
  const Sangeetham76 = lazy(() => import("./components/Psalms/Psalms76"));
  const Sangeetham77 = lazy(() => import("./components/Psalms/Psalms77"));
  const Sangeetham78 = lazy(() => import("./components/Psalms/Psalms78"));
  const Sangeetham79 = lazy(() => import("./components/Psalms/Psalms79"));
  const Sangeetham80 = lazy(() => import("./components/Psalms/Psalms80"));
  const Sangeetham81 = lazy(() => import("./components/Psalms/Psalms81"));
  const Sangeetham82 = lazy(() => import("./components/Psalms/Psalms82"));
  const Sangeetham83 = lazy(() => import("./components/Psalms/Psalms83"));
  const Sangeetham84 = lazy(() => import("./components/Psalms/Psalms84"));
  const Sangeetham85 = lazy(() => import("./components/Psalms/Psalms85"));
  const Sangeetham86 = lazy(() => import("./components/Psalms/Psalms86"));
  const Sangeetham87 = lazy(() => import("./components/Psalms/Psalms87"));
  const Sangeetham88 = lazy(() => import("./components/Psalms/Psalms88"));
  const Sangeetham89 = lazy(() => import("./components/Psalms/Psalms89"));
  const Sangeetham90 = lazy(() => import("./components/Psalms/Psalms90"));
  const Sangeetham91 = lazy(() => import("./components/Psalms/Psalms91"));
  const Sangeetham92 = lazy(() => import("./components/Psalms/Psalms92"));
  const Sangeetham93 = lazy(() => import("./components/Psalms/Psalms93"));
  const Sangeetham94 = lazy(() => import("./components/Psalms/Psalms94"));
  const Sangeetham95 = lazy(() => import("./components/Psalms/Psalms95"));
  const Sangeetham96 = lazy(() => import("./components/Psalms/Psalms96"));
  const Sangeetham97 = lazy(() => import("./components/Psalms/Psalms97"));
  const Sangeetham98 = lazy(() => import("./components/Psalms/Psalms98"));
  const Sangeetham99 = lazy(() => import("./components/Psalms/Psalms99"));
  const Sangeetham100 = lazy(() => import("./components/Psalms/Psalms100"));
  const Sangeetham101 = lazy(() => import("./components/Psalms/Psalms101"));
  const Sangeetham102 = lazy(() => import("./components/Psalms/Psalms102"));
  const Sangeetham103 = lazy(() => import("./components/Psalms/Psalms103"));
  const Sangeetham104 = lazy(() => import("./components/Psalms/Psalms104"));
  const Sangeetham105 = lazy(() => import("./components/Psalms/Psalms105"));
  const Sangeetham106 = lazy(() => import("./components/Psalms/Psalms106"));
  const Sangeetham107 = lazy(() => import("./components/Psalms/Psalms107"));
  const Sangeetham108 = lazy(() => import("./components/Psalms/Psalms108"));
  const Sangeetham109 = lazy(() => import("./components/Psalms/Psalms109"));
  const Sangeetham110 = lazy(() => import("./components/Psalms/Psalms110"));
  const Sangeetham111 = lazy(() => import("./components/Psalms/Psalms111"));
  const Sangeetham112 = lazy(() => import("./components/Psalms/Psalms112"));
  const Sangeetham113 = lazy(() => import("./components/Psalms/Psalms113"));
  const Sangeetham114 = lazy(() => import("./components/Psalms/Psalms114"));
  const Sangeetham115 = lazy(() => import("./components/Psalms/Psalms115"));
  const Sangeetham116 = lazy(() => import("./components/Psalms/Psalms116"));
  const Sangeetham117 = lazy(() => import("./components/Psalms/Psalms117"));
  const Sangeetham118 = lazy(() => import("./components/Psalms/Psalms118"));
  const Sangeetham119 = lazy(() => import("./components/Psalms/Psalms119"));
  const Sangeetham120 = lazy(() => import("./components/Psalms/Psalms120"));
  const Sangeetham121 = lazy(() => import("./components/Psalms/Psalms121"));
  const Sangeetham122 = lazy(() => import("./components/Psalms/Psalms122"));
  const Sangeetham123 = lazy(() => import("./components/Psalms/Psalms123"));
  const Sangeetham124 = lazy(() => import("./components/Psalms/Psalms124"));
  const Sangeetham125 = lazy(() => import("./components/Psalms/Psalms125"));
  const Sangeetham126 = lazy(() => import("./components/Psalms/Psalms126"));
  const Sangeetham127 = lazy(() => import("./components/Psalms/Psalms127"));
  const Sangeetham128 = lazy(() => import("./components/Psalms/Psalms128"));
  const Sangeetham129 = lazy(() => import("./components/Psalms/Psalms129"));
  const Sangeetham130 = lazy(() => import("./components/Psalms/Psalms130"));
  const Sangeetham131 = lazy(() => import("./components/Psalms/Psalms131"));
  const Sangeetham132 = lazy(() => import("./components/Psalms/Psalms132"));
  const Sangeetham133 = lazy(() => import("./components/Psalms/Psalms133"));
  const Sangeetham134 = lazy(() => import("./components/Psalms/Psalms134"));
  const Sangeetham135 = lazy(() => import("./components/Psalms/Psalms135"));
  const Sangeetham136 = lazy(() => import("./components/Psalms/Psalms136"));
  const Sangeetham137 = lazy(() => import("./components/Psalms/Psalms137"));
  const Sangeetham138 = lazy(() => import("./components/Psalms/Psalms138"));
  const Sangeetham139 = lazy(() => import("./components/Psalms/Psalms139"));
  const Sangeetham140 = lazy(() => import("./components/Psalms/Psalms140"));
  const Sangeetham141 = lazy(() => import("./components/Psalms/Psalms141"));
  const Sangeetham142 = lazy(() => import("./components/Psalms/Psalms142"));
  const Sangeetham143 = lazy(() => import("./components/Psalms/Psalms143"));
  const Sangeetham144 = lazy(() => import("./components/Psalms/Psalms144"));
  const Sangeetham145 = lazy(() => import("./components/Psalms/Psalms145"));
  const Sangeetham146 = lazy(() => import("./components/Psalms/Psalms146"));
  const Sangeetham147 = lazy(() => import("./components/Psalms/Psalms147"));
  const Sangeetham148 = lazy(() => import("./components/Psalms/Psalms148"));
  const Sangeetham149 = lazy(() => import("./components/Psalms/Psalms149"));
  const Sangeetham150 = lazy(() => import("./components/Psalms/Psalms150"));


  const Proverbs   = lazy(() => import("./components/Proverbs/Proverbs"));
  const Proverbs1  = lazy(() => import("./components/Proverbs/Proverbs1"));
  const Proverbs2  = lazy(() => import("./components/Proverbs/Proverbs2"));
  const Proverbs3  = lazy(() => import("./components/Proverbs/Proverbs3"));
  const Proverbs4  = lazy(() => import("./components/Proverbs/Proverbs4"));
  const Proverbs5  = lazy(() => import("./components/Proverbs/Proverbs5"));
  const Proverbs6  = lazy(() => import("./components/Proverbs/Proverbs6"));
  const Proverbs7  = lazy(() => import("./components/Proverbs/Proverbs7"));
  const Proverbs8  = lazy(() => import("./components/Proverbs/Proverbs8"));
  const Proverbs9  = lazy(() => import("./components/Proverbs/Proverbs9"));
  const Proverbs10 = lazy(() => import("./components/Proverbs/Proverbs10"));
  const Proverbs11 = lazy(() => import("./components/Proverbs/Proverbs11"));
  const Proverbs12 = lazy(() => import("./components/Proverbs/Proverbs12"));
  const Proverbs13 = lazy(() => import("./components/Proverbs/Proverbs13"));
  const Proverbs14 = lazy(() => import("./components/Proverbs/Proverbs14"));
  const Proverbs15 = lazy(() => import("./components/Proverbs/Proverbs15"));
  const Proverbs16 = lazy(() => import("./components/Proverbs/Proverbs16"));
  const Proverbs17 = lazy(() => import("./components/Proverbs/Proverbs17"));
  const Proverbs18 = lazy(() => import("./components/Proverbs/Proverbs18"));
  const Proverbs19 = lazy(() => import("./components/Proverbs/Proverbs19"));
  const Proverbs20 = lazy(() => import("./components/Proverbs/Proverbs20"));
  const Proverbs21 = lazy(() => import("./components/Proverbs/Proverbs21"));
  const Proverbs22 = lazy(() => import("./components/Proverbs/Proverbs22"));
  const Proverbs23 = lazy(() => import("./components/Proverbs/Proverbs23"));
  const Proverbs24 = lazy(() => import("./components/Proverbs/Proverbs24"));
  const Proverbs25 = lazy(() => import("./components/Proverbs/Proverbs25"));
  const Proverbs26 = lazy(() => import("./components/Proverbs/Proverbs26"));
  const Proverbs27 = lazy(() => import("./components/Proverbs/Proverbs27"));
  const Proverbs28 = lazy(() => import("./components/Proverbs/Proverbs28"));
  const Proverbs29 = lazy(() => import("./components/Proverbs/Proverbs29"));
  const Proverbs30 = lazy(() => import("./components/Proverbs/Proverbs30"));
  const Proverbs31 = lazy(() => import("./components/Proverbs/Proverbs31"));

  return (
    <Router>
    <div className="App">
      <div className="header">
        <div className="left">
          <a href='/' rel="noreferrer" target='_blank'>
          <img src={logo} width="120px" height="120px" alt="logo"></img>
          </a>
          
          <div className="brand">
            <h2 className="brandh">TAMIL BIBLE</h2>
            <h2 className="brands">
              <Link to="/download">
                  <button className="button-85">
                    Download
                  </button>
              </Link>
            </h2>
          </div>
          <div className='share'>
            <RWebShare
                data={{
                    text: "Follow TamilBible",
                    url: "https://www.tamilbibleverse.com",
                    title: "TamilBible",
                }}
                onClick={() => console.log("shared successfully!")}
            >
      <img src={share} alt='share' width="50px" height="50px"></img>
      </RWebShare>
          
          </div>
          
        </div>
        
      </div>
      
      <div className='sidedraw'>   
      
        <div className="mobile-nav">
        <div className="btn"><Link to='/home'>HOME</Link></div>
        <div className="btn"><Link to='/bibleindex'>BIBLE INDEX </Link></div>
            <div className="btn"><Link to='/tamilaudiobible'> AUDIO BIBLE</Link></div>
        <div className="btn"><Link to='/tamilbible_social'>SOCIAL</Link></div>
        <div className="btn"><Link to="/contact" >CONTACT</Link></div>
    
      </div>
      </div>
      <div className="nav">
      <div className="container">
        <div className="btn"><Link to='/home'>HOME</Link></div>
        <div className="btn"><Link to='/bibleindex'>BIBLE INDEX </Link></div>
                <div className="btn"><Link to='/tamilaudiobible'> AUDIO BIBLE</Link></div>
        <div className="btn"><Link to='/tamilbible_social'>SOCIAL</Link></div>
        <div className="btn"><Link to="/contact" >CONTACT</Link></div>

    </div>
      
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='main-content'>
      <div className="mc">
      <Suspense fallback={<h2> Wait.......... </h2>}>
    <Routes>
<Route exact path="/old-testament" element={<OLDTESTAMENT />} />
<Route exact path="/new-testament" element={<NEWTESTAMENT />} />
<Route exact path="/verseoftheday" element={<VerseOfTheDay />} />
<Route exact path="/home" element={<Home />} />
<Route exact path="/" element={<Home />} />
<Route path="/bibleindex" element={<BibleIndex />} />
<Route path="/TamilAudiobible" element={<Audiobible />} />
<Route path="/tamilbible_social" element={<Social />} />
<Route path="/contact" element={<Contact />} />
<Route path="/download" element={<Downloads />} />
<Route path="/tamilbible-download" element={<Downloads />} />
<Route path="/privacy_policy" element={<Privacy />} />

{/* Genesis Routes */}
<Route path="/Aathiyagamam" element={<Genesis />} />
<Route path="/Aathiyagamam1" element={<Genesis1 />} />
<Route path="/Aathiyagamam2" element={<Genesis2 />} />
<Route path="/Aathiyagamam3" element={<Genesis3 />} />
<Route path="/Aathiyagamam4" element={<Genesis4 />} />
<Route path="/Aathiyagamam5" element={<Genesis5 />} />
<Route path="/Aathiyagamam6" element={<Genesis6 />} />
<Route path="/Aathiyagamam7" element={<Genesis7 />} />
<Route path="/Aathiyagamam8" element={<Genesis8 />} />
<Route path="/Aathiyagamam9" element={<Genesis9 />} />
<Route path="/Aathiyagamam10" element={<Genesis10 />} />
<Route path="/Aathiyagamam11" element={<Genesis11 />} />
<Route path="/Aathiyagamam12" element={<Genesis12 />} />
<Route path="/Aathiyagamam13" element={<Genesis13 />} />
<Route path="/Aathiyagamam14" element={<Genesis14 />} />
<Route path="/Aathiyagamam15" element={<Genesis15 />} />
<Route path="/Aathiyagamam16" element={<Genesis16 />} />
<Route path="/Aathiyagamam17" element={<Genesis17 />} />
<Route path="/Aathiyagamam18" element={<Genesis18 />} />
<Route path="/Aathiyagamam19" element={<Genesis19 />} />
<Route path="/Aathiyagamam20" element={<Genesis20 />} />
<Route path="/Aathiyagamam21" element={<Genesis21 />} />
<Route path="/Aathiyagamam22" element={<Genesis22 />} />
<Route path="/Aathiyagamam23" element={<Genesis23 />} />
<Route path="/Aathiyagamam24" element={<Genesis24 />} />
<Route path="/Aathiyagamam25" element={<Genesis25 />} />
<Route path="/Aathiyagamam26" element={<Genesis26 />} />
<Route path="/Aathiyagamam27" element={<Genesis27 />} />
<Route path="/Aathiyagamam28" element={<Genesis28 />} />
<Route path="/Aathiyagamam29" element={<Genesis29 />} />
<Route path="/Aathiyagamam30" element={<Genesis30 />} />
<Route path="/Aathiyagamam31" element={<Genesis31 />} />
<Route path="/Aathiyagamam32" element={<Genesis32 />} />
<Route path="/Aathiyagamam33" element={<Genesis33 />} />
<Route path="/Aathiyagamam34" element={<Genesis34 />} />
<Route path="/Aathiyagamam35" element={<Genesis35 />} />
<Route path="/Aathiyagamam36" element={<Genesis36 />} />
<Route path="/Aathiyagamam37" element={<Genesis37 />} />
<Route path="/Aathiyagamam38" element={<Genesis38 />} />
<Route path="/Aathiyagamam39" element={<Genesis39 />} />
<Route path="/Aathiyagamam40" element={<Genesis40 />} />
<Route path="/Aathiyagamam41" element={<Genesis41 />} />
<Route path="/Aathiyagamam42" element={<Genesis42 />} />
<Route path="/Aathiyagamam43" element={<Genesis43 />} />
<Route path="/Aathiyagamam44" element={<Genesis44 />} />
<Route path="/Aathiyagamam45" element={<Genesis45 />} />
<Route path="/Aathiyagamam46" element={<Genesis46 />} />
<Route path="/Aathiyagamam47" element={<Genesis47 />} />
<Route path="/Aathiyagamam48" element={<Genesis48 />} />
<Route path="/Aathiyagamam49" element={<Genesis49 />} />
<Route path="/Aathiyagamam50" element={<Genesis50 />} />

<Route path="/tamilbible-ஆதியாகமம்" element={<Genesis />} />
<Route path="/tamilbible-ஆதியாகமம்-1" element={<Genesis1 />} />
<Route path="/tamilbible-ஆதியாகமம்-2" element={<Genesis2 />} />
<Route path="/tamilbible-ஆதியாகமம்-3" element={<Genesis3 />} />
<Route path="/tamilbible-ஆதியாகமம்-4" element={<Genesis4 />} />
<Route path="/tamilbible-ஆதியாகமம்-5" element={<Genesis5 />} />
<Route path="/tamilbible-ஆதியாகமம்-6" element={<Genesis6 />} />
<Route path="/tamilbible-ஆதியாகமம்-7" element={<Genesis7 />} />
<Route path="/tamilbible-ஆதியாகமம்-8" element={<Genesis8 />} />
<Route path="/tamilbible-ஆதியாகமம்-9" element={<Genesis9 />} />
<Route path="/tamilbible-ஆதியாகமம்-10" element={<Genesis10 />} />
<Route path="/tamilbible-ஆதியாகமம்-11" element={<Genesis11 />} />
<Route path="/tamilbible-ஆதியாகமம்-12" element={<Genesis12 />} />
<Route path="/tamilbible-ஆதியாகமம்-13" element={<Genesis13 />} />
<Route path="/tamilbible-ஆதியாகமம்-14" element={<Genesis14 />} />
<Route path="/tamilbible-ஆதியாகமம்-15" element={<Genesis15 />} />
<Route path="/tamilbible-ஆதியாகமம்-16" element={<Genesis16 />} />
<Route path="/tamilbible-ஆதியாகமம்-17" element={<Genesis17 />} />
<Route path="/tamilbible-ஆதியாகமம்-18" element={<Genesis18 />} />
<Route path="/tamilbible-ஆதியாகமம்-19" element={<Genesis19 />} />
<Route path="/tamilbible-ஆதியாகமம்-20" element={<Genesis20 />} />
<Route path="/tamilbible-ஆதியாகமம்-21" element={<Genesis21 />} />
<Route path="/tamilbible-ஆதியாகமம்-22" element={<Genesis22 />} />
<Route path="/tamilbible-ஆதியாகமம்-23" element={<Genesis23 />} />
<Route path="/tamilbible-ஆதியாகமம்-24" element={<Genesis24 />} />
<Route path="/tamilbible-ஆதியாகமம்-25" element={<Genesis25 />} />
<Route path="/tamilbible-ஆதியாகமம்-26" element={<Genesis26 />} />
<Route path="/tamilbible-ஆதியாகமம்-27" element={<Genesis27 />} />
<Route path="/tamilbible-ஆதியாகமம்-28" element={<Genesis28 />} />
<Route path="/tamilbible-ஆதியாகமம்-29" element={<Genesis29 />} />
<Route path="/tamilbible-ஆதியாகமம்-30" element={<Genesis30 />} />
<Route path="/tamilbible-ஆதியாகமம்-31" element={<Genesis31 />} />
<Route path="/tamilbible-ஆதியாகமம்-32" element={<Genesis32 />} />
<Route path="/tamilbible-ஆதியாகமம்-33" element={<Genesis33 />} />
<Route path="/tamilbible-ஆதியாகமம்-34" element={<Genesis34 />} />
<Route path="/tamilbible-ஆதியாகமம்-35" element={<Genesis35 />} />
<Route path="/tamilbible-ஆதியாகமம்-36" element={<Genesis36 />} />
<Route path="/tamilbible-ஆதியாகமம்-37" element={<Genesis37 />} />
<Route path="/tamilbible-ஆதியாகமம்-38" element={<Genesis38 />} />
<Route path="/tamilbible-ஆதியாகமம்-39" element={<Genesis39 />} />
<Route path="/tamilbible-ஆதியாகமம்-40" element={<Genesis40 />} />
<Route path="/tamilbible-ஆதியாகமம்-41" element={<Genesis41 />} />
<Route path="/tamilbible-ஆதியாகமம்-42" element={<Genesis42 />} />
<Route path="/tamilbible-ஆதியாகமம்-43" element={<Genesis43 />} />
<Route path="/tamilbible-ஆதியாகமம்-44" element={<Genesis44 />} />
<Route path="/tamilbible-ஆதியாகமம்-45" element={<Genesis45 />} />
<Route path="/tamilbible-ஆதியாகமம்-46" element={<Genesis46 />} />
<Route path="/tamilbible-ஆதியாகமம்-47" element={<Genesis47 />} />
<Route path="/tamilbible-ஆதியாகமம்-48" element={<Genesis48 />} />
<Route path="/tamilbible-ஆதியாகமம்-49" element={<Genesis49 />} />
<Route path="/tamilbible-ஆதியாகமம்-50" element={<Genesis50 />} />

<Route path="/Genesis-" element={<Genesis />} />
<Route path="/Genesis-1" element={<Genesis1 />} />
<Route path="/Genesis-2" element={<Genesis2 />} />
<Route path="/Genesis-3" element={<Genesis3 />} />
<Route path="/Genesis-4" element={<Genesis4 />} />
<Route path="/Genesis-5" element={<Genesis5 />} />
<Route path="/Genesis-6" element={<Genesis6 />} />
<Route path="/Genesis-7" element={<Genesis7 />} />
<Route path="/Genesis-8" element={<Genesis8 />} />
<Route path="/Genesis-9" element={<Genesis9 />} />
<Route path="/Genesis-10" element={<Genesis10 />} />
<Route path="/Genesis-11" element={<Genesis11 />} />
<Route path="/Genesis-12" element={<Genesis12 />} />
<Route path="/Genesis-13" element={<Genesis13 />} />
<Route path="/Genesis-14" element={<Genesis14 />} />
<Route path="/Genesis-15" element={<Genesis15 />} />
<Route path="/Genesis-16" element={<Genesis16 />} />
<Route path="/Genesis-17" element={<Genesis17 />} />
<Route path="/Genesis-18" element={<Genesis18 />} />
<Route path="/Genesis-19" element={<Genesis19 />} />
<Route path="/Genesis-20" element={<Genesis20 />} />
<Route path="/Genesis-21" element={<Genesis21 />} />
<Route path="/Genesis-22" element={<Genesis22 />} />
<Route path="/Genesis-23" element={<Genesis23 />} />
<Route path="/Genesis-24" element={<Genesis24 />} />
<Route path="/Genesis-25" element={<Genesis25 />} />
<Route path="/Genesis-26" element={<Genesis26 />} />
<Route path="/Genesis-27" element={<Genesis27 />} />
<Route path="/Genesis-28" element={<Genesis28 />} />
<Route path="/Genesis-29" element={<Genesis29 />} />
<Route path="/Genesis-30" element={<Genesis30 />} />
<Route path="/Genesis-31" element={<Genesis31 />} />
<Route path="/Genesis-32" element={<Genesis32 />} />
<Route path="/Genesis-33" element={<Genesis33 />} />
<Route path="/Genesis-34" element={<Genesis34 />} />
<Route path="/Genesis-35" element={<Genesis35 />} />
<Route path="/Genesis-36" element={<Genesis36 />} />
<Route path="/Genesis-37" element={<Genesis37 />} />
<Route path="/Genesis-38" element={<Genesis38 />} />
<Route path="/Genesis-39" element={<Genesis39 />} />
<Route path="/Genesis-40" element={<Genesis40 />} />
<Route path="/Genesis-41" element={<Genesis41 />} />
<Route path="/Genesis-42" element={<Genesis42 />} />
<Route path="/Genesis-43" element={<Genesis43 />} />
<Route path="/Genesis-44" element={<Genesis44 />} />
<Route path="/Genesis-45" element={<Genesis45 />} />
<Route path="/Genesis-46" element={<Genesis46 />} />
<Route path="/Genesis-47" element={<Genesis47 />} />
<Route path="/Genesis-48" element={<Genesis48 />} />
<Route path="/Genesis-49" element={<Genesis49 />} />
<Route path="/Genesis-50" element={<Genesis50 />} />

  {/* Exodus Routes */}
<Route path="/Yathiragamam" element={<Exodus />} />
<Route path="/Yathiragamam1" element={<Exodus1 />} />
<Route path="/Yathiragamam2" element={<Exodus2 />} />
<Route path="/Yathiragamam3" element={<Exodus3 />} />
<Route path="/Yathiragamam4" element={<Exodus4 />} />
<Route path="/Yathiragamam5" element={<Exodus5 />} />
<Route path="/Yathiragamam6" element={<Exodus6 />} />
<Route path="/Yathiragamam7" element={<Exodus7 />} />
<Route path="/Yathiragamam8" element={<Exodus8 />} />
<Route path="/Yathiragamam9" element={<Exodus9 />} />
<Route path="/Yathiragamam10" element={<Exodus10 />} />
<Route path="/Yathiragamam11" element={<Exodus11 />} />
<Route path="/Yathiragamam12" element={<Exodus12 />} />
<Route path="/Yathiragamam13" element={<Exodus13 />} />
<Route path="/Yathiragamam14" element={<Exodus14 />} />
<Route path="/Yathiragamam15" element={<Exodus15 />} />
<Route path="/Yathiragamam16" element={<Exodus16 />} />
<Route path="/Yathiragamam17" element={<Exodus17 />} />
<Route path="/Yathiragamam18" element={<Exodus18 />} />
<Route path="/Yathiragamam19" element={<Exodus19 />} />
<Route path="/Yathiragamam20" element={<Exodus20 />} />
<Route path="/Yathiragamam21" element={<Exodus21 />} />
<Route path="/Yathiragamam22" element={<Exodus22 />} />
<Route path="/Yathiragamam23" element={<Exodus23 />} />
<Route path="/Yathiragamam24" element={<Exodus24 />} />
<Route path="/Yathiragamam25" element={<Exodus25 />} />
<Route path="/Yathiragamam26" element={<Exodus26 />} />
<Route path="/Yathiragamam27" element={<Exodus27 />} />
<Route path="/Yathiragamam28" element={<Exodus28 />} />
<Route path="/Yathiragamam29" element={<Exodus29 />} />
<Route path="/Yathiragamam30" element={<Exodus30 />} />
<Route path="/Yathiragamam31" element={<Exodus31 />} />
<Route path="/Yathiragamam32" element={<Exodus32 />} />
<Route path="/Yathiragamam33" element={<Exodus33 />} />
<Route path="/Yathiragamam34" element={<Exodus34 />} />
<Route path="/Yathiragamam35" element={<Exodus35 />} />
<Route path="/Yathiragamam36" element={<Exodus36 />} />
<Route path="/Yathiragamam37" element={<Exodus37 />} />
<Route path="/Yathiragamam38" element={<Exodus38 />} />
<Route path="/Yathiragamam39" element={<Exodus39 />} />
<Route path="/Yathiragamam40" element={<Exodus40 />} />

<Route path="/tamilbible-யாத்திராகமம்" element={<Exodus />} />
<Route path="/tamilbible-யாத்திராகமம்-1" element={<Exodus1 />} />
<Route path="/tamilbible-யாத்திராகமம்-2" element={<Exodus2 />} />
<Route path="/tamilbible-யாத்திராகமம்-3" element={<Exodus3 />} />
<Route path="/tamilbible-யாத்திராகமம்-4" element={<Exodus4 />} />
<Route path="/tamilbible-யாத்திராகமம்-5" element={<Exodus5 />} />
<Route path="/tamilbible-யாத்திராகமம்-6" element={<Exodus6 />} />
<Route path="/tamilbible-யாத்திராகமம்-7" element={<Exodus7 />} />
<Route path="/tamilbible-யாத்திராகமம்-8" element={<Exodus8 />} />
<Route path="/tamilbible-யாத்திராகமம்-9" element={<Exodus9 />} />
<Route path="/tamilbible-யாத்திராகமம்-10" element={<Exodus10 />} />
<Route path="/tamilbible-யாத்திராகமம்-11" element={<Exodus11 />} />
<Route path="/tamilbible-யாத்திராகமம்-12" element={<Exodus12 />} />
<Route path="/tamilbible-யாத்திராகமம்-13" element={<Exodus13 />} />
<Route path="/tamilbible-யாத்திராகமம்-14" element={<Exodus14 />} />
<Route path="/tamilbible-யாத்திராகமம்-15" element={<Exodus15 />} />
<Route path="/tamilbible-யாத்திராகமம்-16" element={<Exodus16 />} />
<Route path="/tamilbible-யாத்திராகமம்-17" element={<Exodus17 />} />
<Route path="/tamilbible-யாத்திராகமம்-18" element={<Exodus18 />} />
<Route path="/tamilbible-யாத்திராகமம்-19" element={<Exodus19 />} />
<Route path="/tamilbible-யாத்திராகமம்-20" element={<Exodus20 />} />
<Route path="/tamilbible-யாத்திராகமம்-21" element={<Exodus21 />} />
<Route path="/tamilbible-யாத்திராகமம்-22" element={<Exodus22 />} />
<Route path="/tamilbible-யாத்திராகமம்-23" element={<Exodus23 />} />
<Route path="/tamilbible-யாத்திராகமம்-24" element={<Exodus24 />} />
<Route path="/tamilbible-யாத்திராகமம்-25" element={<Exodus25 />} />
<Route path="/tamilbible-யாத்திராகமம்-26" element={<Exodus26 />} />
<Route path="/tamilbible-யாத்திராகமம்-27" element={<Exodus27 />} />
<Route path="/tamilbible-யாத்திராகமம்-28" element={<Exodus28 />} />
<Route path="/tamilbible-யாத்திராகமம்-29" element={<Exodus29 />} />
<Route path="/tamilbible-யாத்திராகமம்-30" element={<Exodus30 />} />
<Route path="/tamilbible-யாத்திராகமம்-31" element={<Exodus31 />} />
<Route path="/tamilbible-யாத்திராகமம்-32" element={<Exodus32 />} />
<Route path="/tamilbible-யாத்திராகமம்-33" element={<Exodus33 />} />
<Route path="/tamilbible-யாத்திராகமம்-34" element={<Exodus34 />} />
<Route path="/tamilbible-யாத்திராகமம்-35" element={<Exodus35 />} />
<Route path="/tamilbible-யாத்திராகமம்-36" element={<Exodus36 />} />
<Route path="/tamilbible-யாத்திராகமம்-37" element={<Exodus37 />} />
<Route path="/tamilbible-யாத்திராகமம்-38" element={<Exodus38 />} />
<Route path="/tamilbible-யாத்திராகமம்-39" element={<Exodus39 />} />
<Route path="/tamilbible-யாத்திராகமம்-40" element={<Exodus40 />} />

<Route path="/Exodus" element={<Exodus />} />
<Route path="/Exodus1" element={<Exodus1 />} />
<Route path="/Exodus2" element={<Exodus2 />} />
<Route path="/Exodus3" element={<Exodus3 />} />
<Route path="/Exodus4" element={<Exodus4 />} />
<Route path="/Exodus5" element={<Exodus5 />} />
<Route path="/Exodus6" element={<Exodus6 />} />
<Route path="/Exodus7" element={<Exodus7 />} />
<Route path="/Exodus8" element={<Exodus8 />} />
<Route path="/Exodus9" element={<Exodus9 />} />
<Route path="/Exodus10" element={<Exodus10 />} />
<Route path="/Exodus11" element={<Exodus11 />} />
<Route path="/Exodus12" element={<Exodus12 />} />
<Route path="/Exodus13" element={<Exodus13 />} />
<Route path="/Exodus14" element={<Exodus14 />} />
<Route path="/Exodus15" element={<Exodus15 />} />
<Route path="/Exodus16" element={<Exodus16 />} />
<Route path="/Exodus17" element={<Exodus17 />} />
<Route path="/Exodus18" element={<Exodus18 />} />
<Route path="/Exodus19" element={<Exodus19 />} />
<Route path="/Exodus20" element={<Exodus20 />} />
<Route path="/Exodus21" element={<Exodus21 />} />
<Route path="/Exodus22" element={<Exodus22 />} />
<Route path="/Exodus23" element={<Exodus23 />} />
<Route path="/Exodus24" element={<Exodus24 />} />
<Route path="/Exodus25" element={<Exodus25 />} />
<Route path="/Exodus26" element={<Exodus26 />} />
<Route path="/Exodus27" element={<Exodus27 />} />
<Route path="/Exodus28" element={<Exodus28 />} />
<Route path="/Exodus29" element={<Exodus29 />} />
<Route path="/Exodus30" element={<Exodus30 />} />
<Route path="/Exodus31" element={<Exodus31 />} />
<Route path="/Exodus32" element={<Exodus32 />} />
<Route path="/Exodus33" element={<Exodus33 />} />
<Route path="/Exodus34" element={<Exodus34 />} />
<Route path="/Exodus35" element={<Exodus35 />} />
<Route path="/Exodus36" element={<Exodus36 />} />
<Route path="/Exodus37" element={<Exodus37 />} />
<Route path="/Exodus38" element={<Exodus38 />} />
<Route path="/Exodus39" element={<Exodus39 />} />
<Route path="/Exodus40" element={<Exodus40 />} />
  {/* Leviticus Routes */}
<Route path="/Leviaragamam" element={<Leviticus />} />
<Route path="/Leviaragamam1" element={<Leviticus1 />} />
<Route path="/Leviaragamam2" element={<Leviticus2 />} />
<Route path="/Leviaragamam3" element={<Leviticus3 />} />
<Route path="/Leviaragamam4" element={<Leviticus4 />} />
<Route path="/Leviaragamam5" element={<Leviticus5 />} />
<Route path="/Leviaragamam6" element={<Leviticus6 />} />
<Route path="/Leviaragamam4" element={<Leviticus4 />} />
<Route path="/Leviaragamam5" element={<Leviticus5 />} />
<Route path="/Leviaragamam6" element={<Leviticus6 />} />
<Route path="/Leviaragamam7" element={<Leviticus7 />} />
<Route path="/Leviaragamam8" element={<Leviticus8 />} />
<Route path="/Leviaragamam9" element={<Leviticus9 />} />
<Route path="/Leviaragamam10" element={<Leviticus10 />} />
<Route path="/Leviaragamam11" element={<Leviticus11 />} />
<Route path="/Leviaragamam12" element={<Leviticus12 />} />
<Route path="/Leviaragamam13" element={<Leviticus13 />} />
<Route path="/Leviaragamam14" element={<Leviticus14 />} />
<Route path="/Leviaragamam15" element={<Leviticus15 />} />
<Route path="/Leviaragamam16" element={<Leviticus16 />} />
<Route path="/Leviaragamam17" element={<Leviticus17 />} />
<Route path="/Leviaragamam18" element={<Leviticus18 />} />
<Route path="/Leviaragamam19" element={<Leviticus19 />} />
<Route path="/Leviaragamam20" element={<Leviticus20 />} />
<Route path="/Leviaragamam21" element={<Leviticus21 />} />
<Route path="/Leviaragamam22" element={<Leviticus22 />} />
<Route path="/Leviaragamam23" element={<Leviticus23 />} />
<Route path="/Leviaragamam24" element={<Leviticus24 />} />
<Route path="/Leviaragamam25" element={<Leviticus25 />} />
<Route path="/Leviaragamam26" element={<Leviticus26 />} />
<Route path="/Leviaragamam27" element={<Leviticus27 />} />

<Route path="/tamilbible-லேவியராகமம் " element={<Leviticus />} />
<Route path="/tamilbible-லேவியராகமம் 1" element={<Leviticus1 />} />
<Route path="/tamilbible-லேவியராகமம் 2" element={<Leviticus2 />} />
<Route path="/tamilbible-லேவியராகமம் 3" element={<Leviticus3 />} />
<Route path="/tamilbible-லேவியராகமம் 4" element={<Leviticus4 />} />
<Route path="/tamilbible-லேவியராகமம் 5" element={<Leviticus5 />} />
<Route path="/tamilbible-லேவியராகமம் 6" element={<Leviticus6 />} />
<Route path="/tamilbible-லேவியராகமம் 4" element={<Leviticus4 />} />
<Route path="/tamilbible-லேவியராகமம் 5" element={<Leviticus5 />} />
<Route path="/tamilbible-லேவியராகமம் 6" element={<Leviticus6 />} />
<Route path="/tamilbible-லேவியராகமம் 7" element={<Leviticus7 />} />
<Route path="/tamilbible-லேவியராகமம் 8" element={<Leviticus8 />} />
<Route path="/tamilbible-லேவியராகமம் 9" element={<Leviticus9 />} />
<Route path="/tamilbible-லேவியராகமம் 10" element={<Leviticus10 />} />
<Route path="/tamilbible-லேவியராகமம் 11" element={<Leviticus11 />} />
<Route path="/tamilbible-லேவியராகமம் 12" element={<Leviticus12 />} />
<Route path="/tamilbible-லேவியராகமம் 13" element={<Leviticus13 />} />
<Route path="/tamilbible-லேவியராகமம் 14" element={<Leviticus14 />} />
<Route path="/tamilbible-லேவியராகமம் 15" element={<Leviticus15 />} />
<Route path="/tamilbible-லேவியராகமம் 16" element={<Leviticus16 />} />
<Route path="/tamilbible-லேவியராகமம் 17" element={<Leviticus17 />} />
<Route path="/tamilbible-லேவியராகமம் 18" element={<Leviticus18 />} />
<Route path="/tamilbible-லேவியராகமம் 19" element={<Leviticus19 />} />
<Route path="/tamilbible-லேவியராகமம் 20" element={<Leviticus20 />} />
<Route path="/tamilbible-லேவியராகமம் 21" element={<Leviticus21 />} />
<Route path="/tamilbible-லேவியராகமம் 22" element={<Leviticus22 />} />
<Route path="/tamilbible-லேவியராகமம் 23" element={<Leviticus23 />} />
<Route path="/tamilbible-லேவியராகமம் 24" element={<Leviticus24 />} />
<Route path="/tamilbible-லேவியராகமம் 25" element={<Leviticus25 />} />
<Route path="/tamilbible-லேவியராகமம் 26" element={<Leviticus26 />} />
<Route path="/tamilbible-லேவியராகமம் 27" element={<Leviticus27 />} />

<Route path="/Leviticus" element={<Leviticus />} />
<Route path="/Leviticus1" element={<Leviticus1 />} />
<Route path="/Leviticus2" element={<Leviticus2 />} />
<Route path="/Leviticus3" element={<Leviticus3 />} />
<Route path="/Leviticus4" element={<Leviticus4 />} />
<Route path="/Leviticus5" element={<Leviticus5 />} />
<Route path="/Leviticus6" element={<Leviticus6 />} />
<Route path="/Leviticus4" element={<Leviticus4 />} />
<Route path="/Leviticus5" element={<Leviticus5 />} />
<Route path="/Leviticus6" element={<Leviticus6 />} />
<Route path="/Leviticus7" element={<Leviticus7 />} />
<Route path="/Leviticus8" element={<Leviticus8 />} />
<Route path="/Leviticus9" element={<Leviticus9 />} />
<Route path="/Leviticus10" element={<Leviticus10 />} />
<Route path="/Leviticus11" element={<Leviticus11 />} />
<Route path="/Leviticus12" element={<Leviticus12 />} />
<Route path="/Leviticus13" element={<Leviticus13 />} />
<Route path="/Leviticus14" element={<Leviticus14 />} />
<Route path="/Leviticus15" element={<Leviticus15 />} />
<Route path="/Leviticus16" element={<Leviticus16 />} />
<Route path="/Leviticus17" element={<Leviticus17 />} />
<Route path="/Leviticus18" element={<Leviticus18 />} />
<Route path="/Leviticus19" element={<Leviticus19 />} />
<Route path="/Leviticus20" element={<Leviticus20 />} />
<Route path="/Leviticus21" element={<Leviticus21 />} />
<Route path="/Leviticus22" element={<Leviticus22 />} />
<Route path="/Leviticus23" element={<Leviticus23 />} />
<Route path="/Leviticus24" element={<Leviticus24 />} />
<Route path="/Leviticus25" element={<Leviticus25 />} />
<Route path="/Leviticus26" element={<Leviticus26 />} />
<Route path="/Leviticus27" element={<Leviticus27 />} />

<Route path="/Ennagamam" element={<Numbers />} />
<Route path="/Ennagamam-1" element={<Numbers1/>} />
<Route path="/Ennagamam-2" element={<Numbers2/>} />
<Route path="/Ennagamam-3" element={<Numbers3/>} />
<Route path="/Ennagamam-4" element={<Numbers4/>} />
<Route path="/Ennagamam-5" element={<Numbers5/>} />
<Route path="/Ennagamam-6" element={<Numbers6/>} />
<Route path="/Ennagamam-7" element={<Numbers7/>} />
<Route path="/Ennagamam-8" element={<Numbers8/>} />
<Route path="/Ennagamam-9" element={<Numbers9/>} />
<Route path="/Ennagamam-10" element={<Numbers10/>} />
<Route path="/Ennagamam-11" element={<Numbers11/>} />
<Route path="/Ennagamam-12" element={<Numbers12/>} />
<Route path="/Ennagamam-13" element={<Numbers13/>} />
<Route path="/Ennagamam-14" element={<Numbers14/>} />
<Route path="/Ennagamam-15" element={<Numbers15/>} />
<Route path="/Ennagamam-16" element={<Numbers16/>} />
<Route path="/Ennagamam-17" element={<Numbers17/>} />
<Route path="/Ennagamam-18" element={<Numbers18/>} />
<Route path="/Ennagamam-19" element={<Numbers19/>} />
<Route path="/Ennagamam-20" element={<Numbers20/>} />
<Route path="/Ennagamam-21" element={<Numbers21/>} />
<Route path="/Ennagamam-22" element={<Numbers22/>} />
<Route path="/Ennagamam-23" element={<Numbers23/>} />
<Route path="/Ennagamam-24" element={<Numbers24/>} />
<Route path="/Ennagamam-25" element={<Numbers25/>} />
<Route path="/Ennagamam-26" element={<Numbers26/>} />
<Route path="/Ennagamam-27" element={<Numbers27/>} />
<Route path="/Ennagamam-28" element={<Numbers28/>} />
<Route path="/Ennagamam-29" element={<Numbers29/>} />
<Route path="/Ennagamam-30" element={<Numbers30/>} />
<Route path="/Ennagamam-31" element={<Numbers31/>} />
<Route path="/Ennagamam-32" element={<Numbers32/>} />
<Route path="/Ennagamam-33" element={<Numbers33/>} />
<Route path="/Ennagamam-34" element={<Numbers34/>} />
<Route path="/Ennagamam-35" element={<Numbers35/>} />
<Route path="/Ennagamam-36" element={<Numbers36/>} />

<Route path="/tamilbible-எண்ணாகமம்" element={<Numbers />} />
<Route path="/tamilbible-எண்ணாகமம்-1" element={<Numbers1 />} />
<Route path="/tamilbible-எண்ணாகமம்-2" element={<Numbers2 />} />
<Route path="/tamilbible-எண்ணாகமம்-3" element={<Numbers3 />} />
<Route path="/tamilbible-எண்ணாகமம்-4" element={<Numbers4 />} />
<Route path="/tamilbible-எண்ணாகமம்-5" element={<Numbers5 />} />
<Route path="/tamilbible-எண்ணாகமம்-6" element={<Numbers6 />} />
<Route path="/tamilbible-எண்ணாகமம்-7" element={<Numbers7 />} />
<Route path="/tamilbible-எண்ணாகமம்-8" element={<Numbers8 />} />
<Route path="/tamilbible-எண்ணாகமம்-9" element={<Numbers9 />} />
<Route path="/tamilbible-எண்ணாகமம்-10" element={<Numbers10 />} />
<Route path="/tamilbible-எண்ணாகமம்-11" element={<Numbers11 />} />
<Route path="/tamilbible-எண்ணாகமம்-12" element={<Numbers12 />} />
<Route path="/tamilbible-எண்ணாகமம்-13" element={<Numbers13 />} />
<Route path="/tamilbible-எண்ணாகமம்-14" element={<Numbers14 />} />
<Route path="/tamilbible-எண்ணாகமம்-15" element={<Numbers15 />} />
<Route path="/tamilbible-எண்ணாகமம்-16" element={<Numbers16 />} />
<Route path="/tamilbible-எண்ணாகமம்-17" element={<Numbers17 />} />
<Route path="/tamilbible-எண்ணாகமம்-18" element={<Numbers18 />} />
<Route path="/tamilbible-எண்ணாகமம்-19" element={<Numbers19 />} />
<Route path="/tamilbible-எண்ணாகமம்-20" element={<Numbers20 />} />
<Route path="/tamilbible-எண்ணாகமம்-21" element={<Numbers21 />} />
<Route path="/tamilbible-எண்ணாகமம்-22" element={<Numbers22 />} />
<Route path="/tamilbible-எண்ணாகமம்-23" element={<Numbers23 />} />
<Route path="/tamilbible-எண்ணாகமம்-24" element={<Numbers24 />} />
<Route path="/tamilbible-எண்ணாகமம்-25" element={<Numbers25 />} />
<Route path="/tamilbible-எண்ணாகமம்-26" element={<Numbers26 />} />
<Route path="/tamilbible-எண்ணாகமம்-27" element={<Numbers27 />} />
<Route path="/tamilbible-எண்ணாகமம்-28" element={<Numbers28 />} />
<Route path="/tamilbible-எண்ணாகமம்-29" element={<Numbers29 />} />
<Route path="/tamilbible-எண்ணாகமம்-30" element={<Numbers30 />} />
<Route path="/tamilbible-எண்ணாகமம்-31" element={<Numbers31 />} />
<Route path="/tamilbible-எண்ணாகமம்-32" element={<Numbers32 />} />
<Route path="/tamilbible-எண்ணாகமம்-33" element={<Numbers33 />} />
<Route path="/tamilbible-எண்ணாகமம்-34" element={<Numbers34 />} />
<Route path="/tamilbible-எண்ணாகமம்-35" element={<Numbers35 />} />
<Route path="/tamilbible-எண்ணாகமம்-36" element={<Numbers36 />} />

<Route path="/Numbers" element={<Numbers />} />
<Route path="/Numbers-1" element={<Numbers1 />} />
<Route path="/Numbers-2" element={<Numbers2 />} />
<Route path="/Numbers-3" element={<Numbers3 />} />
<Route path="/Numbers-4" element={<Numbers4 />} />
<Route path="/Numbers-5" element={<Numbers5 />} />
<Route path="/Numbers-6" element={<Numbers6 />} />
<Route path="/Numbers-7" element={<Numbers7 />} />
<Route path="/Numbers-8" element={<Numbers8 />} />
<Route path="/Numbers-9" element={<Numbers9 />} />
<Route path="/Numbers-10" element={<Numbers10 />} />
<Route path="/Numbers-11" element={<Numbers11 />} />
<Route path="/Numbers-12" element={<Numbers12 />} />
<Route path="/Numbers-13" element={<Numbers13 />} />
<Route path="/Numbers-14" element={<Numbers14 />} />
<Route path="/Numbers-15" element={<Numbers15 />} />
<Route path="/Numbers-16" element={<Numbers16 />} />
<Route path="/Numbers-17" element={<Numbers17 />} />
<Route path="/Numbers-18" element={<Numbers18 />} />
<Route path="/Numbers-19" element={<Numbers19 />} />
<Route path="/Numbers-20" element={<Numbers20 />} />
<Route path="/Numbers-21" element={<Numbers21 />} />
<Route path="/Numbers-22" element={<Numbers22 />} />
<Route path="/Numbers-23" element={<Numbers23 />} />
<Route path="/Numbers-24" element={<Numbers24 />} />
<Route path="/Numbers-25" element={<Numbers25 />} />
<Route path="/Numbers-26" element={<Numbers26 />} />
<Route path="/Numbers-27" element={<Numbers27 />} />
<Route path="/Numbers-28" element={<Numbers28 />} />
<Route path="/Numbers-29" element={<Numbers29 />} />
<Route path="/Numbers-30" element={<Numbers30 />} />
<Route path="/Numbers-31" element={<Numbers31 />} />
<Route path="/Numbers-32" element={<Numbers32 />} />
<Route path="/Numbers-33" element={<Numbers33 />} />
<Route path="/Numbers-34" element={<Numbers34 />} />
<Route path="/Numbers-35" element={<Numbers35 />} />
<Route path="/Numbers-36" element={<Numbers36 />} />


<Route path="/Ubagamam" element={<Ubagamam />} />
<Route path="/Ubagamam-1" element={<Ubagamam1 />} />
<Route path="/Ubagamam-2" element={<Ubagamam2 />} />
<Route path="/Ubagamam-3" element={<Ubagamam3 />} />
<Route path="/Ubagamam-4" element={<Ubagamam4 />} />
<Route path="/Ubagamam-5" element={<Ubagamam5 />} />
<Route path="/Ubagamam-6" element={<Ubagamam6 />} />
<Route path="/Ubagamam-7" element={<Ubagamam7 />} />
<Route path="/Ubagamam-8" element={<Ubagamam8 />} />
<Route path="/Ubagamam-9" element={<Ubagamam9 />} />
<Route path="/Ubagamam-10" element={<Ubagamam10 />} />
<Route path="/Ubagamam-11" element={<Ubagamam11 />} />
<Route path="/Ubagamam-12" element={<Ubagamam12 />} />
<Route path="/Ubagamam-13" element={<Ubagamam13 />} />
<Route path="/Ubagamam-14" element={<Ubagamam14 />} />
<Route path="/Ubagamam-15" element={<Ubagamam15 />} />
<Route path="/Ubagamam-16" element={<Ubagamam16 />} />
<Route path="/Ubagamam-17" element={<Ubagamam17 />} />
<Route path="/Ubagamam-18" element={<Ubagamam18 />} />
<Route path="/Ubagamam-19" element={<Ubagamam19 />} />
<Route path="/Ubagamam-20" element={<Ubagamam20 />} />
<Route path="/Ubagamam-21" element={<Ubagamam21 />} />
<Route path="/Ubagamam-22" element={<Ubagamam22 />} />
<Route path="/Ubagamam-23" element={<Ubagamam23 />} />
<Route path="/Ubagamam-24" element={<Ubagamam24 />} />
<Route path="/Ubagamam-25" element={<Ubagamam25 />} />
<Route path="/Ubagamam-26" element={<Ubagamam26 />} />
<Route path="/Ubagamam-27" element={<Ubagamam27 />} />
<Route path="/Ubagamam-28" element={<Ubagamam28 />} />
<Route path="/Ubagamam-29" element={<Ubagamam29 />} />
<Route path="/Ubagamam-30" element={<Ubagamam30 />} />
<Route path="/Ubagamam-31" element={<Ubagamam31 />} />
<Route path="/Ubagamam-32" element={<Ubagamam32 />} />
<Route path="/Ubagamam-33" element={<Ubagamam33 />} />
<Route path="/Ubagamam-34" element={<Ubagamam34 />} />

<Route path="/tamilbible-உபாகமம் " element={<Ubagamam />} />
<Route path="/tamilbible-உபாகமம் -1" element={<Ubagamam1 />} />
<Route path="/tamilbible-உபாகமம் -2" element={<Ubagamam2 />} />
<Route path="/tamilbible-உபாகமம் -3" element={<Ubagamam3 />} />
<Route path="/tamilbible-உபாகமம் -4" element={<Ubagamam4 />} />
<Route path="/tamilbible-உபாகமம் -5" element={<Ubagamam5 />} />
<Route path="/tamilbible-உபாகமம் -6" element={<Ubagamam6 />} />
<Route path="/tamilbible-உபாகமம் -7" element={<Ubagamam7 />} />
<Route path="/tamilbible-உபாகமம் -8" element={<Ubagamam8 />} />
<Route path="/tamilbible-உபாகமம் -9" element={<Ubagamam9 />} />
<Route path="/tamilbible-உபாகமம் -10" element={<Ubagamam10 />} />
<Route path="/tamilbible-உபாகமம் -11" element={<Ubagamam11 />} />
<Route path="/tamilbible-உபாகமம் -12" element={<Ubagamam12 />} />
<Route path="/tamilbible-உபாகமம் -13" element={<Ubagamam13 />} />
<Route path="/tamilbible-உபாகமம் -14" element={<Ubagamam14 />} />
<Route path="/tamilbible-உபாகமம் -15" element={<Ubagamam15 />} />
<Route path="/tamilbible-உபாகமம் -16" element={<Ubagamam16 />} />
<Route path="/tamilbible-உபாகமம் -17" element={<Ubagamam17 />} />
<Route path="/tamilbible-உபாகமம் -18" element={<Ubagamam18 />} />
<Route path="/tamilbible-உபாகமம் -19" element={<Ubagamam19 />} />
<Route path="/tamilbible-உபாகமம் -20" element={<Ubagamam20 />} />
<Route path="/tamilbible-உபாகமம் -21" element={<Ubagamam21 />} />
<Route path="/tamilbible-உபாகமம் -22" element={<Ubagamam22 />} />
<Route path="/tamilbible-உபாகமம் -23" element={<Ubagamam23 />} />
<Route path="/tamilbible-உபாகமம் -24" element={<Ubagamam24 />} />
<Route path="/tamilbible-உபாகமம் -25" element={<Ubagamam25 />} />
<Route path="/tamilbible-உபாகமம் -26" element={<Ubagamam26 />} />
<Route path="/tamilbible-உபாகமம் -27" element={<Ubagamam27 />} />
<Route path="/tamilbible-உபாகமம் -28" element={<Ubagamam28 />} />
<Route path="/tamilbible-உபாகமம் -29" element={<Ubagamam29 />} />
<Route path="/tamilbible-உபாகமம் -30" element={<Ubagamam30 />} />
<Route path="/tamilbible-உபாகமம் -31" element={<Ubagamam31 />} />
<Route path="/tamilbible-உபாகமம் -32" element={<Ubagamam32 />} />
<Route path="/tamilbible-உபாகமம் -33" element={<Ubagamam33 />} />
<Route path="/tamilbible-உபாகமம் -34" element={<Ubagamam34 />} />

<Route path="/Deuteronomy" element={<Ubagamam />} />
<Route path="/Deuteronomy-1" element={<Ubagamam1 />} />
<Route path="/Deuteronomy-2" element={<Ubagamam2 />} />
<Route path="/Deuteronomy-3" element={<Ubagamam3 />} />
<Route path="/Deuteronomy-4" element={<Ubagamam4 />} />
<Route path="/Deuteronomy-5" element={<Ubagamam5 />} />
<Route path="/Deuteronomy-6" element={<Ubagamam6 />} />
<Route path="/Deuteronomy-7" element={<Ubagamam7 />} />
<Route path="/Deuteronomy-8" element={<Ubagamam8 />} />
<Route path="/Deuteronomy-9" element={<Ubagamam9 />} />
<Route path="/Deuteronomy-10" element={<Ubagamam10 />} />
<Route path="/Deuteronomy-11" element={<Ubagamam11 />} />
<Route path="/Deuteronomy-12" element={<Ubagamam12 />} />
<Route path="/Deuteronomy-13" element={<Ubagamam13 />} />
<Route path="/Deuteronomy-14" element={<Ubagamam14 />} />
<Route path="/Deuteronomy-15" element={<Ubagamam15 />} />
<Route path="/Deuteronomy-16" element={<Ubagamam16 />} />
<Route path="/Deuteronomy-17" element={<Ubagamam17 />} />
<Route path="/Deuteronomy-18" element={<Ubagamam18 />} />
<Route path="/Deuteronomy-19" element={<Ubagamam19 />} />
<Route path="/Deuteronomy-20" element={<Ubagamam20 />} />
<Route path="/Deuteronomy-21" element={<Ubagamam21 />} />
<Route path="/Deuteronomy-22" element={<Ubagamam22 />} />
<Route path="/Deuteronomy-23" element={<Ubagamam23 />} />
<Route path="/Deuteronomy-24" element={<Ubagamam24 />} />
<Route path="/Deuteronomy-25" element={<Ubagamam25 />} />
<Route path="/Deuteronomy-26" element={<Ubagamam26 />} />
<Route path="/Deuteronomy-27" element={<Ubagamam27 />} />
<Route path="/Deuteronomy-28" element={<Ubagamam28 />} />
<Route path="/Deuteronomy-29" element={<Ubagamam29 />} />
<Route path="/Deuteronomy-30" element={<Ubagamam30 />} />
<Route path="/Deuteronomy-31" element={<Ubagamam31 />} />
<Route path="/Deuteronomy-32" element={<Ubagamam32 />} />
<Route path="/Deuteronomy-33" element={<Ubagamam33 />} />
<Route path="/Deuteronomy-34" element={<Ubagamam34 />} />

<Route path="/Joshua" element={<Joshua />} />
<Route path="/Joshua-1" element={<Joshua1 />} />
<Route path="/Joshua-2" element={<Joshua2 />} />
<Route path="/Joshua-3" element={<Joshua3 />} />
<Route path="/Joshua-4" element={<Joshua4 />} />
<Route path="/Joshua-5" element={<Joshua5 />} />
<Route path="/Joshua-6" element={<Joshua6 />} />
<Route path="/Joshua-7" element={<Joshua7 />} />
<Route path="/Joshua-8" element={<Joshua8 />} />
<Route path="/Joshua-9" element={<Joshua9 />} />
<Route path="/Joshua-10" element={<Joshua10 />} />
<Route path="/Joshua-11" element={<Joshua11 />} />
<Route path="/Joshua-12" element={<Joshua12 />} />
<Route path="/Joshua-13" element={<Joshua13 />} />
<Route path="/Joshua-14" element={<Joshua14 />} />
<Route path="/Joshua-15" element={<Joshua15 />} />
<Route path="/Joshua-16" element={<Joshua16 />} />
<Route path="/Joshua-17" element={<Joshua17 />} />
<Route path="/Joshua-18" element={<Joshua18 />} />
<Route path="/Joshua-19" element={<Joshua19 />} />
<Route path="/Joshua-20" element={<Joshua20 />} />
<Route path="/Joshua-21" element={<Joshua21 />} />
<Route path="/Joshua-22" element={<Joshua22 />} />
<Route path="/Joshua-23" element={<Joshua23 />} />
<Route path="/Joshua-24" element={<Joshua24 />} />

<Route path="/tamilbible-யோசுவா" element={<Joshua />} />
<Route path="/tamilbible-யோசுவா-1" element={<Joshua1 />} />
<Route path="/tamilbible-யோசுவா-2" element={<Joshua2 />} />
<Route path="/tamilbible-யோசுவா-3" element={<Joshua3 />} />
<Route path="/tamilbible-யோசுவா-4" element={<Joshua4 />} />
<Route path="/tamilbible-யோசுவா-5" element={<Joshua5 />} />
<Route path="/tamilbible-யோசுவா-6" element={<Joshua6 />} />
<Route path="/tamilbible-யோசுவா-7" element={<Joshua7 />} />
<Route path="/tamilbible-யோசுவா-8" element={<Joshua8 />} />
<Route path="/tamilbible-யோசுவா-9" element={<Joshua9 />} />
<Route path="/tamilbible-யோசுவா-10" element={<Joshua10 />} />
<Route path="/tamilbible-யோசுவா-11" element={<Joshua11 />} />
<Route path="/tamilbible-யோசுவா-12" element={<Joshua12 />} />
<Route path="/tamilbible-யோசுவா-13" element={<Joshua13 />} />
<Route path="/tamilbible-யோசுவா-14" element={<Joshua14 />} />
<Route path="/tamilbible-யோசுவா-15" element={<Joshua15 />} />
<Route path="/tamilbible-யோசுவா-16" element={<Joshua16 />} />
<Route path="/tamilbible-யோசுவா-17" element={<Joshua17 />} />
<Route path="/tamilbible-யோசுவா-18" element={<Joshua18 />} />
<Route path="/tamilbible-யோசுவா-19" element={<Joshua19 />} />
<Route path="/tamilbible-யோசுவா-20" element={<Joshua20 />} />
<Route path="/tamilbible-யோசுவா-21" element={<Joshua21 />} />
<Route path="/tamilbible-யோசுவா-22" element={<Joshua22 />} />
<Route path="/tamilbible-யோசுவா-23" element={<Joshua23 />} />
<Route path="/tamilbible-யோசுவா-24" element={<Joshua24 />} />

<Route path="/Niyayathibathigal" element={<Judges />} />
<Route path="/Niyayathibathigal-1" element={<Judges1 />} />
<Route path="/Niyayathibathigal-2" element={<Judges2 />} />
<Route path="/Niyayathibathigal-3" element={<Judges3 />} />
<Route path="/Niyayathibathigal-4" element={<Judges4 />} />
<Route path="/Niyayathibathigal-5" element={<Judges5 />} />
<Route path="/Niyayathibathigal-6" element={<Judges6 />} />
<Route path="/Niyayathibathigal-7" element={<Judges7 />} />
<Route path="/Niyayathibathigal-8" element={<Judges8 />} />
<Route path="/Niyayathibathigal-9" element={<Judges9 />} />
<Route path="/Niyayathibathigal-10" element={<Judges10 />} />
<Route path="/Niyayathibathigal-11" element={<Judges11 />} />
<Route path="/Niyayathibathigal-12" element={<Judges12 />} />
<Route path="/Niyayathibathigal-13" element={<Judges13 />} />
<Route path="/Niyayathibathigal-14" element={<Judges14 />} />
<Route path="/Niyayathibathigal-15" element={<Judges15 />} />
<Route path="/Niyayathibathigal-16" element={<Judges16 />} />
<Route path="/Niyayathibathigal-17" element={<Judges17 />} />
<Route path="/Niyayathibathigal-18" element={<Judges18 />} />
<Route path="/Niyayathibathigal-19" element={<Judges19 />} />
<Route path="/Niyayathibathigal-20" element={<Judges20 />} />
<Route path="/Niyayathibathigal-21" element={<Judges21 />} />

<Route path="/tamilbible-நியாயாதிபதிகள்" element={<Judges />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-1" element={<Judges1 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-2" element={<Judges2 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-3" element={<Judges3 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-4" element={<Judges4 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-5" element={<Judges5 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-6" element={<Judges6 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-7" element={<Judges7 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-8" element={<Judges8 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-9" element={<Judges9 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-10" element={<Judges10 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-11" element={<Judges11 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-12" element={<Judges12 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-13" element={<Judges13 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-14" element={<Judges14 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-15" element={<Judges15 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-16" element={<Judges16 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-17" element={<Judges17 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-18" element={<Judges18 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-19" element={<Judges19 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-20" element={<Judges20 />} />
<Route path="/tamilbible-நியாயாதிபதிகள்-21" element={<Judges21 />} />

<Route path="/Judges" element={<Judges />} />
<Route path="/Judges-1" element={<Judges1 />} />
<Route path="/Judges-2" element={<Judges2 />} />
<Route path="/Judges-3" element={<Judges3 />} />
<Route path="/Judges-4" element={<Judges4 />} />
<Route path="/Judges-5" element={<Judges5 />} />
<Route path="/Judges-6" element={<Judges6 />} />
<Route path="/Judges-7" element={<Judges7 />} />
<Route path="/Judges-8" element={<Judges8 />} />
<Route path="/Judges-9" element={<Judges9 />} />
<Route path="/Judges-10" element={<Judges10 />} />
<Route path="/Judges-11" element={<Judges11 />} />
<Route path="/Judges-12" element={<Judges12 />} />
<Route path="/Judges-13" element={<Judges13 />} />
<Route path="/Judges-14" element={<Judges14 />} />
<Route path="/Judges-15" element={<Judges15 />} />
<Route path="/Judges-16" element={<Judges16 />} />
<Route path="/Judges-17" element={<Judges17 />} />
<Route path="/Judges-18" element={<Judges18 />} />
<Route path="/Judges-19" element={<Judges19 />} />
<Route path="/Judges-20" element={<Judges20 />} />
<Route path="/Judges-21" element={<Judges21 />} />

<Route path="/Ruth" element={<Ruth />} />
<Route path="/Ruth-1" element={<Ruth1 />} />
<Route path="/Ruth-2" element={<Ruth2 />} />
<Route path="/Ruth-3" element={<Ruth3 />} />
<Route path="/Ruth-4" element={<Ruth4 />} />

<Route path="/tamilbible-ரூத்" element={<Ruth />} />
<Route path="/tamilbible-ரூத்-1" element={<Ruth1 />} />
<Route path="/tamilbible-ரூத்-2" element={<Ruth2 />} />
<Route path="/tamilbible-ரூத்-3" element={<Ruth3 />} />
<Route path="/tamilbible-ரூத்-4" element={<Ruth4 />} />

<Route path="/1Samuel"    element={<OneSamuel />} />
<Route path="/1Samuel-1"  element={<OneSamuel1 />} />
<Route path="/1Samuel-2"  element={<OneSamuel2 />} />
<Route path="/1Samuel-3"  element={<OneSamuel3 />} />
<Route path="/1Samuel-4"  element={<OneSamuel4 />} />
<Route path="/1Samuel-5"  element={<OneSamuel5 />} />
<Route path="/1Samuel-6"  element={<OneSamuel6 />} />
<Route path="/1Samuel-7"  element={<OneSamuel7 />} />
<Route path="/1Samuel-8"  element={<OneSamuel8 />} />
<Route path="/1Samuel-9"  element={<OneSamuel9 />} />
<Route path="/1Samuel-10" element={<OneSamuel10 />} />
<Route path="/1Samuel-11" element={<OneSamuel11 />} />
<Route path="/1Samuel-12" element={<OneSamuel12 />} />
<Route path="/1Samuel-13" element={<OneSamuel13 />} />
<Route path="/1Samuel-14" element={<OneSamuel14 />} />
<Route path="/1Samuel-15" element={<OneSamuel15 />} />
<Route path="/1Samuel-16" element={<OneSamuel16 />} />
<Route path="/1Samuel-17" element={<OneSamuel17 />} />
<Route path="/1Samuel-18" element={<OneSamuel18 />} />
<Route path="/1Samuel-19" element={<OneSamuel19 />} />
<Route path="/1Samuel-20" element={<OneSamuel20 />} />
<Route path="/1Samuel-21" element={<OneSamuel21 />} />
<Route path="/1Samuel-22" element={<OneSamuel22 />} />
<Route path="/1Samuel-23" element={<OneSamuel23 />} />
<Route path="/1Samuel-24" element={<OneSamuel24 />} />
<Route path="/1Samuel-25" element={<OneSamuel25 />} />
<Route path="/1Samuel-26" element={<OneSamuel26 />} />
<Route path="/1Samuel-27" element={<OneSamuel27 />} />
<Route path="/1Samuel-28" element={<OneSamuel28 />} />
<Route path="/1Samuel-29" element={<OneSamuel29 />} />
<Route path="/1Samuel-30" element={<OneSamuel30 />} />
<Route path="/1Samuel-31" element={<OneSamuel31 />} />

<Route path="/tamilbible-1சாமுவேல்" element={<OneSamuel />} />
<Route path="/tamilbible-1சாமுவேல்-1"  element={<OneSamuel1 />} />
<Route path="/tamilbible-1சாமுவேல்-2"  element={<OneSamuel2 />} />
<Route path="/tamilbible-1சாமுவேல்-3"  element={<OneSamuel3 />} />
<Route path="/tamilbible-1சாமுவேல்-4"  element={<OneSamuel4 />} />
<Route path="/tamilbible-1சாமுவேல்-5"  element={<OneSamuel5 />} />
<Route path="/tamilbible-1சாமுவேல்-6"  element={<OneSamuel6 />} />
<Route path="/tamilbible-1சாமுவேல்-7"  element={<OneSamuel7 />} />
<Route path="/tamilbible-1சாமுவேல்-8"  element={<OneSamuel8 />} />
<Route path="/tamilbible-1சாமுவேல்-9"  element={<OneSamuel9 />} />
<Route path="/tamilbible-1சாமுவேல்-10" element={<OneSamuel10 />} />
<Route path="/tamilbible-1சாமுவேல்-11" element={<OneSamuel11 />} />
<Route path="/tamilbible-1சாமுவேல்-12" element={<OneSamuel12 />} />
<Route path="/tamilbible-1சாமுவேல்-13" element={<OneSamuel13 />} />
<Route path="/tamilbible-1சாமுவேல்-14" element={<OneSamuel14 />} />
<Route path="/tamilbible-1சாமுவேல்-15" element={<OneSamuel15 />} />
<Route path="/tamilbible-1சாமுவேல்-16" element={<OneSamuel16 />} />
<Route path="/tamilbible-1சாமுவேல்-17" element={<OneSamuel17 />} />
<Route path="/tamilbible-1சாமுவேல்-18" element={<OneSamuel18 />} />
<Route path="/tamilbible-1சாமுவேல்-19" element={<OneSamuel19 />} />
<Route path="/tamilbible-1சாமுவேல்-20" element={<OneSamuel20 />} />
<Route path="/tamilbible-1சாமுவேல்-21" element={<OneSamuel21 />} />
<Route path="/tamilbible-1சாமுவேல்-22" element={<OneSamuel22 />} />
<Route path="/tamilbible-1சாமுவேல்-23" element={<OneSamuel23 />} />
<Route path="/tamilbible-1சாமுவேல்-24" element={<OneSamuel24 />} />
<Route path="/tamilbible-1சாமுவேல்-25" element={<OneSamuel25 />} />
<Route path="/tamilbible-1சாமுவேல்-26" element={<OneSamuel26 />} />
<Route path="/tamilbible-1சாமுவேல்-27" element={<OneSamuel27 />} />
<Route path="/tamilbible-1சாமுவேல்-28" element={<OneSamuel28 />} />
<Route path="/tamilbible-1சாமுவேல்-29" element={<OneSamuel29 />} />
<Route path="/tamilbible-1சாமுவேல்-30" element={<OneSamuel30 />} />
<Route path="/tamilbible-1சாமுவேல்-31" element={<OneSamuel31 />} />

<Route path="/tamilbible-2சாமுவேல்" element={<TwoSamuel />} />
<Route path="/tamilbible-2சாமுவேல்-1"  element={<TwoSamuel1 />} />
<Route path="/tamilbible-2சாமுவேல்-2"  element={<TwoSamuel2 />} />
<Route path="/tamilbible-2சாமுவேல்-3"  element={<TwoSamuel3 />} />
<Route path="/tamilbible-2சாமுவேல்-4"  element={<TwoSamuel4 />} />
<Route path="/tamilbible-2சாமுவேல்-5"  element={<TwoSamuel5 />} />
<Route path="/tamilbible-2சாமுவேல்-6"  element={<TwoSamuel6 />} />
<Route path="/tamilbible-2சாமுவேல்-7"  element={<TwoSamuel7 />} />
<Route path="/tamilbible-2சாமுவேல்-8"  element={<TwoSamuel8 />} />
<Route path="/tamilbible-2சாமுவேல்-9"  element={<TwoSamuel9 />} />
<Route path="/tamilbible-2சாமுவேல்-10" element={<TwoSamuel10 />} />
<Route path="/tamilbible-2சாமுவேல்-11" element={<TwoSamuel11 />} />
<Route path="/tamilbible-2சாமுவேல்-12" element={<TwoSamuel12 />} />
<Route path="/tamilbible-2சாமுவேல்-13" element={<TwoSamuel13 />} />
<Route path="/tamilbible-2சாமுவேல்-14" element={<TwoSamuel14 />} />
<Route path="/tamilbible-2சாமுவேல்-15" element={<TwoSamuel15 />} />
<Route path="/tamilbible-2சாமுவேல்-16" element={<TwoSamuel16 />} />
<Route path="/tamilbible-2சாமுவேல்-17" element={<TwoSamuel17 />} />
<Route path="/tamilbible-2சாமுவேல்-18" element={<TwoSamuel18 />} />
<Route path="/tamilbible-2சாமுவேல்-19" element={<TwoSamuel19 />} />
<Route path="/tamilbible-2சாமுவேல்-20" element={<TwoSamuel20 />} />
<Route path="/tamilbible-2சாமுவேல்-21" element={<TwoSamuel21 />} />
<Route path="/tamilbible-2சாமுவேல்-22" element={<TwoSamuel22 />} />
<Route path="/tamilbible-2சாமுவேல்-23" element={<TwoSamuel23 />} />
<Route path="/tamilbible-2சாமுவேல்-24" element={<TwoSamuel24 />} />

<Route path="/2Samuel" element={<TwoSamuel />} />
<Route path="/2Samuel-1"  element={<TwoSamuel1 />} />
<Route path="/2Samuel-2"  element={<TwoSamuel2 />} />
<Route path="/2Samuel-3"  element={<TwoSamuel3 />} />
<Route path="/2Samuel-4"  element={<TwoSamuel4 />} />
<Route path="/2Samuel-5"  element={<TwoSamuel5 />} />
<Route path="/2Samuel-6"  element={<TwoSamuel6 />} />
<Route path="/2Samuel-7"  element={<TwoSamuel7 />} />
<Route path="/2Samuel-8"  element={<TwoSamuel8 />} />
<Route path="/2Samuel-9"  element={<TwoSamuel9 />} />
<Route path="/2Samuel-10" element={<TwoSamuel10 />} />
<Route path="/2Samuel-11" element={<TwoSamuel11 />} />
<Route path="/2Samuel-12" element={<TwoSamuel12 />} />
<Route path="/2Samuel-13" element={<TwoSamuel13 />} />
<Route path="/2Samuel-14" element={<TwoSamuel14 />} />
<Route path="/2Samuel-15" element={<TwoSamuel15 />} />
<Route path="/2Samuel-16" element={<TwoSamuel16 />} />
<Route path="/2Samuel-17" element={<TwoSamuel17 />} />
<Route path="/2Samuel-18" element={<TwoSamuel18 />} />
<Route path="/2Samuel-19" element={<TwoSamuel19 />} />
<Route path="/2Samuel-20" element={<TwoSamuel20 />} />
<Route path="/2Samuel-21" element={<TwoSamuel21 />} />
<Route path="/2Samuel-22" element={<TwoSamuel22 />} />
<Route path="/2Samuel-23" element={<TwoSamuel23 />} />
<Route path="/2Samuel-24" element={<TwoSamuel24 />} />


<Route path="/1Rajakkal"    element={<OneKings />} />
<Route path="/1Rajakkal-1"  element={<OneRajakkal1 />} />
<Route path="/1Rajakkal-2"  element={<OneRajakkal2 />} />
<Route path="/1Rajakkal-3"  element={<OneRajakkal3 />} />
<Route path="/1Rajakkal-4"  element={<OneRajakkal4 />} />
<Route path="/1Rajakkal-5"  element={<OneRajakkal5 />} />
<Route path="/1Rajakkal-6"  element={<OneRajakkal6 />} />
<Route path="/1Rajakkal-7"  element={<OneRajakkal7 />} />
<Route path="/1Rajakkal-8"  element={<OneRajakkal8 />} />
<Route path="/1Rajakkal-9"  element={<OneRajakkal9 />} />
<Route path="/1Rajakkal-10" element={<OneRajakkal10 />} />
<Route path="/1Rajakkal-11" element={<OneRajakkal11 />} />
<Route path="/1Rajakkal-12" element={<OneRajakkal12 />} />
<Route path="/1Rajakkal-13" element={<OneRajakkal13 />} />
<Route path="/1Rajakkal-14" element={<OneRajakkal14 />} />
<Route path="/1Rajakkal-15" element={<OneRajakkal15 />} />
<Route path="/1Rajakkal-16" element={<OneRajakkal16 />} />
<Route path="/1Rajakkal-17" element={<OneRajakkal17 />} />
<Route path="/1Rajakkal-18" element={<OneRajakkal18 />} />
<Route path="/1Rajakkal-19" element={<OneRajakkal19 />} />
<Route path="/1Rajakkal-20" element={<OneRajakkal20 />} />
<Route path="/1Rajakkal-21" element={<OneRajakkal21 />} />
<Route path="/1Rajakkal-22" element={<OneRajakkal22 />} />

<Route path="/1Kings"    element={<OneKings />} />
<Route path="/1Kings-1"  element={<OneRajakkal1 />} />
<Route path="/1Kings-2"  element={<OneRajakkal2 />} />
<Route path="/1Kings-3"  element={<OneRajakkal3 />} />
<Route path="/1Kings-4"  element={<OneRajakkal4 />} />
<Route path="/1Kings-5"  element={<OneRajakkal5 />} />
<Route path="/1Kings-6"  element={<OneRajakkal6 />} />
<Route path="/1Kings-7"  element={<OneRajakkal7 />} />
<Route path="/1Kings-8"  element={<OneRajakkal8 />} />
<Route path="/1Kings-9"  element={<OneRajakkal9 />} />
<Route path="/1Kings-10" element={<OneRajakkal10 />} />
<Route path="/1Kings-11" element={<OneRajakkal11 />} />
<Route path="/1Kings-12" element={<OneRajakkal12 />} />
<Route path="/1Kings-13" element={<OneRajakkal13 />} />
<Route path="/1Kings-14" element={<OneRajakkal14 />} />
<Route path="/1Kings-15" element={<OneRajakkal15 />} />
<Route path="/1Kings-16" element={<OneRajakkal16 />} />
<Route path="/1Kings-17" element={<OneRajakkal17 />} />
<Route path="/1Kings-18" element={<OneRajakkal18 />} />
<Route path="/1Kings-19" element={<OneRajakkal19 />} />
<Route path="/1Kings-20" element={<OneRajakkal20 />} />
<Route path="/1Kings-21" element={<OneRajakkal21 />} />
<Route path="/1Kings-22" element={<OneRajakkal22 />} />

<Route path="/tamilbible-1இராஜாக்கள்"    element={<OneKings />} />
<Route path="/tamilbible-1இராஜாக்கள்-1"  element={<OneRajakkal1 />} />
<Route path="/tamilbible-1இராஜாக்கள்-2"  element={<OneRajakkal2 />} />
<Route path="/tamilbible-1இராஜாக்கள்-3"  element={<OneRajakkal3 />} />
<Route path="/tamilbible-1இராஜாக்கள்-4"  element={<OneRajakkal4 />} />
<Route path="/tamilbible-1இராஜாக்கள்-5"  element={<OneRajakkal5 />} />
<Route path="/tamilbible-1இராஜாக்கள்-6"  element={<OneRajakkal6 />} />
<Route path="/tamilbible-1இராஜாக்கள்-7"  element={<OneRajakkal7 />} />
<Route path="/tamilbible-1இராஜாக்கள்-8"  element={<OneRajakkal8 />} />
<Route path="/tamilbible-1இராஜாக்கள்-9"  element={<OneRajakkal9 />} />
<Route path="/tamilbible-1இராஜாக்கள்-10" element={<OneRajakkal10 />} />
<Route path="/tamilbible-1இராஜாக்கள்-11" element={<OneRajakkal11 />} />
<Route path="/tamilbible-1இராஜாக்கள்-12" element={<OneRajakkal12 />} />
<Route path="/tamilbible-1இராஜாக்கள்-13" element={<OneRajakkal13 />} />
<Route path="/tamilbible-1இராஜாக்கள்-14" element={<OneRajakkal14 />} />
<Route path="/tamilbible-1இராஜாக்கள்-15" element={<OneRajakkal15 />} />
<Route path="/tamilbible-1இராஜாக்கள்-16" element={<OneRajakkal16 />} />
<Route path="/tamilbible-1இராஜாக்கள்-17" element={<OneRajakkal17 />} />
<Route path="/tamilbible-1இராஜாக்கள்-18" element={<OneRajakkal18 />} />
<Route path="/tamilbible-1இராஜாக்கள்-19" element={<OneRajakkal19 />} />
<Route path="/tamilbible-1இராஜாக்கள்-20" element={<OneRajakkal20 />} />
<Route path="/tamilbible-1இராஜாக்கள்-21" element={<OneRajakkal21 />} />
<Route path="/tamilbible-1இராஜாக்கள்-22" element={<OneRajakkal22 />} />

<Route path="/2Rajakkal"    element={<TwoKings />} />
<Route path="/2Rajakkal-1"  element={<TwoRajakkal1 />} />
<Route path="/2Rajakkal-2"  element={<TwoRajakkal2 />} />
<Route path="/2Rajakkal-3"  element={<TwoRajakkal3 />} />
<Route path="/2Rajakkal-4"  element={<TwoRajakkal4 />} />
<Route path="/2Rajakkal-5"  element={<TwoRajakkal5 />} />
<Route path="/2Rajakkal-6"  element={<TwoRajakkal6 />} />
<Route path="/2Rajakkal-7"  element={<TwoRajakkal7 />} />
<Route path="/2Rajakkal-8"  element={<TwoRajakkal8 />} />
<Route path="/2Rajakkal-9"  element={<TwoRajakkal9 />} />
<Route path="/2Rajakkal-10" element={<TwoRajakkal10 />} />
<Route path="/2Rajakkal-11" element={<TwoRajakkal11 />} />
<Route path="/2Rajakkal-12" element={<TwoRajakkal12 />} />
<Route path="/2Rajakkal-13" element={<TwoRajakkal13 />} />
<Route path="/2Rajakkal-14" element={<TwoRajakkal14 />} />
<Route path="/2Rajakkal-15" element={<TwoRajakkal15 />} />
<Route path="/2Rajakkal-16" element={<TwoRajakkal16 />} />
<Route path="/2Rajakkal-17" element={<TwoRajakkal17 />} />
<Route path="/2Rajakkal-18" element={<TwoRajakkal18 />} />
<Route path="/2Rajakkal-19" element={<TwoRajakkal19 />} />
<Route path="/2Rajakkal-20" element={<TwoRajakkal20 />} />
<Route path="/2Rajakkal-21" element={<TwoRajakkal21 />} />
<Route path="/2Rajakkal-22" element={<TwoRajakkal22 />} />
<Route path="/2Rajakkal-23" element={<TwoRajakkal23 />} />
<Route path="/2Rajakkal-24" element={<TwoRajakkal24 />} />
<Route path="/2Rajakkal-25" element={<TwoRajakkal25 />} />

<Route path="/2Kings"    element={<TwoKings />} />
<Route path="/2Kings-1"  element={<TwoRajakkal1 />} />
<Route path="/2Kings-2"  element={<TwoRajakkal2 />} />
<Route path="/2Kings-3"  element={<TwoRajakkal3 />} />
<Route path="/2Kings-4"  element={<TwoRajakkal4 />} />
<Route path="/2Kings-5"  element={<TwoRajakkal5 />} />
<Route path="/2Kings-6"  element={<TwoRajakkal6 />} />
<Route path="/2Kings-7"  element={<TwoRajakkal7 />} />
<Route path="/2Kings-8"  element={<TwoRajakkal8 />} />
<Route path="/2Kings-9"  element={<TwoRajakkal9 />} />
<Route path="/2Kings-10" element={<TwoRajakkal10 />} />
<Route path="/2Kings-11" element={<TwoRajakkal11 />} />
<Route path="/2Kings-12" element={<TwoRajakkal12 />} />
<Route path="/2Kings-13" element={<TwoRajakkal13 />} />
<Route path="/2Kings-14" element={<TwoRajakkal14 />} />
<Route path="/2Kings-15" element={<TwoRajakkal15 />} />
<Route path="/2Kings-16" element={<TwoRajakkal16 />} />
<Route path="/2Kings-17" element={<TwoRajakkal17 />} />
<Route path="/2Kings-18" element={<TwoRajakkal18 />} />
<Route path="/2Kings-19" element={<TwoRajakkal19 />} />
<Route path="/2Kings-20" element={<TwoRajakkal20 />} />
<Route path="/2Kings-21" element={<TwoRajakkal21 />} />
<Route path="/2Kings-22" element={<TwoRajakkal22 />} />
<Route path="/2Kings-23" element={<TwoRajakkal23 />} />
<Route path="/2Kings-24" element={<TwoRajakkal24 />} />
<Route path="/2Kings-25" element={<TwoRajakkal25 />} />

<Route path="/tamilbible-2இராஜாக்கள்"    element={<TwoKings />} />
<Route path="/tamilbible-2இராஜாக்கள்-1"  element={<TwoRajakkal1 />} />
<Route path="/tamilbible-2இராஜாக்கள்-2"  element={<TwoRajakkal2 />} />
<Route path="/tamilbible-2இராஜாக்கள்-3"  element={<TwoRajakkal3 />} />
<Route path="/tamilbible-2இராஜாக்கள்-4"  element={<TwoRajakkal4 />} />
<Route path="/tamilbible-2இராஜாக்கள்-5"  element={<TwoRajakkal5 />} />
<Route path="/tamilbible-2இராஜாக்கள்-6"  element={<TwoRajakkal6 />} />
<Route path="/tamilbible-2இராஜாக்கள்-7"  element={<TwoRajakkal7 />} />
<Route path="/tamilbible-2இராஜாக்கள்-8"  element={<TwoRajakkal8 />} />
<Route path="/tamilbible-2இராஜாக்கள்-9"  element={<TwoRajakkal9 />} />
<Route path="/tamilbible-2இராஜாக்கள்-10" element={<TwoRajakkal10 />} />
<Route path="/tamilbible-2இராஜாக்கள்-11" element={<TwoRajakkal11 />} />
<Route path="/tamilbible-2இராஜாக்கள்-12" element={<TwoRajakkal12 />} />
<Route path="/tamilbible-2இராஜாக்கள்-13" element={<TwoRajakkal13 />} />
<Route path="/tamilbible-2இராஜாக்கள்-14" element={<TwoRajakkal14 />} />
<Route path="/tamilbible-2இராஜாக்கள்-15" element={<TwoRajakkal15 />} />
<Route path="/tamilbible-2இராஜாக்கள்-16" element={<TwoRajakkal16 />} />
<Route path="/tamilbible-2இராஜாக்கள்-17" element={<TwoRajakkal17 />} />
<Route path="/tamilbible-2இராஜாக்கள்-18" element={<TwoRajakkal18 />} />
<Route path="/tamilbible-2இராஜாக்கள்-19" element={<TwoRajakkal19 />} />
<Route path="/tamilbible-2இராஜாக்கள்-20" element={<TwoRajakkal20 />} />
<Route path="/tamilbible-2இராஜாக்கள்-21" element={<TwoRajakkal21 />} />
<Route path="/tamilbible-2இராஜாக்கள்-22" element={<TwoRajakkal22 />} />
<Route path="/tamilbible-2இராஜாக்கள்-23" element={<TwoRajakkal23 />} />
<Route path="/tamilbible-2இராஜாக்கள்-24" element={<TwoRajakkal24 />} />
<Route path="/tamilbible-2இராஜாக்கள்-25" element={<TwoRajakkal25 />} />


<Route path="/1Nalagamam" element={<OneChronicles />} />
<Route path="/1Nalagamam-1"  element={<OneChronicles1 />} />
<Route path="/1Nalagamam-2"  element={<OneChronicles2 />} />
<Route path="/1Nalagamam-3"  element={<OneChronicles3 />} />
<Route path="/1Nalagamam-4"  element={<OneChronicles4 />} />
<Route path="/1Nalagamam-5"  element={<OneChronicles5 />} />
<Route path="/1Nalagamam-6"  element={<OneChronicles6 />} />
<Route path="/1Nalagamam-7"  element={<OneChronicles7 />} />
<Route path="/1Nalagamam-8"  element={<OneChronicles8 />} />
<Route path="/1Nalagamam-9"  element={<OneChronicles9 />} />
<Route path="/1Nalagamam-10" element={<OneChronicles10 />} />
<Route path="/1Nalagamam-11" element={<OneChronicles11 />} />
<Route path="/1Nalagamam-12" element={<OneChronicles12 />} />
<Route path="/1Nalagamam-13" element={<OneChronicles13 />} />
<Route path="/1Nalagamam-14" element={<OneChronicles14 />} />
<Route path="/1Nalagamam-15" element={<OneChronicles15 />} />
<Route path="/1Nalagamam-16" element={<OneChronicles16 />} />
<Route path="/1Nalagamam-17" element={<OneChronicles17 />} />
<Route path="/1Nalagamam-18" element={<OneChronicles18 />} />
<Route path="/1Nalagamam-19" element={<OneChronicles19 />} />
<Route path="/1Nalagamam-20" element={<OneChronicles20 />} />
<Route path="/1Nalagamam-21" element={<OneChronicles21 />} />
<Route path="/1Nalagamam-22" element={<OneChronicles22 />} />
<Route path="/1Nalagamam-23" element={<OneChronicles23 />} />
<Route path="/1Nalagamam-24" element={<OneChronicles24 />} />
<Route path="/1Nalagamam-25" element={<OneChronicles25 />} />
<Route path="/1Nalagamam-26" element={<OneChronicles26 />} />
<Route path="/1Nalagamam-27" element={<OneChronicles27 />} />
<Route path="/1Nalagamam-28" element={<OneChronicles28 />} />
<Route path="/1Nalagamam-29" element={<OneChronicles29 />} />

<Route path="/1Chronicles"    element={<OneChronicles />} />
<Route path="/1Chronicles-1"  element={<OneChronicles1 />} />
<Route path="/1Chronicles-2"  element={<OneChronicles2 />} />
<Route path="/1Chronicles-3"  element={<OneChronicles3 />} />
<Route path="/1Chronicles-4"  element={<OneChronicles4 />} />
<Route path="/1Chronicles-5"  element={<OneChronicles5 />} />
<Route path="/1Chronicles-6"  element={<OneChronicles6 />} />
<Route path="/1Chronicles-7"  element={<OneChronicles7 />} />
<Route path="/1Chronicles-8"  element={<OneChronicles8 />} />
<Route path="/1Chronicles-9"  element={<OneChronicles9 />} />
<Route path="/1Chronicles-10" element={<OneChronicles10 />} />
<Route path="/1Chronicles-11" element={<OneChronicles11 />} />
<Route path="/1Chronicles-12" element={<OneChronicles12 />} />
<Route path="/1Chronicles-13" element={<OneChronicles13 />} />
<Route path="/1Chronicles-14" element={<OneChronicles14 />} />
<Route path="/1Chronicles-15" element={<OneChronicles15 />} />
<Route path="/1Chronicles-16" element={<OneChronicles16 />} />
<Route path="/1Chronicles-17" element={<OneChronicles17 />} />
<Route path="/1Chronicles-18" element={<OneChronicles18 />} />
<Route path="/1Chronicles-19" element={<OneChronicles19 />} />
<Route path="/1Chronicles-20" element={<OneChronicles20 />} />
<Route path="/1Chronicles-21" element={<OneChronicles21 />} />
<Route path="/1Chronicles-22" element={<OneChronicles22 />} />
<Route path="/1Chronicles-23" element={<OneChronicles23 />} />
<Route path="/1Chronicles-24" element={<OneChronicles24 />} />
<Route path="/1Chronicles-25" element={<OneChronicles25 />} />
<Route path="/1Chronicles-26" element={<OneChronicles26 />} />
<Route path="/1Chronicles-27" element={<OneChronicles27 />} />
<Route path="/1Chronicles-28" element={<OneChronicles28 />} />
<Route path="/1Chronicles-29" element={<OneChronicles29 />} />

<Route path="/tamilbible-1நாளாகமம்"    element={<OneChronicles />} />
<Route path="/tamilbible-1நாளாகமம்-1"  element={<OneChronicles1 />} />
<Route path="/tamilbible-1நாளாகமம்-2"  element={<OneChronicles2 />} />
<Route path="/tamilbible-1நாளாகமம்-3"  element={<OneChronicles3 />} />
<Route path="/tamilbible-1நாளாகமம்-4"  element={<OneChronicles4 />} />
<Route path="/tamilbible-1நாளாகமம்-5"  element={<OneChronicles5 />} />
<Route path="/tamilbible-1நாளாகமம்-6"  element={<OneChronicles6 />} />
<Route path="/tamilbible-1நாளாகமம்-7"  element={<OneChronicles7 />} />
<Route path="/tamilbible-1நாளாகமம்-8"  element={<OneChronicles8 />} />
<Route path="/tamilbible-1நாளாகமம்-9"  element={<OneChronicles9 />} />
<Route path="/tamilbible-1நாளாகமம்-10" element={<OneChronicles10 />} />
<Route path="/tamilbible-1நாளாகமம்-11" element={<OneChronicles11 />} />
<Route path="/tamilbible-1நாளாகமம்-12" element={<OneChronicles12 />} />
<Route path="/tamilbible-1நாளாகமம்-13" element={<OneChronicles13 />} />
<Route path="/tamilbible-1நாளாகமம்-14" element={<OneChronicles14 />} />
<Route path="/tamilbible-1நாளாகமம்-15" element={<OneChronicles15 />} />
<Route path="/tamilbible-1நாளாகமம்-16" element={<OneChronicles16 />} />
<Route path="/tamilbible-1நாளாகமம்-17" element={<OneChronicles17 />} />
<Route path="/tamilbible-1நாளாகமம்-18" element={<OneChronicles18 />} />
<Route path="/tamilbible-1நாளாகமம்-19" element={<OneChronicles19 />} />
<Route path="/tamilbible-1நாளாகமம்-20" element={<OneChronicles20 />} />
<Route path="/tamilbible-1நாளாகமம்-21" element={<OneChronicles21 />} />
<Route path="/tamilbible-1நாளாகமம்-22" element={<OneChronicles22 />} />
<Route path="/tamilbible-1நாளாகமம்-23" element={<OneChronicles23 />} />
<Route path="/tamilbible-1நாளாகமம்-24" element={<OneChronicles24 />} />
<Route path="/tamilbible-1நாளாகமம்-25" element={<OneChronicles25 />} />
<Route path="/tamilbible-1நாளாகமம்-26" element={<OneChronicles26 />} />
<Route path="/tamilbible-1நாளாகமம்-27" element={<OneChronicles27 />} />
<Route path="/tamilbible-1நாளாகமம்-28" element={<OneChronicles28 />} />
<Route path="/tamilbible-1நாளாகமம்-29" element={<OneChronicles29 />} />

<Route path="/2Nalagamam" element={<TwoChronicles />} />
<Route path="/2Nalagamam-1"  element={<TwoChronicles1 />} />
<Route path="/2Nalagamam-2"  element={<TwoChronicles2 />} />
<Route path="/2Nalagamam-3"  element={<TwoChronicles3 />} />
<Route path="/2Nalagamam-4"  element={<TwoChronicles4 />} />
<Route path="/2Nalagamam-5"  element={<TwoChronicles5 />} />
<Route path="/2Nalagamam-6"  element={<TwoChronicles6 />} />
<Route path="/2Nalagamam-7"  element={<TwoChronicles7 />} />
<Route path="/2Nalagamam-8"  element={<TwoChronicles8 />} />
<Route path="/2Nalagamam-9"  element={<TwoChronicles9 />} />
<Route path="/2Nalagamam-10" element={<TwoChronicles10 />} />
<Route path="/2Nalagamam-11" element={<TwoChronicles11 />} />
<Route path="/2Nalagamam-12" element={<TwoChronicles12 />} />
<Route path="/2Nalagamam-13" element={<TwoChronicles13 />} />
<Route path="/2Nalagamam-14" element={<TwoChronicles14 />} />
<Route path="/2Nalagamam-15" element={<TwoChronicles15 />} />
<Route path="/2Nalagamam-16" element={<TwoChronicles16 />} />
<Route path="/2Nalagamam-17" element={<TwoChronicles17 />} />
<Route path="/2Nalagamam-18" element={<TwoChronicles18 />} />
<Route path="/2Nalagamam-19" element={<TwoChronicles19 />} />
<Route path="/2Nalagamam-20" element={<TwoChronicles20 />} />
<Route path="/2Nalagamam-21" element={<TwoChronicles21 />} />
<Route path="/2Nalagamam-22" element={<TwoChronicles22 />} />
<Route path="/2Nalagamam-23" element={<TwoChronicles23 />} />
<Route path="/2Nalagamam-24" element={<TwoChronicles24 />} />
<Route path="/2Nalagamam-25" element={<TwoChronicles25 />} />
<Route path="/2Nalagamam-26" element={<TwoChronicles26 />} />
<Route path="/2Nalagamam-27" element={<TwoChronicles27 />} />
<Route path="/2Nalagamam-28" element={<TwoChronicles28 />} />
<Route path="/2Nalagamam-29" element={<TwoChronicles29 />} />
<Route path="/2Nalagamam-30" element={<TwoChronicles30 />} />
<Route path="/2Nalagamam-31" element={<TwoChronicles31 />} />
<Route path="/2Nalagamam-32" element={<TwoChronicles32 />} />
<Route path="/2Nalagamam-33" element={<TwoChronicles33 />} />
<Route path="/2Nalagamam-34" element={<TwoChronicles34 />} />
<Route path="/2Nalagamam-35" element={<TwoChronicles35 />} />
<Route path="/2Nalagamam-36" element={<TwoChronicles36 />} />

<Route path="/2Chronicles"    element={<TwoChronicles />} />
<Route path="/2Chronicles-1"  element={<TwoChronicles1 />} />
<Route path="/2Chronicles-2"  element={<TwoChronicles2 />} />
<Route path="/2Chronicles-3"  element={<TwoChronicles3 />} />
<Route path="/2Chronicles-4"  element={<TwoChronicles4 />} />
<Route path="/2Chronicles-5"  element={<TwoChronicles5 />} />
<Route path="/2Chronicles-6"  element={<TwoChronicles6 />} />
<Route path="/2Chronicles-7"  element={<TwoChronicles7 />} />
<Route path="/2Chronicles-8"  element={<TwoChronicles8 />} />
<Route path="/2Chronicles-9"  element={<TwoChronicles9 />} />
<Route path="/2Chronicles-10" element={<TwoChronicles10 />} />
<Route path="/2Chronicles-11" element={<TwoChronicles11 />} />
<Route path="/2Chronicles-12" element={<TwoChronicles12 />} />
<Route path="/2Chronicles-13" element={<TwoChronicles13 />} />
<Route path="/2Chronicles-14" element={<TwoChronicles14 />} />
<Route path="/2Chronicles-15" element={<TwoChronicles15 />} />
<Route path="/2Chronicles-16" element={<TwoChronicles16 />} />
<Route path="/2Chronicles-17" element={<TwoChronicles17 />} />
<Route path="/2Chronicles-18" element={<TwoChronicles18 />} />
<Route path="/2Chronicles-19" element={<TwoChronicles19 />} />
<Route path="/2Chronicles-20" element={<TwoChronicles20 />} />
<Route path="/2Chronicles-21" element={<TwoChronicles21 />} />
<Route path="/2Chronicles-22" element={<TwoChronicles22 />} />
<Route path="/2Chronicles-23" element={<TwoChronicles23 />} />
<Route path="/2Chronicles-24" element={<TwoChronicles24 />} />
<Route path="/2Chronicles-25" element={<TwoChronicles25 />} />
<Route path="/2Chronicles-26" element={<TwoChronicles26 />} />
<Route path="/2Chronicles-27" element={<TwoChronicles27 />} />
<Route path="/2Chronicles-28" element={<TwoChronicles28 />} />
<Route path="/2Chronicles-29" element={<TwoChronicles29 />} />
<Route path="/2Chronicles-30" element={<TwoChronicles30 />} />
<Route path="/2Chronicles-31" element={<TwoChronicles31 />} />
<Route path="/2Chronicles-32" element={<TwoChronicles32 />} />
<Route path="/2Chronicles-33" element={<TwoChronicles33 />} />
<Route path="/2Chronicles-34" element={<TwoChronicles34 />} />
<Route path="/2Chronicles-35" element={<TwoChronicles35 />} />
<Route path="/2Chronicles-36" element={<TwoChronicles36 />} />

<Route path="/tamilbible-2நாளாகமம்"    element={<TwoChronicles />} />
<Route path="/tamilbible-2நாளாகமம்-1"  element={<TwoChronicles1 />} />
<Route path="/tamilbible-2நாளாகமம்-2"  element={<TwoChronicles2 />} />
<Route path="/tamilbible-2நாளாகமம்-3"  element={<TwoChronicles3 />} />
<Route path="/tamilbible-2நாளாகமம்-4"  element={<TwoChronicles4 />} />
<Route path="/tamilbible-2நாளாகமம்-5"  element={<TwoChronicles5 />} />
<Route path="/tamilbible-2நாளாகமம்-6"  element={<TwoChronicles6 />} />
<Route path="/tamilbible-2நாளாகமம்-7"  element={<TwoChronicles7 />} />
<Route path="/tamilbible-2நாளாகமம்-8"  element={<TwoChronicles8 />} />
<Route path="/tamilbible-2நாளாகமம்-9"  element={<TwoChronicles9 />} />
<Route path="/tamilbible-2நாளாகமம்-10" element={<TwoChronicles10 />} />
<Route path="/tamilbible-2நாளாகமம்-11" element={<TwoChronicles11 />} />
<Route path="/tamilbible-2நாளாகமம்-12" element={<TwoChronicles12 />} />
<Route path="/tamilbible-2நாளாகமம்-13" element={<TwoChronicles13 />} />
<Route path="/tamilbible-2நாளாகமம்-14" element={<TwoChronicles14 />} />
<Route path="/tamilbible-2நாளாகமம்-15" element={<TwoChronicles15 />} />
<Route path="/tamilbible-2நாளாகமம்-16" element={<TwoChronicles16 />} />
<Route path="/tamilbible-2நாளாகமம்-17" element={<TwoChronicles17 />} />
<Route path="/tamilbible-2நாளாகமம்-18" element={<TwoChronicles18 />} />
<Route path="/tamilbible-2நாளாகமம்-19" element={<TwoChronicles19 />} />
<Route path="/tamilbible-2நாளாகமம்-20" element={<TwoChronicles20 />} />
<Route path="/tamilbible-2நாளாகமம்-21" element={<TwoChronicles21 />} />
<Route path="/tamilbible-2நாளாகமம்-22" element={<TwoChronicles22 />} />
<Route path="/tamilbible-2நாளாகமம்-23" element={<TwoChronicles23 />} />
<Route path="/tamilbible-2நாளாகமம்-24" element={<TwoChronicles24 />} />
<Route path="/tamilbible-2நாளாகமம்-25" element={<TwoChronicles25 />} />
<Route path="/tamilbible-2நாளாகமம்-26" element={<TwoChronicles26 />} />
<Route path="/tamilbible-2நாளாகமம்-27" element={<TwoChronicles27 />} />
<Route path="/tamilbible-2நாளாகமம்-28" element={<TwoChronicles28 />} />
<Route path="/tamilbible-2நாளாகமம்-29" element={<TwoChronicles29 />} />
<Route path="/tamilbible-2நாளாகமம்-30" element={<TwoChronicles30 />} />
<Route path="/tamilbible-2நாளாகமம்-31" element={<TwoChronicles31 />} />
<Route path="/tamilbible-2நாளாகமம்-32" element={<TwoChronicles32 />} />
<Route path="/tamilbible-2நாளாகமம்-33" element={<TwoChronicles33 />} />
<Route path="/tamilbible-2நாளாகமம்-34" element={<TwoChronicles34 />} />
<Route path="/tamilbible-2நாளாகமம்-35" element={<TwoChronicles35 />} />
<Route path="/tamilbible-2நாளாகமம்-36" element={<TwoChronicles36 />} />

<Route path="/Ezra"    element={<Ezra />} />
<Route path="/Ezra-1"  element={<Ezra1 />} />
<Route path="/Ezra-2"  element={<Ezra2 />} />
<Route path="/Ezra-3"  element={<Ezra3 />} />
<Route path="/Ezra-4"  element={<Ezra4 />} />
<Route path="/Ezra-5"  element={<Ezra5 />} />
<Route path="/Ezra-6"  element={<Ezra6 />} />
<Route path="/Ezra-7"  element={<Ezra7 />} />
<Route path="/Ezra-8"  element={<Ezra8 />} />
<Route path="/Ezra-9"  element={<Ezra9 />} />
<Route path="/Ezra-10" element={<Ezra10 />} />

<Route path="/tamilbible-எஸ்றா"    element={<Ezra />} />
<Route path="/tamilbible-எஸ்றா-1"  element={<Ezra1 />} />
<Route path="/tamilbible-எஸ்றா-2"  element={<Ezra2 />} />
<Route path="/tamilbible-எஸ்றா-3"  element={<Ezra3 />} />
<Route path="/tamilbible-எஸ்றா-4"  element={<Ezra4 />} />
<Route path="/tamilbible-எஸ்றா-5"  element={<Ezra5 />} />
<Route path="/tamilbible-எஸ்றா-6"  element={<Ezra6 />} />
<Route path="/tamilbible-எஸ்றா-7"  element={<Ezra7 />} />
<Route path="/tamilbible-எஸ்றா-8"  element={<Ezra8 />} />
<Route path="/tamilbible-எஸ்றா-9"  element={<Ezra9 />} />
<Route path="/tamilbible-எஸ்றா-10" element={<Ezra10 />} />

<Route path="/Nehemiah"    element={<Nehemiah />} />
<Route path="/Nehemiah-1"  element={<Nehemiah1 />} />
<Route path="/Nehemiah-2"  element={<Nehemiah2 />} />
<Route path="/Nehemiah-3"  element={<Nehemiah3 />} />
<Route path="/Nehemiah-4"  element={<Nehemiah4 />} />
<Route path="/Nehemiah-5"  element={<Nehemiah5 />} />
<Route path="/Nehemiah-6"  element={<Nehemiah6 />} />
<Route path="/Nehemiah-7"  element={<Nehemiah7 />} />
<Route path="/Nehemiah-8"  element={<Nehemiah8 />} />
<Route path="/Nehemiah-9"  element={<Nehemiah9 />} />
<Route path="/Nehemiah-10" element={<Nehemiah10 />} />
<Route path="/Nehemiah-11" element={<Nehemiah11 />} />
<Route path="/Nehemiah-12" element={<Nehemiah12 />} />
<Route path="/Nehemiah-13" element={<Nehemiah13 />} />

<Route path="/Nehemiya"    element={<Nehemiah />} />
<Route path="/Nehemiya-1"  element={<Nehemiah1 />} />
<Route path="/Nehemiya-2"  element={<Nehemiah2 />} />
<Route path="/Nehemiya-3"  element={<Nehemiah3 />} />
<Route path="/Nehemiya-4"  element={<Nehemiah4 />} />
<Route path="/Nehemiya-5"  element={<Nehemiah5 />} />
<Route path="/Nehemiya-6"  element={<Nehemiah6 />} />
<Route path="/Nehemiya-7"  element={<Nehemiah7 />} />
<Route path="/Nehemiya-8"  element={<Nehemiah8 />} />
<Route path="/Nehemiya-9"  element={<Nehemiah9 />} />
<Route path="/Nehemiya-10" element={<Nehemiah10 />} />
<Route path="/Nehemiya-11" element={<Nehemiah11 />} />
<Route path="/Nehemiya-12" element={<Nehemiah12 />} />
<Route path="/Nehemiya-13" element={<Nehemiah13 />} />

<Route path="/tamilbible-நெகேமியா"    element={<Nehemiah />} />
<Route path="/tamilbible-நெகேமியா-1"  element={<Nehemiah1 />} />
<Route path="/tamilbible-நெகேமியா-2"  element={<Nehemiah2 />} />
<Route path="/tamilbible-நெகேமியா-3"  element={<Nehemiah3 />} />
<Route path="/tamilbible-நெகேமியா-4"  element={<Nehemiah4 />} />
<Route path="/tamilbible-நெகேமியா-5"  element={<Nehemiah5 />} />
<Route path="/tamilbible-நெகேமியா-6"  element={<Nehemiah6 />} />
<Route path="/tamilbible-நெகேமியா-7"  element={<Nehemiah7 />} />
<Route path="/tamilbible-நெகேமியா-8"  element={<Nehemiah8 />} />
<Route path="/tamilbible-நெகேமியா-9"  element={<Nehemiah9 />} />
<Route path="/tamilbible-நெகேமியா-10" element={<Nehemiah10 />} />
<Route path="/tamilbible-நெகேமியா-11" element={<Nehemiah11 />} />
<Route path="/tamilbible-நெகேமியா-12" element={<Nehemiah12 />} />
<Route path="/tamilbible-நெகேமியா-13" element={<Nehemiah13 />} />

<Route path="/Esther"    element={<Esther />} />
<Route path="/Esther-1"  element={<Esther1 />} />
<Route path="/Esther-2"  element={<Esther2 />} />
<Route path="/Esther-3"  element={<Esther3 />} />
<Route path="/Esther-4"  element={<Esther4 />} />
<Route path="/Esther-5"  element={<Esther5 />} />
<Route path="/Esther-6"  element={<Esther6 />} />
<Route path="/Esther-7"  element={<Esther7 />} />
<Route path="/Esther-8"  element={<Esther8 />} />
<Route path="/Esther-9"  element={<Esther9 />} />
<Route path="/Esther-10" element={<Esther10 />} />

<Route path="/tamilbible-எஸ்தர்"    element={<Esther />} />
<Route path="/tamilbible-எஸ்தர்-1"  element={<Esther1 />} />
<Route path="/tamilbible-எஸ்தர்-2"  element={<Esther2 />} />
<Route path="/tamilbible-எஸ்தர்-3"  element={<Esther3 />} />
<Route path="/tamilbible-எஸ்தர்-4"  element={<Esther4 />} />
<Route path="/tamilbible-எஸ்தர்-5"  element={<Esther5 />} />
<Route path="/tamilbible-எஸ்தர்-6"  element={<Esther6 />} />
<Route path="/tamilbible-எஸ்தர்-7"  element={<Esther7 />} />
<Route path="/tamilbible-எஸ்தர்-8"  element={<Esther8 />} />
<Route path="/tamilbible-எஸ்தர்-9"  element={<Esther9 />} />
<Route path="/tamilbible-எஸ்தர்-10" element={<Esther10 />} />

<Route path="/Job"    element={<Job />} />
<Route path="/Job-1"  element={<Job1 />} />
<Route path="/Job-2"  element={<Job2 />} />
<Route path="/Job-3"  element={<Job3 />} />
<Route path="/Job-4"  element={<Job4 />} />
<Route path="/Job-5"  element={<Job5 />} />
<Route path="/Job-6"  element={<Job6 />} />
<Route path="/Job-7"  element={<Job7 />} />
<Route path="/Job-8"  element={<Job8 />} />
<Route path="/Job-9"  element={<Job9 />} />
<Route path="/Job-10" element={<Job10 />} />
<Route path="/Job-11" element={<Job11 />} />
<Route path="/Job-12" element={<Job12 />} />
<Route path="/Job-13" element={<Job13 />} />
<Route path="/Job-14" element={<Job14 />} />
<Route path="/Job-15" element={<Job15 />} />
<Route path="/Job-16" element={<Job16 />} />
<Route path="/Job-17" element={<Job17 />} />
<Route path="/Job-18" element={<Job18 />} />
<Route path="/Job-19" element={<Job19 />} />
<Route path="/Job-20" element={<Job20 />} />
<Route path="/Job-21" element={<Job21 />} />
<Route path="/Job-22" element={<Job22 />} />
<Route path="/Job-23" element={<Job23 />} />
<Route path="/Job-24" element={<Job24 />} />
<Route path="/Job-25" element={<Job25 />} />
<Route path="/Job-26" element={<Job26 />} />
<Route path="/Job-27" element={<Job27 />} />
<Route path="/Job-28" element={<Job28 />} />
<Route path="/Job-29" element={<Job29 />} />
<Route path="/Job-30" element={<Job30 />} />
<Route path="/Job-31" element={<Job31 />} />
<Route path="/Job-32" element={<Job32 />} />
<Route path="/Job-33" element={<Job33 />} />
<Route path="/Job-34" element={<Job34 />} />
<Route path="/Job-35" element={<Job35 />} />
<Route path="/Job-36" element={<Job36 />} />
<Route path="/Job-37" element={<Job37 />} />
<Route path="/Job-38" element={<Job38 />} />
<Route path="/Job-39" element={<Job39 />} />
<Route path="/Job-40" element={<Job40 />} />
<Route path="/Job-41" element={<Job41 />} />
<Route path="/Job-42" element={<Job42 />} />

<Route path="/Yobu"    element={<Job />} />
<Route path="/Yobu-1"  element={<Job1 />} />
<Route path="/Yobu-2"  element={<Job2 />} />
<Route path="/Yobu-3"  element={<Job3 />} />
<Route path="/Yobu-4"  element={<Job4 />} />
<Route path="/Yobu-5"  element={<Job5 />} />
<Route path="/Yobu-6"  element={<Job6 />} />
<Route path="/Yobu-7"  element={<Job7 />} />
<Route path="/Yobu-8"  element={<Job8 />} />
<Route path="/Yobu-9"  element={<Job9 />} />
<Route path="/Yobu-10" element={<Job10 />} />
<Route path="/Yobu-11" element={<Job11 />} />
<Route path="/Yobu-12" element={<Job12 />} />
<Route path="/Yobu-13" element={<Job13 />} />
<Route path="/Yobu-14" element={<Job14 />} />
<Route path="/Yobu-15" element={<Job15 />} />
<Route path="/Yobu-16" element={<Job16 />} />
<Route path="/Yobu-17" element={<Job17 />} />
<Route path="/Yobu-18" element={<Job18 />} />
<Route path="/Yobu-19" element={<Job19 />} />
<Route path="/Yobu-20" element={<Job20 />} />
<Route path="/Yobu-21" element={<Job21 />} />
<Route path="/Yobu-22" element={<Job22 />} />
<Route path="/Yobu-23" element={<Job23 />} />
<Route path="/Yobu-24" element={<Job24 />} />
<Route path="/Yobu-25" element={<Job25 />} />
<Route path="/Yobu-26" element={<Job26 />} />
<Route path="/Yobu-27" element={<Job27 />} />
<Route path="/Yobu-28" element={<Job28 />} />
<Route path="/Yobu-29" element={<Job29 />} />
<Route path="/Yobu-30" element={<Job30 />} />
<Route path="/Yobu-31" element={<Job31 />} />
<Route path="/Yobu-32" element={<Job32 />} />
<Route path="/Yobu-33" element={<Job33 />} />
<Route path="/Yobu-34" element={<Job34 />} />
<Route path="/Yobu-35" element={<Job35 />} />
<Route path="/Yobu-36" element={<Job36 />} />
<Route path="/Yobu-37" element={<Job37 />} />
<Route path="/Yobu-38" element={<Job38 />} />
<Route path="/Yobu-39" element={<Job39 />} />
<Route path="/Yobu-40" element={<Job40 />} />
<Route path="/Yobu-41" element={<Job41 />} />
<Route path="/Yobu-42" element={<Job42 />} />

<Route path="/tamilbible-யோபு"    element={<Job />} />
<Route path="/tamilbible-யோபு-1"  element={<Job1 />} />
<Route path="/tamilbible-யோபு-2"  element={<Job2 />} />
<Route path="/tamilbible-யோபு-3"  element={<Job3 />} />
<Route path="/tamilbible-யோபு-4"  element={<Job4 />} />
<Route path="/tamilbible-யோபு-5"  element={<Job5 />} />
<Route path="/tamilbible-யோபு-6"  element={<Job6 />} />
<Route path="/tamilbible-யோபு-7"  element={<Job7 />} />
<Route path="/tamilbible-யோபு-8"  element={<Job8 />} />
<Route path="/tamilbible-யோபு-9"  element={<Job9 />} />
<Route path="/tamilbible-யோபு-10" element={<Job10 />} />
<Route path="/tamilbible-யோபு-11" element={<Job11 />} />
<Route path="/tamilbible-யோபு-12" element={<Job12 />} />
<Route path="/tamilbible-யோபு-13" element={<Job13 />} />
<Route path="/tamilbible-யோபு-14" element={<Job14 />} />
<Route path="/tamilbible-யோபு-15" element={<Job15 />} />
<Route path="/tamilbible-யோபு-16" element={<Job16 />} />
<Route path="/tamilbible-யோபு-17" element={<Job17 />} />
<Route path="/tamilbible-யோபு-18" element={<Job18 />} />
<Route path="/tamilbible-யோபு-19" element={<Job19 />} />
<Route path="/tamilbible-யோபு-20" element={<Job20 />} />
<Route path="/tamilbible-யோபு-21" element={<Job21 />} />
<Route path="/tamilbible-யோபு-22" element={<Job22 />} />
<Route path="/tamilbible-யோபு-23" element={<Job23 />} />
<Route path="/tamilbible-யோபு-24" element={<Job24 />} />
<Route path="/tamilbible-யோபு-25" element={<Job25 />} />
<Route path="/tamilbible-யோபு-26" element={<Job26 />} />
<Route path="/tamilbible-யோபு-27" element={<Job27 />} />
<Route path="/tamilbible-யோபு-28" element={<Job28 />} />
<Route path="/tamilbible-யோபு-29" element={<Job29 />} />
<Route path="/tamilbible-யோபு-30" element={<Job30 />} />
<Route path="/tamilbible-யோபு-31" element={<Job31 />} />
<Route path="/tamilbible-யோபு-32" element={<Job32 />} />
<Route path="/tamilbible-யோபு-33" element={<Job33 />} />
<Route path="/tamilbible-யோபு-34" element={<Job34 />} />
<Route path="/tamilbible-யோபு-35" element={<Job35 />} />
<Route path="/tamilbible-யோபு-36" element={<Job36 />} />
<Route path="/tamilbible-யோபு-37" element={<Job37 />} />
<Route path="/tamilbible-யோபு-38" element={<Job38 />} />
<Route path="/tamilbible-யோபு-39" element={<Job39 />} />
<Route path="/tamilbible-யோபு-40" element={<Job40 />} />
<Route path="/tamilbible-யோபு-41" element={<Job41 />} />
<Route path="/tamilbible-யோபு-42" element={<Job42 />} />

<Route path="/tamilbible-எஸ்றா"    element={<Ezra />} />
<Route path="/tamilbible-எஸ்றா-1"  element={<Ezra1 />} />
<Route path="/tamilbible-எஸ்றா-2"  element={<Ezra2 />} />
<Route path="/tamilbible-எஸ்றா-3"  element={<Ezra3 />} />
<Route path="/tamilbible-எஸ்றா-4"  element={<Ezra4 />} />
<Route path="/tamilbible-எஸ்றா-5"  element={<Ezra5 />} />
<Route path="/tamilbible-எஸ்றா-6"  element={<Ezra6 />} />
<Route path="/tamilbible-எஸ்றா-7"  element={<Ezra7 />} />
<Route path="/tamilbible-எஸ்றா-8"  element={<Ezra8 />} />
<Route path="/tamilbible-எஸ்றா-9"  element={<Ezra9 />} />
<Route path="/tamilbible-எஸ்றா-10" element={<Ezra10 />} />

<Route path="/Sangeetham"    element={<Sangeetham />} />
<Route path="/Sangeetham-1"  element={<Sangeetham1 />} />
<Route path="/Sangeetham-2"  element={<Sangeetham2 />} />
<Route path="/Sangeetham-3"  element={<Sangeetham3 />} />
<Route path="/Sangeetham-4"  element={<Sangeetham4 />} />
<Route path="/Sangeetham-5"  element={<Sangeetham5 />} />
<Route path="/Sangeetham-6"  element={<Sangeetham6 />} />
<Route path="/Sangeetham-7"  element={<Sangeetham7 />} />
<Route path="/Sangeetham-8"  element={<Sangeetham8 />} />
<Route path="/Sangeetham-9"  element={<Sangeetham9 />} />
<Route path="/Sangeetham-10" element={<Sangeetham10 />} />
<Route path="/Sangeetham-11" element={<Sangeetham11 />} />
<Route path="/Sangeetham-12" element={<Sangeetham12 />} />
<Route path="/Sangeetham-13" element={<Sangeetham13 />} />
<Route path="/Sangeetham-14" element={<Sangeetham14 />} />
<Route path="/Sangeetham-15" element={<Sangeetham15 />} />
<Route path="/Sangeetham-16" element={<Sangeetham16 />} />
<Route path="/Sangeetham-17" element={<Sangeetham17 />} />
<Route path="/Sangeetham-18" element={<Sangeetham18 />} />
<Route path="/Sangeetham-19" element={<Sangeetham19 />} />
<Route path="/Sangeetham-20" element={<Sangeetham20 />} />
<Route path="/Sangeetham-21" element={<Sangeetham21 />} />
<Route path="/Sangeetham-22" element={<Sangeetham22 />} />
<Route path="/Sangeetham-23" element={<Sangeetham23 />} />
<Route path="/Sangeetham-24" element={<Sangeetham24 />} />
<Route path="/Sangeetham-25" element={<Sangeetham25 />} />
<Route path="/Sangeetham-26" element={<Sangeetham26 />} />
<Route path="/Sangeetham-27" element={<Sangeetham27 />} />
<Route path="/Sangeetham-28" element={<Sangeetham28 />} />
<Route path="/Sangeetham-29" element={<Sangeetham29 />} />
<Route path="/Sangeetham-30" element={<Sangeetham30 />} />
<Route path="/Sangeetham-31" element={<Sangeetham31 />} />
<Route path="/Sangeetham-32" element={<Sangeetham32 />} />
<Route path="/Sangeetham-33" element={<Sangeetham33 />} />
<Route path="/Sangeetham-34" element={<Sangeetham34 />} />
<Route path="/Sangeetham-35" element={<Sangeetham35 />} />
<Route path="/Sangeetham-36" element={<Sangeetham36 />} />
<Route path="/Sangeetham-37" element={<Sangeetham37 />} />
<Route path="/Sangeetham-38" element={<Sangeetham38 />} />
<Route path="/Sangeetham-39" element={<Sangeetham39 />} />
<Route path="/Sangeetham-40" element={<Sangeetham40 />} />
<Route path="/Sangeetham-41" element={<Sangeetham41 />} />
<Route path="/Sangeetham-42" element={<Sangeetham42 />} />
<Route path="/Sangeetham-43" element={<Sangeetham43 />} />
<Route path="/Sangeetham-44" element={<Sangeetham44 />} />
<Route path="/Sangeetham-45" element={<Sangeetham45 />} />
<Route path="/Sangeetham-46" element={<Sangeetham46 />} />
<Route path="/Sangeetham-47" element={<Sangeetham47 />} />
<Route path="/Sangeetham-48" element={<Sangeetham48 />} />
<Route path="/Sangeetham-49" element={<Sangeetham49 />} />
<Route path="/Sangeetham-50" element={<Sangeetham50 />} />
<Route path="/Sangeetham-51" element={<Sangeetham51 />} />
<Route path="/Sangeetham-52" element={<Sangeetham52 />} />
<Route path="/Sangeetham-53" element={<Sangeetham53 />} />
<Route path="/Sangeetham-54" element={<Sangeetham54 />} />
<Route path="/Sangeetham-55" element={<Sangeetham55 />} />
<Route path="/Sangeetham-56" element={<Sangeetham56 />} />
<Route path="/Sangeetham-57" element={<Sangeetham57 />} />
<Route path="/Sangeetham-58" element={<Sangeetham58 />} />
<Route path="/Sangeetham-59" element={<Sangeetham59 />} />
<Route path="/Sangeetham-60" element={<Sangeetham60 />} />
<Route path="/Sangeetham-61" element={<Sangeetham61 />} />
<Route path="/Sangeetham-62" element={<Sangeetham62 />} />
<Route path="/Sangeetham-63" element={<Sangeetham63 />} />
<Route path="/Sangeetham-64" element={<Sangeetham64 />} />
<Route path="/Sangeetham-65" element={<Sangeetham65 />} />
<Route path="/Sangeetham-66" element={<Sangeetham66 />} />
<Route path="/Sangeetham-67" element={<Sangeetham67 />} />
<Route path="/Sangeetham-68" element={<Sangeetham68 />} />
<Route path="/Sangeetham-69" element={<Sangeetham69 />} />
<Route path="/Sangeetham-70" element={<Sangeetham70 />} />
<Route path="/Sangeetham-71" element={<Sangeetham71 />} />
<Route path="/Sangeetham-72" element={<Sangeetham72 />} />
<Route path="/Sangeetham-73" element={<Sangeetham73 />} />
<Route path="/Sangeetham-74" element={<Sangeetham74 />} />
<Route path="/Sangeetham-75" element={<Sangeetham75 />} />
<Route path="/Sangeetham-76" element={<Sangeetham76 />} />
<Route path="/Sangeetham-77" element={<Sangeetham77 />} />
<Route path="/Sangeetham-78" element={<Sangeetham78 />} />
<Route path="/Sangeetham-79" element={<Sangeetham79 />} />
<Route path="/Sangeetham-80" element={<Sangeetham80 />} />
<Route path="/Sangeetham-81" element={<Sangeetham81 />} />
<Route path="/Sangeetham-82" element={<Sangeetham82 />} />
<Route path="/Sangeetham-83" element={<Sangeetham83 />} />
<Route path="/Sangeetham-84" element={<Sangeetham84 />} />
<Route path="/Sangeetham-85" element={<Sangeetham85 />} />
<Route path="/Sangeetham-86" element={<Sangeetham86 />} />
<Route path="/Sangeetham-87" element={<Sangeetham87 />} />
<Route path="/Sangeetham-88" element={<Sangeetham88 />} />
<Route path="/Sangeetham-89" element={<Sangeetham89 />} />
<Route path="/Sangeetham-90" element={<Sangeetham90 />} />
<Route path="/Sangeetham-91" element={<Sangeetham91 />} />
<Route path="/Sangeetham-92" element={<Sangeetham92 />} />
<Route path="/Sangeetham-93" element={<Sangeetham93 />} />
<Route path="/Sangeetham-94" element={<Sangeetham94 />} />
<Route path="/Sangeetham-95" element={<Sangeetham95 />} />
<Route path="/Sangeetham-96" element={<Sangeetham96 />} />
<Route path="/Sangeetham-97" element={<Sangeetham97 />} />
<Route path="/Sangeetham-98" element={<Sangeetham98 />} />
<Route path="/Sangeetham-99" element={<Sangeetham99 />} />
<Route path="/Sangeetham-100" element={<Sangeetham100 />} />
<Route path="/Sangeetham-101" element={<Sangeetham101 />} />
<Route path="/Sangeetham-102" element={<Sangeetham102 />} />
<Route path="/Sangeetham-103" element={<Sangeetham103 />} />
<Route path="/Sangeetham-104" element={<Sangeetham104 />} />
<Route path="/Sangeetham-105" element={<Sangeetham105 />} />
<Route path="/Sangeetham-106" element={<Sangeetham106 />} />
<Route path="/Sangeetham-107" element={<Sangeetham107 />} />
<Route path="/Sangeetham-108" element={<Sangeetham108 />} />
<Route path="/Sangeetham-109" element={<Sangeetham109 />} />
<Route path="/Sangeetham-100" element={<Sangeetham100 />} />
<Route path="/Sangeetham-101" element={<Sangeetham101 />} />
<Route path="/Sangeetham-102" element={<Sangeetham102 />} />
<Route path="/Sangeetham-103" element={<Sangeetham103 />} />
<Route path="/Sangeetham-104" element={<Sangeetham104 />} />
<Route path="/Sangeetham-105" element={<Sangeetham105 />} />
<Route path="/Sangeetham-106" element={<Sangeetham106 />} />
<Route path="/Sangeetham-107" element={<Sangeetham107 />} />
<Route path="/Sangeetham-108" element={<Sangeetham108 />} />
<Route path="/Sangeetham-109" element={<Sangeetham109 />} />
<Route path="/Sangeetham-110" element={<Sangeetham110 />} />
<Route path="/Sangeetham-111" element={<Sangeetham111 />} />
<Route path="/Sangeetham-112" element={<Sangeetham112 />} />
<Route path="/Sangeetham-113" element={<Sangeetham113 />} />
<Route path="/Sangeetham-114" element={<Sangeetham114 />} />
<Route path="/Sangeetham-115" element={<Sangeetham115 />} />
<Route path="/Sangeetham-116" element={<Sangeetham116 />} />
<Route path="/Sangeetham-117" element={<Sangeetham117 />} />
<Route path="/Sangeetham-118" element={<Sangeetham118 />} />
<Route path="/Sangeetham-119" element={<Sangeetham119 />} />
<Route path="/Sangeetham-120" element={<Sangeetham120 />} />
<Route path="/Sangeetham-121" element={<Sangeetham121 />} />
<Route path="/Sangeetham-122" element={<Sangeetham122 />} />
<Route path="/Sangeetham-123" element={<Sangeetham123 />} />
<Route path="/Sangeetham-124" element={<Sangeetham124 />} />
<Route path="/Sangeetham-125" element={<Sangeetham125 />} />
<Route path="/Sangeetham-126" element={<Sangeetham126 />} />
<Route path="/Sangeetham-127" element={<Sangeetham127 />} />
<Route path="/Sangeetham-128" element={<Sangeetham128 />} />
<Route path="/Sangeetham-129" element={<Sangeetham129 />} />
<Route path="/Sangeetham-130" element={<Sangeetham130 />} />
<Route path="/Sangeetham-131" element={<Sangeetham131 />} />
<Route path="/Sangeetham-132" element={<Sangeetham132 />} />
<Route path="/Sangeetham-133" element={<Sangeetham133 />} />
<Route path="/Sangeetham-134" element={<Sangeetham134 />} />
<Route path="/Sangeetham-135" element={<Sangeetham135 />} />
<Route path="/Sangeetham-136" element={<Sangeetham136 />} />
<Route path="/Sangeetham-137" element={<Sangeetham137 />} />
<Route path="/Sangeetham-138" element={<Sangeetham138 />} />
<Route path="/Sangeetham-139" element={<Sangeetham139 />} />
<Route path="/Sangeetham-140" element={<Sangeetham140 />} />
<Route path="/Sangeetham-141" element={<Sangeetham141 />} />
<Route path="/Sangeetham-142" element={<Sangeetham142 />} />
<Route path="/Sangeetham-143" element={<Sangeetham143 />} />
<Route path="/Sangeetham-144" element={<Sangeetham144 />} />
<Route path="/Sangeetham-145" element={<Sangeetham145 />} />
<Route path="/Sangeetham-146" element={<Sangeetham146 />} />
<Route path="/Sangeetham-147" element={<Sangeetham147 />} />
<Route path="/Sangeetham-148" element={<Sangeetham148 />} />
<Route path="/Sangeetham-149" element={<Sangeetham149 />} />
<Route path="/Sangeetham-150" element={<Sangeetham150 />} />

<Route path="/tamilbible-சங்கீதம்"    element={<Sangeetham />} />
<Route path="/tamilbible-சங்கீதம்-1"  element={<Sangeetham1 />} />
<Route path="/tamilbible-சங்கீதம்-2"  element={<Sangeetham2 />} />
<Route path="/tamilbible-சங்கீதம்-3"  element={<Sangeetham3 />} />
<Route path="/tamilbible-சங்கீதம்-4"  element={<Sangeetham4 />} />
<Route path="/tamilbible-சங்கீதம்-5"  element={<Sangeetham5 />} />
<Route path="/tamilbible-சங்கீதம்-6"  element={<Sangeetham6 />} />
<Route path="/tamilbible-சங்கீதம்-7"  element={<Sangeetham7 />} />
<Route path="/tamilbible-சங்கீதம்-8"  element={<Sangeetham8 />} />
<Route path="/tamilbible-சங்கீதம்-9"  element={<Sangeetham9 />} />
<Route path="/tamilbible-சங்கீதம்-10" element={<Sangeetham10 />} />
<Route path="/tamilbible-சங்கீதம்-11" element={<Sangeetham11 />} />
<Route path="/tamilbible-சங்கீதம்-12" element={<Sangeetham12 />} />
<Route path="/tamilbible-சங்கீதம்-13" element={<Sangeetham13 />} />
<Route path="/tamilbible-சங்கீதம்-14" element={<Sangeetham14 />} />
<Route path="/tamilbible-சங்கீதம்-15" element={<Sangeetham15 />} />
<Route path="/tamilbible-சங்கீதம்-16" element={<Sangeetham16 />} />
<Route path="/tamilbible-சங்கீதம்-17" element={<Sangeetham17 />} />
<Route path="/tamilbible-சங்கீதம்-18" element={<Sangeetham18 />} />
<Route path="/tamilbible-சங்கீதம்-19" element={<Sangeetham19 />} />
<Route path="/tamilbible-சங்கீதம்-20" element={<Sangeetham20 />} />
<Route path="/tamilbible-சங்கீதம்-21" element={<Sangeetham21 />} />
<Route path="/tamilbible-சங்கீதம்-22" element={<Sangeetham22 />} />
<Route path="/tamilbible-சங்கீதம்-23" element={<Sangeetham23 />} />
<Route path="/tamilbible-சங்கீதம்-24" element={<Sangeetham24 />} />
<Route path="/tamilbible-சங்கீதம்-25" element={<Sangeetham25 />} />
<Route path="/tamilbible-சங்கீதம்-26" element={<Sangeetham26 />} />
<Route path="/tamilbible-சங்கீதம்-27" element={<Sangeetham27 />} />
<Route path="/tamilbible-சங்கீதம்-28" element={<Sangeetham28 />} />
<Route path="/tamilbible-சங்கீதம்-29" element={<Sangeetham29 />} />
<Route path="/tamilbible-சங்கீதம்-30" element={<Sangeetham30 />} />
<Route path="/tamilbible-சங்கீதம்-31" element={<Sangeetham31 />} />
<Route path="/tamilbible-சங்கீதம்-32" element={<Sangeetham32 />} />
<Route path="/tamilbible-சங்கீதம்-33" element={<Sangeetham33 />} />
<Route path="/tamilbible-சங்கீதம்-34" element={<Sangeetham34 />} />
<Route path="/tamilbible-சங்கீதம்-35" element={<Sangeetham35 />} />
<Route path="/tamilbible-சங்கீதம்-36" element={<Sangeetham36 />} />
<Route path="/tamilbible-சங்கீதம்-37" element={<Sangeetham37 />} />
<Route path="/tamilbible-சங்கீதம்-38" element={<Sangeetham38 />} />
<Route path="/tamilbible-சங்கீதம்-39" element={<Sangeetham39 />} />
<Route path="/tamilbible-சங்கீதம்-40" element={<Sangeetham40 />} />
<Route path="/tamilbible-சங்கீதம்-41" element={<Sangeetham41 />} />
<Route path="/tamilbible-சங்கீதம்-42" element={<Sangeetham42 />} />
<Route path="/tamilbible-சங்கீதம்-43" element={<Sangeetham43 />} />
<Route path="/tamilbible-சங்கீதம்-44" element={<Sangeetham44 />} />
<Route path="/tamilbible-சங்கீதம்-45" element={<Sangeetham45 />} />
<Route path="/tamilbible-சங்கீதம்-46" element={<Sangeetham46 />} />
<Route path="/tamilbible-சங்கீதம்-47" element={<Sangeetham47 />} />
<Route path="/tamilbible-சங்கீதம்-48" element={<Sangeetham48 />} />
<Route path="/tamilbible-சங்கீதம்-49" element={<Sangeetham49 />} />
<Route path="/tamilbible-சங்கீதம்-50" element={<Sangeetham50 />} />
<Route path="/tamilbible-சங்கீதம்-51" element={<Sangeetham51 />} />
<Route path="/tamilbible-சங்கீதம்-52" element={<Sangeetham52 />} />
<Route path="/tamilbible-சங்கீதம்-53" element={<Sangeetham53 />} />
<Route path="/tamilbible-சங்கீதம்-54" element={<Sangeetham54 />} />
<Route path="/tamilbible-சங்கீதம்-55" element={<Sangeetham55 />} />
<Route path="/tamilbible-சங்கீதம்-56" element={<Sangeetham56 />} />
<Route path="/tamilbible-சங்கீதம்-57" element={<Sangeetham57 />} />
<Route path="/tamilbible-சங்கீதம்-58" element={<Sangeetham58 />} />
<Route path="/tamilbible-சங்கீதம்-59" element={<Sangeetham59 />} />
<Route path="/tamilbible-சங்கீதம்-60" element={<Sangeetham60 />} />
<Route path="/tamilbible-சங்கீதம்-61" element={<Sangeetham61 />} />
<Route path="/tamilbible-சங்கீதம்-62" element={<Sangeetham62 />} />
<Route path="/tamilbible-சங்கீதம்-63" element={<Sangeetham63 />} />
<Route path="/tamilbible-சங்கீதம்-64" element={<Sangeetham64 />} />
<Route path="/tamilbible-சங்கீதம்-65" element={<Sangeetham65 />} />
<Route path="/tamilbible-சங்கீதம்-66" element={<Sangeetham66 />} />
<Route path="/tamilbible-சங்கீதம்-67" element={<Sangeetham67 />} />
<Route path="/tamilbible-சங்கீதம்-68" element={<Sangeetham68 />} />
<Route path="/tamilbible-சங்கீதம்-69" element={<Sangeetham69 />} />
<Route path="/tamilbible-சங்கீதம்-70" element={<Sangeetham70 />} />
<Route path="/tamilbible-சங்கீதம்-71" element={<Sangeetham71 />} />
<Route path="/tamilbible-சங்கீதம்-72" element={<Sangeetham72 />} />
<Route path="/tamilbible-சங்கீதம்-73" element={<Sangeetham73 />} />
<Route path="/tamilbible-சங்கீதம்-74" element={<Sangeetham74 />} />
<Route path="/tamilbible-சங்கீதம்-75" element={<Sangeetham75 />} />
<Route path="/tamilbible-சங்கீதம்-76" element={<Sangeetham76 />} />
<Route path="/tamilbible-சங்கீதம்-77" element={<Sangeetham77 />} />
<Route path="/tamilbible-சங்கீதம்-78" element={<Sangeetham78 />} />
<Route path="/tamilbible-சங்கீதம்-79" element={<Sangeetham79 />} />
<Route path="/tamilbible-சங்கீதம்-80" element={<Sangeetham80 />} />
<Route path="/tamilbible-சங்கீதம்-81" element={<Sangeetham81 />} />
<Route path="/tamilbible-சங்கீதம்-82" element={<Sangeetham82 />} />
<Route path="/tamilbible-சங்கீதம்-83" element={<Sangeetham83 />} />
<Route path="/tamilbible-சங்கீதம்-84" element={<Sangeetham84 />} />
<Route path="/tamilbible-சங்கீதம்-85" element={<Sangeetham85 />} />
<Route path="/tamilbible-சங்கீதம்-86" element={<Sangeetham86 />} />
<Route path="/tamilbible-சங்கீதம்-87" element={<Sangeetham87 />} />
<Route path="/tamilbible-சங்கீதம்-88" element={<Sangeetham88 />} />
<Route path="/tamilbible-சங்கீதம்-89" element={<Sangeetham89 />} />
<Route path="/tamilbible-சங்கீதம்-90" element={<Sangeetham90 />} />
<Route path="/tamilbible-சங்கீதம்-91" element={<Sangeetham91 />} />
<Route path="/tamilbible-சங்கீதம்-92" element={<Sangeetham92 />} />
<Route path="/tamilbible-சங்கீதம்-93" element={<Sangeetham93 />} />
<Route path="/tamilbible-சங்கீதம்-94" element={<Sangeetham94 />} />
<Route path="/tamilbible-சங்கீதம்-95" element={<Sangeetham95 />} />
<Route path="/tamilbible-சங்கீதம்-96" element={<Sangeetham96 />} />
<Route path="/tamilbible-சங்கீதம்-97" element={<Sangeetham97 />} />
<Route path="/tamilbible-சங்கீதம்-98" element={<Sangeetham98 />} />
<Route path="/tamilbible-சங்கீதம்-99" element={<Sangeetham99 />} />
<Route path="/tamilbible-சங்கீதம்-100" element={<Sangeetham100 />} />
<Route path="/tamilbible-சங்கீதம்-101" element={<Sangeetham101 />} />
<Route path="/tamilbible-சங்கீதம்-102" element={<Sangeetham102 />} />
<Route path="/tamilbible-சங்கீதம்-103" element={<Sangeetham103 />} />
<Route path="/tamilbible-சங்கீதம்-104" element={<Sangeetham104 />} />
<Route path="/tamilbible-சங்கீதம்-105" element={<Sangeetham105 />} />
<Route path="/tamilbible-சங்கீதம்-106" element={<Sangeetham106 />} />
<Route path="/tamilbible-சங்கீதம்-107" element={<Sangeetham107 />} />
<Route path="/tamilbible-சங்கீதம்-108" element={<Sangeetham108 />} />
<Route path="/tamilbible-சங்கீதம்-109" element={<Sangeetham109 />} />
<Route path="/tamilbible-சங்கீதம்-100" element={<Sangeetham100 />} />
<Route path="/tamilbible-சங்கீதம்-101" element={<Sangeetham101 />} />
<Route path="/tamilbible-சங்கீதம்-102" element={<Sangeetham102 />} />
<Route path="/tamilbible-சங்கீதம்-103" element={<Sangeetham103 />} />
<Route path="/tamilbible-சங்கீதம்-104" element={<Sangeetham104 />} />
<Route path="/tamilbible-சங்கீதம்-105" element={<Sangeetham105 />} />
<Route path="/tamilbible-சங்கீதம்-106" element={<Sangeetham106 />} />
<Route path="/tamilbible-சங்கீதம்-107" element={<Sangeetham107 />} />
<Route path="/tamilbible-சங்கீதம்-108" element={<Sangeetham108 />} />
<Route path="/tamilbible-சங்கீதம்-109" element={<Sangeetham109 />} />
<Route path="/tamilbible-சங்கீதம்-110" element={<Sangeetham110 />} />
<Route path="/tamilbible-சங்கீதம்-111" element={<Sangeetham111 />} />
<Route path="/tamilbible-சங்கீதம்-112" element={<Sangeetham112 />} />
<Route path="/tamilbible-சங்கீதம்-113" element={<Sangeetham113 />} />
<Route path="/tamilbible-சங்கீதம்-114" element={<Sangeetham114 />} />
<Route path="/tamilbible-சங்கீதம்-115" element={<Sangeetham115 />} />
<Route path="/tamilbible-சங்கீதம்-116" element={<Sangeetham116 />} />
<Route path="/tamilbible-சங்கீதம்-117" element={<Sangeetham117 />} />
<Route path="/tamilbible-சங்கீதம்-118" element={<Sangeetham118 />} />
<Route path="/tamilbible-சங்கீதம்-119" element={<Sangeetham119 />} />
<Route path="/tamilbible-சங்கீதம்-120" element={<Sangeetham120 />} />
<Route path="/tamilbible-சங்கீதம்-121" element={<Sangeetham121 />} />
<Route path="/tamilbible-சங்கீதம்-122" element={<Sangeetham122 />} />
<Route path="/tamilbible-சங்கீதம்-123" element={<Sangeetham123 />} />
<Route path="/tamilbible-சங்கீதம்-124" element={<Sangeetham124 />} />
<Route path="/tamilbible-சங்கீதம்-125" element={<Sangeetham125 />} />
<Route path="/tamilbible-சங்கீதம்-126" element={<Sangeetham126 />} />
<Route path="/tamilbible-சங்கீதம்-127" element={<Sangeetham127 />} />
<Route path="/tamilbible-சங்கீதம்-128" element={<Sangeetham128 />} />
<Route path="/tamilbible-சங்கீதம்-129" element={<Sangeetham129 />} />
<Route path="/tamilbible-சங்கீதம்-130" element={<Sangeetham130 />} />
<Route path="/tamilbible-சங்கீதம்-131" element={<Sangeetham131 />} />
<Route path="/tamilbible-சங்கீதம்-132" element={<Sangeetham132 />} />
<Route path="/tamilbible-சங்கீதம்-133" element={<Sangeetham133 />} />
<Route path="/tamilbible-சங்கீதம்-134" element={<Sangeetham134 />} />
<Route path="/tamilbible-சங்கீதம்-135" element={<Sangeetham135 />} />
<Route path="/tamilbible-சங்கீதம்-136" element={<Sangeetham136 />} />
<Route path="/tamilbible-சங்கீதம்-137" element={<Sangeetham137 />} />
<Route path="/tamilbible-சங்கீதம்-138" element={<Sangeetham138 />} />
<Route path="/tamilbible-சங்கீதம்-139" element={<Sangeetham139 />} />
<Route path="/tamilbible-சங்கீதம்-140" element={<Sangeetham140 />} />
<Route path="/tamilbible-சங்கீதம்-141" element={<Sangeetham141 />} />
<Route path="/tamilbible-சங்கீதம்-142" element={<Sangeetham142 />} />
<Route path="/tamilbible-சங்கீதம்-143" element={<Sangeetham143 />} />
<Route path="/tamilbible-சங்கீதம்-144" element={<Sangeetham144 />} />
<Route path="/tamilbible-சங்கீதம்-145" element={<Sangeetham145 />} />
<Route path="/tamilbible-சங்கீதம்-146" element={<Sangeetham146 />} />
<Route path="/tamilbible-சங்கீதம்-147" element={<Sangeetham147 />} />
<Route path="/tamilbible-சங்கீதம்-148" element={<Sangeetham148 />} />
<Route path="/tamilbible-சங்கீதம்-149" element={<Sangeetham149 />} />
<Route path="/tamilbible-சங்கீதம்-150" element={<Sangeetham150 />} />

<Route path="/Psalms"    element={<Sangeetham />} />
<Route path="/Psalms-1"  element={<Sangeetham1 />} />
<Route path="/Psalms-2"  element={<Sangeetham2 />} />
<Route path="/Psalms-3"  element={<Sangeetham3 />} />
<Route path="/Psalms-4"  element={<Sangeetham4 />} />
<Route path="/Psalms-5"  element={<Sangeetham5 />} />
<Route path="/Psalms-6"  element={<Sangeetham6 />} />
<Route path="/Psalms-7"  element={<Sangeetham7 />} />
<Route path="/Psalms-8"  element={<Sangeetham8 />} />
<Route path="/Psalms-9"  element={<Sangeetham9 />} />
<Route path="/Psalms-10" element={<Sangeetham10 />} />
<Route path="/Psalms-11" element={<Sangeetham11 />} />
<Route path="/Psalms-12" element={<Sangeetham12 />} />
<Route path="/Psalms-13" element={<Sangeetham13 />} />
<Route path="/Psalms-14" element={<Sangeetham14 />} />
<Route path="/Psalms-15" element={<Sangeetham15 />} />
<Route path="/Psalms-16" element={<Sangeetham16 />} />
<Route path="/Psalms-17" element={<Sangeetham17 />} />
<Route path="/Psalms-18" element={<Sangeetham18 />} />
<Route path="/Psalms-19" element={<Sangeetham19 />} />
<Route path="/Psalms-20" element={<Sangeetham20 />} />
<Route path="/Psalms-21" element={<Sangeetham21 />} />
<Route path="/Psalms-22" element={<Sangeetham22 />} />
<Route path="/Psalms-23" element={<Sangeetham23 />} />
<Route path="/Psalms-24" element={<Sangeetham24 />} />
<Route path="/Psalms-25" element={<Sangeetham25 />} />
<Route path="/Psalms-26" element={<Sangeetham26 />} />
<Route path="/Psalms-27" element={<Sangeetham27 />} />
<Route path="/Psalms-28" element={<Sangeetham28 />} />
<Route path="/Psalms-29" element={<Sangeetham29 />} />
<Route path="/Psalms-30" element={<Sangeetham30 />} />
<Route path="/Psalms-31" element={<Sangeetham31 />} />
<Route path="/Psalms-32" element={<Sangeetham32 />} />
<Route path="/Psalms-33" element={<Sangeetham33 />} />
<Route path="/Psalms-34" element={<Sangeetham34 />} />
<Route path="/Psalms-35" element={<Sangeetham35 />} />
<Route path="/Psalms-36" element={<Sangeetham36 />} />
<Route path="/Psalms-37" element={<Sangeetham37 />} />
<Route path="/Psalms-38" element={<Sangeetham38 />} />
<Route path="/Psalms-39" element={<Sangeetham39 />} />
<Route path="/Psalms-40" element={<Sangeetham40 />} />
<Route path="/Psalms-41" element={<Sangeetham41 />} />
<Route path="/Psalms-42" element={<Sangeetham42 />} />
<Route path="/Psalms-43" element={<Sangeetham43 />} />
<Route path="/Psalms-44" element={<Sangeetham44 />} />
<Route path="/Psalms-45" element={<Sangeetham45 />} />
<Route path="/Psalms-46" element={<Sangeetham46 />} />
<Route path="/Psalms-47" element={<Sangeetham47 />} />
<Route path="/Psalms-48" element={<Sangeetham48 />} />
<Route path="/Psalms-49" element={<Sangeetham49 />} />
<Route path="/Psalms-50" element={<Sangeetham50 />} />
<Route path="/Psalms-51" element={<Sangeetham51 />} />
<Route path="/Psalms-52" element={<Sangeetham52 />} />
<Route path="/Psalms-53" element={<Sangeetham53 />} />
<Route path="/Psalms-54" element={<Sangeetham54 />} />
<Route path="/Psalms-55" element={<Sangeetham55 />} />
<Route path="/Psalms-56" element={<Sangeetham56 />} />
<Route path="/Psalms-57" element={<Sangeetham57 />} />
<Route path="/Psalms-58" element={<Sangeetham58 />} />
<Route path="/Psalms-59" element={<Sangeetham59 />} />
<Route path="/Psalms-60" element={<Sangeetham60 />} />
<Route path="/Psalms-61" element={<Sangeetham61 />} />
<Route path="/Psalms-62" element={<Sangeetham62 />} />
<Route path="/Psalms-63" element={<Sangeetham63 />} />
<Route path="/Psalms-64" element={<Sangeetham64 />} />
<Route path="/Psalms-65" element={<Sangeetham65 />} />
<Route path="/Psalms-66" element={<Sangeetham66 />} />
<Route path="/Psalms-67" element={<Sangeetham67 />} />
<Route path="/Psalms-68" element={<Sangeetham68 />} />
<Route path="/Psalms-69" element={<Sangeetham69 />} />
<Route path="/Psalms-70" element={<Sangeetham70 />} />
<Route path="/Psalms-71" element={<Sangeetham71 />} />
<Route path="/Psalms-72" element={<Sangeetham72 />} />
<Route path="/Psalms-73" element={<Sangeetham73 />} />
<Route path="/Psalms-74" element={<Sangeetham74 />} />
<Route path="/Psalms-75" element={<Sangeetham75 />} />
<Route path="/Psalms-76" element={<Sangeetham76 />} />
<Route path="/Psalms-77" element={<Sangeetham77 />} />
<Route path="/Psalms-78" element={<Sangeetham78 />} />
<Route path="/Psalms-79" element={<Sangeetham79 />} />
<Route path="/Psalms-80" element={<Sangeetham80 />} />
<Route path="/Psalms-81" element={<Sangeetham81 />} />
<Route path="/Psalms-82" element={<Sangeetham82 />} />
<Route path="/Psalms-83" element={<Sangeetham83 />} />
<Route path="/Psalms-84" element={<Sangeetham84 />} />
<Route path="/Psalms-85" element={<Sangeetham85 />} />
<Route path="/Psalms-86" element={<Sangeetham86 />} />
<Route path="/Psalms-87" element={<Sangeetham87 />} />
<Route path="/Psalms-88" element={<Sangeetham88 />} />
<Route path="/Psalms-89" element={<Sangeetham89 />} />
<Route path="/Psalms-90" element={<Sangeetham90 />} />
<Route path="/Psalms-91" element={<Sangeetham91 />} />
<Route path="/Psalms-92" element={<Sangeetham92 />} />
<Route path="/Psalms-93" element={<Sangeetham93 />} />
<Route path="/Psalms-94" element={<Sangeetham94 />} />
<Route path="/Psalms-95" element={<Sangeetham95 />} />
<Route path="/Psalms-96" element={<Sangeetham96 />} />
<Route path="/Psalms-97" element={<Sangeetham97 />} />
<Route path="/Psalms-98" element={<Sangeetham98 />} />
<Route path="/Psalms-99" element={<Sangeetham99 />} />
<Route path="/Psalms-100" element={<Sangeetham100 />} />
<Route path="/Psalms-101" element={<Sangeetham101 />} />
<Route path="/Psalms-102" element={<Sangeetham102 />} />
<Route path="/Psalms-103" element={<Sangeetham103 />} />
<Route path="/Psalms-104" element={<Sangeetham104 />} />
<Route path="/Psalms-105" element={<Sangeetham105 />} />
<Route path="/Psalms-106" element={<Sangeetham106 />} />
<Route path="/Psalms-107" element={<Sangeetham107 />} />
<Route path="/Psalms-108" element={<Sangeetham108 />} />
<Route path="/Psalms-109" element={<Sangeetham109 />} />
<Route path="/Psalms-100" element={<Sangeetham100 />} />
<Route path="/Psalms-101" element={<Sangeetham101 />} />
<Route path="/Psalms-102" element={<Sangeetham102 />} />
<Route path="/Psalms-103" element={<Sangeetham103 />} />
<Route path="/Psalms-104" element={<Sangeetham104 />} />
<Route path="/Psalms-105" element={<Sangeetham105 />} />
<Route path="/Psalms-106" element={<Sangeetham106 />} />
<Route path="/Psalms-107" element={<Sangeetham107 />} />
<Route path="/Psalms-108" element={<Sangeetham108 />} />
<Route path="/Psalms-109" element={<Sangeetham109 />} />
<Route path="/Psalms-110" element={<Sangeetham110 />} />
<Route path="/Psalms-111" element={<Sangeetham111 />} />
<Route path="/Psalms-112" element={<Sangeetham112 />} />
<Route path="/Psalms-113" element={<Sangeetham113 />} />
<Route path="/Psalms-114" element={<Sangeetham114 />} />
<Route path="/Psalms-115" element={<Sangeetham115 />} />
<Route path="/Psalms-116" element={<Sangeetham116 />} />
<Route path="/Psalms-117" element={<Sangeetham117 />} />
<Route path="/Psalms-118" element={<Sangeetham118 />} />
<Route path="/Psalms-119" element={<Sangeetham119 />} />
<Route path="/Psalms-120" element={<Sangeetham120 />} />
<Route path="/Psalms-121" element={<Sangeetham121 />} />
<Route path="/Psalms-122" element={<Sangeetham122 />} />
<Route path="/Psalms-123" element={<Sangeetham123 />} />
<Route path="/Psalms-124" element={<Sangeetham124 />} />
<Route path="/Psalms-125" element={<Sangeetham125 />} />
<Route path="/Psalms-126" element={<Sangeetham126 />} />
<Route path="/Psalms-127" element={<Sangeetham127 />} />
<Route path="/Psalms-128" element={<Sangeetham128 />} />
<Route path="/Psalms-129" element={<Sangeetham129 />} />
<Route path="/Psalms-130" element={<Sangeetham130 />} />
<Route path="/Psalms-131" element={<Sangeetham131 />} />
<Route path="/Psalms-132" element={<Sangeetham132 />} />
<Route path="/Psalms-133" element={<Sangeetham133 />} />
<Route path="/Psalms-134" element={<Sangeetham134 />} />
<Route path="/Psalms-135" element={<Sangeetham135 />} />
<Route path="/Psalms-136" element={<Sangeetham136 />} />
<Route path="/Psalms-137" element={<Sangeetham137 />} />
<Route path="/Psalms-138" element={<Sangeetham138 />} />
<Route path="/Psalms-139" element={<Sangeetham139 />} />
<Route path="/Psalms-140" element={<Sangeetham140 />} />
<Route path="/Psalms-141" element={<Sangeetham141 />} />
<Route path="/Psalms-142" element={<Sangeetham142 />} />
<Route path="/Psalms-143" element={<Sangeetham143 />} />
<Route path="/Psalms-144" element={<Sangeetham144 />} />
<Route path="/Psalms-145" element={<Sangeetham145 />} />
<Route path="/Psalms-146" element={<Sangeetham146 />} />
<Route path="/Psalms-147" element={<Sangeetham147 />} />
<Route path="/Psalms-148" element={<Sangeetham148 />} />
<Route path="/Psalms-149" element={<Sangeetham149 />} />
<Route path="/Psalms-150" element={<Sangeetham150 />} />

<Route path="/Neethimozhigal"    element={<Proverbs />} />
<Route path="/Neethimozhigal-1"  element={<Proverbs1 />} />
<Route path="/Neethimozhigal-2"  element={<Proverbs2 />} />
<Route path="/Neethimozhigal-3"  element={<Proverbs3 />} />
<Route path="/Neethimozhigal-4"  element={<Proverbs4 />} />
<Route path="/Neethimozhigal-5"  element={<Proverbs5 />} />
<Route path="/Neethimozhigal-6"  element={<Proverbs6 />} />
<Route path="/Neethimozhigal-7"  element={<Proverbs7 />} />
<Route path="/Neethimozhigal-8"  element={<Proverbs8 />} />
<Route path="/Neethimozhigal-9"  element={<Proverbs9 />} />
<Route path="/Neethimozhigal-10" element={<Proverbs10 />} />
<Route path="/Neethimozhigal-11" element={<Proverbs11 />} />
<Route path="/Neethimozhigal-12" element={<Proverbs12 />} />
<Route path="/Neethimozhigal-13" element={<Proverbs13 />} />
<Route path="/Neethimozhigal-14" element={<Proverbs14 />} />
<Route path="/Neethimozhigal-15" element={<Proverbs15 />} />
<Route path="/Neethimozhigal-16" element={<Proverbs16 />} />
<Route path="/Neethimozhigal-17" element={<Proverbs17 />} />
<Route path="/Neethimozhigal-18" element={<Proverbs18 />} />
<Route path="/Neethimozhigal-19" element={<Proverbs19 />} />
<Route path="/Neethimozhigal-20" element={<Proverbs20 />} />
<Route path="/Neethimozhigal-21" element={<Proverbs21 />} />
<Route path="/Neethimozhigal-22" element={<Proverbs22 />} />
<Route path="/Neethimozhigal-23" element={<Proverbs23 />} />
<Route path="/Neethimozhigal-24" element={<Proverbs24 />} />
<Route path="/Neethimozhigal-25" element={<Proverbs25 />} />
<Route path="/Neethimozhigal-26" element={<Proverbs26 />} />
<Route path="/Neethimozhigal-27" element={<Proverbs27 />} />
<Route path="/Neethimozhigal-28" element={<Proverbs28 />} />
<Route path="/Neethimozhigal-29" element={<Proverbs29 />} />
<Route path="/Neethimozhigal-30" element={<Proverbs30 />} />
<Route path="/Neethimozhigal-31" element={<Proverbs31 />} />

<Route path="/Needhimozhigal"    element={<Proverbs />} />
<Route path="/Needhimozhigal-1"  element={<Proverbs1 />} />
<Route path="/Needhimozhigal-2"  element={<Proverbs2 />} />
<Route path="/Needhimozhigal-3"  element={<Proverbs3 />} />
<Route path="/Needhimozhigal-4"  element={<Proverbs4 />} />
<Route path="/Needhimozhigal-5"  element={<Proverbs5 />} />
<Route path="/Needhimozhigal-6"  element={<Proverbs6 />} />
<Route path="/Needhimozhigal-7"  element={<Proverbs7 />} />
<Route path="/Needhimozhigal-8"  element={<Proverbs8 />} />
<Route path="/Needhimozhigal-9"  element={<Proverbs9 />} />
<Route path="/Needhimozhigal-10" element={<Proverbs10 />} />
<Route path="/Needhimozhigal-11" element={<Proverbs11 />} />
<Route path="/Needhimozhigal-12" element={<Proverbs12 />} />
<Route path="/Needhimozhigal-13" element={<Proverbs13 />} />
<Route path="/Needhimozhigal-14" element={<Proverbs14 />} />
<Route path="/Needhimozhigal-15" element={<Proverbs15 />} />
<Route path="/Needhimozhigal-16" element={<Proverbs16 />} />
<Route path="/Needhimozhigal-17" element={<Proverbs17 />} />
<Route path="/Needhimozhigal-18" element={<Proverbs18 />} />
<Route path="/Needhimozhigal-19" element={<Proverbs19 />} />
<Route path="/Needhimozhigal-20" element={<Proverbs20 />} />
<Route path="/Needhimozhigal-21" element={<Proverbs21 />} />
<Route path="/Needhimozhigal-22" element={<Proverbs22 />} />
<Route path="/Needhimozhigal-23" element={<Proverbs23 />} />
<Route path="/Needhimozhigal-24" element={<Proverbs24 />} />
<Route path="/Needhimozhigal-25" element={<Proverbs25 />} />
<Route path="/Needhimozhigal-26" element={<Proverbs26 />} />
<Route path="/Needhimozhigal-27" element={<Proverbs27 />} />
<Route path="/Needhimozhigal-28" element={<Proverbs28 />} />
<Route path="/Needhimozhigal-29" element={<Proverbs29 />} />
<Route path="/Needhimozhigal-30" element={<Proverbs30 />} />
<Route path="/Needhimozhigal-31" element={<Proverbs31 />} />

<Route path="/Ponmozhigal"    element={<Proverbs />} />
<Route path="/Ponmozhigal-1"  element={<Proverbs1 />} />
<Route path="/Ponmozhigal-2"  element={<Proverbs2 />} />
<Route path="/Ponmozhigal-3"  element={<Proverbs3 />} />
<Route path="/Ponmozhigal-4"  element={<Proverbs4 />} />
<Route path="/Ponmozhigal-5"  element={<Proverbs5 />} />
<Route path="/Ponmozhigal-6"  element={<Proverbs6 />} />
<Route path="/Ponmozhigal-7"  element={<Proverbs7 />} />
<Route path="/Ponmozhigal-8"  element={<Proverbs8 />} />
<Route path="/Ponmozhigal-9"  element={<Proverbs9 />} />
<Route path="/Ponmozhigal-10" element={<Proverbs10 />} />
<Route path="/Ponmozhigal-11" element={<Proverbs11 />} />
<Route path="/Ponmozhigal-12" element={<Proverbs12 />} />
<Route path="/Ponmozhigal-13" element={<Proverbs13 />} />
<Route path="/Ponmozhigal-14" element={<Proverbs14 />} />
<Route path="/Ponmozhigal-15" element={<Proverbs15 />} />
<Route path="/Ponmozhigal-16" element={<Proverbs16 />} />
<Route path="/Ponmozhigal-17" element={<Proverbs17 />} />
<Route path="/Ponmozhigal-18" element={<Proverbs18 />} />
<Route path="/Ponmozhigal-19" element={<Proverbs19 />} />
<Route path="/Ponmozhigal-20" element={<Proverbs20 />} />
<Route path="/Ponmozhigal-21" element={<Proverbs21 />} />
<Route path="/Ponmozhigal-22" element={<Proverbs22 />} />
<Route path="/Ponmozhigal-23" element={<Proverbs23 />} />
<Route path="/Ponmozhigal-24" element={<Proverbs24 />} />
<Route path="/Ponmozhigal-25" element={<Proverbs25 />} />
<Route path="/Ponmozhigal-26" element={<Proverbs26 />} />
<Route path="/Ponmozhigal-27" element={<Proverbs27 />} />
<Route path="/Ponmozhigal-28" element={<Proverbs28 />} />
<Route path="/Ponmozhigal-29" element={<Proverbs29 />} />
<Route path="/Ponmozhigal-30" element={<Proverbs30 />} />
<Route path="/Ponmozhigal-31" element={<Proverbs31 />} />

<Route path="/Proverbs"    element={<Proverbs />} />
<Route path="/Proverbs-1"  element={<Proverbs1 />} />
<Route path="/Proverbs-2"  element={<Proverbs2 />} />
<Route path="/Proverbs-3"  element={<Proverbs3 />} />
<Route path="/Proverbs-4"  element={<Proverbs4 />} />
<Route path="/Proverbs-5"  element={<Proverbs5 />} />
<Route path="/Proverbs-6"  element={<Proverbs6 />} />
<Route path="/Proverbs-7"  element={<Proverbs7 />} />
<Route path="/Proverbs-8"  element={<Proverbs8 />} />
<Route path="/Proverbs-9"  element={<Proverbs9 />} />
<Route path="/Proverbs-10" element={<Proverbs10 />} />
<Route path="/Proverbs-11" element={<Proverbs11 />} />
<Route path="/Proverbs-12" element={<Proverbs12 />} />
<Route path="/Proverbs-13" element={<Proverbs13 />} />
<Route path="/Proverbs-14" element={<Proverbs14 />} />
<Route path="/Proverbs-15" element={<Proverbs15 />} />
<Route path="/Proverbs-16" element={<Proverbs16 />} />
<Route path="/Proverbs-17" element={<Proverbs17 />} />
<Route path="/Proverbs-18" element={<Proverbs18 />} />
<Route path="/Proverbs-19" element={<Proverbs19 />} />
<Route path="/Proverbs-20" element={<Proverbs20 />} />
<Route path="/Proverbs-21" element={<Proverbs21 />} />
<Route path="/Proverbs-22" element={<Proverbs22 />} />
<Route path="/Proverbs-23" element={<Proverbs23 />} />
<Route path="/Proverbs-24" element={<Proverbs24 />} />
<Route path="/Proverbs-25" element={<Proverbs25 />} />
<Route path="/Proverbs-26" element={<Proverbs26 />} />
<Route path="/Proverbs-27" element={<Proverbs27 />} />
<Route path="/Proverbs-28" element={<Proverbs28 />} />
<Route path="/Proverbs-29" element={<Proverbs29 />} />
<Route path="/Proverbs-30" element={<Proverbs30 />} />
<Route path="/Proverbs-31" element={<Proverbs31 />} />

<Route path="/tamilbible-நீதிமொழிகள்"    element={<Proverbs />} />
<Route path="/tamilbible-நீதிமொழிகள்-1"  element={<Proverbs1 />} />
<Route path="/tamilbible-நீதிமொழிகள்-2"  element={<Proverbs2 />} />
<Route path="/tamilbible-நீதிமொழிகள்-3"  element={<Proverbs3 />} />
<Route path="/tamilbible-நீதிமொழிகள்-4"  element={<Proverbs4 />} />
<Route path="/tamilbible-நீதிமொழிகள்-5"  element={<Proverbs5 />} />
<Route path="/tamilbible-நீதிமொழிகள்-6"  element={<Proverbs6 />} />
<Route path="/tamilbible-நீதிமொழிகள்-7"  element={<Proverbs7 />} />
<Route path="/tamilbible-நீதிமொழிகள்-8"  element={<Proverbs8 />} />
<Route path="/tamilbible-நீதிமொழிகள்-9"  element={<Proverbs9 />} />
<Route path="/tamilbible-நீதிமொழிகள்-10" element={<Proverbs10 />} />
<Route path="/tamilbible-நீதிமொழிகள்-11" element={<Proverbs11 />} />
<Route path="/tamilbible-நீதிமொழிகள்-12" element={<Proverbs12 />} />
<Route path="/tamilbible-நீதிமொழிகள்-13" element={<Proverbs13 />} />
<Route path="/tamilbible-நீதிமொழிகள்-14" element={<Proverbs14 />} />
<Route path="/tamilbible-நீதிமொழிகள்-15" element={<Proverbs15 />} />
<Route path="/tamilbible-நீதிமொழிகள்-16" element={<Proverbs16 />} />
<Route path="/tamilbible-நீதிமொழிகள்-17" element={<Proverbs17 />} />
<Route path="/tamilbible-நீதிமொழிகள்-18" element={<Proverbs18 />} />
<Route path="/tamilbible-நீதிமொழிகள்-19" element={<Proverbs19 />} />
<Route path="/tamilbible-நீதிமொழிகள்-20" element={<Proverbs20 />} />
<Route path="/tamilbible-நீதிமொழிகள்-21" element={<Proverbs21 />} />
<Route path="/tamilbible-நீதிமொழிகள்-22" element={<Proverbs22 />} />
<Route path="/tamilbible-நீதிமொழிகள்-23" element={<Proverbs23 />} />
<Route path="/tamilbible-நீதிமொழிகள்-24" element={<Proverbs24 />} />
<Route path="/tamilbible-நீதிமொழிகள்-25" element={<Proverbs25 />} />
<Route path="/tamilbible-நீதிமொழிகள்-26" element={<Proverbs26 />} />
<Route path="/tamilbible-நீதிமொழிகள்-27" element={<Proverbs27 />} />
<Route path="/tamilbible-நீதிமொழிகள்-28" element={<Proverbs28 />} />
<Route path="/tamilbible-நீதிமொழிகள்-29" element={<Proverbs29 />} />
<Route path="/tamilbible-நீதிமொழிகள்-30" element={<Proverbs30 />} />
<Route path="/tamilbible-நீதிமொழிகள்-31" element={<Proverbs31 />} />

<Route path="/Prasangi"    element={<Ecclesiastes />} />
<Route path="/Prasangi-1"  element={<Ecclesiastes1 />} />
<Route path="/Prasangi-2"  element={<Ecclesiastes2 />} />
<Route path="/Prasangi-3"  element={<Ecclesiastes3 />} />
<Route path="/Prasangi-4"  element={<Ecclesiastes4 />} />
<Route path="/Prasangi-5"  element={<Ecclesiastes5 />} />
<Route path="/Prasangi-6"  element={<Ecclesiastes6 />} />
<Route path="/Prasangi-7"  element={<Ecclesiastes7 />} />
<Route path="/Prasangi-8"  element={<Ecclesiastes8 />} />
<Route path="/Prasangi-9"  element={<Ecclesiastes9 />} />
<Route path="/Prasangi-10" element={<Ecclesiastes10 />} />
<Route path="/Prasangi-11" element={<Ecclesiastes11 />} />
<Route path="/Prasangi-12" element={<Ecclesiastes12 />} />

<Route path="/Ecclesiates"    element={<Ecclesiastes />} />
<Route path="/Ecclesiates-1"  element={<Ecclesiastes1 />} />
<Route path="/Ecclesiates-2"  element={<Ecclesiastes2 />} />
<Route path="/Ecclesiates-3"  element={<Ecclesiastes3 />} />
<Route path="/Ecclesiates-4"  element={<Ecclesiastes4 />} />
<Route path="/Ecclesiates-5"  element={<Ecclesiastes5 />} />
<Route path="/Ecclesiates-6"  element={<Ecclesiastes6 />} />
<Route path="/Ecclesiates-7"  element={<Ecclesiastes7 />} />
<Route path="/Ecclesiates-8"  element={<Ecclesiastes8 />} />
<Route path="/Ecclesiates-9"  element={<Ecclesiastes9 />} />
<Route path="/Ecclesiates-10" element={<Ecclesiastes10 />} />
<Route path="/Ecclesiates-11" element={<Ecclesiastes11 />} />
<Route path="/Ecclesiates-12" element={<Ecclesiastes12 />} />

<Route path="/tamilbible-பிரசங்கி"    element={<Ecclesiastes />} />
<Route path="/tamilbible-பிரசங்கி-1"  element={<Ecclesiastes1 />} />
<Route path="/tamilbible-பிரசங்கி-2"  element={<Ecclesiastes2 />} />
<Route path="/tamilbible-பிரசங்கி-3"  element={<Ecclesiastes3 />} />
<Route path="/tamilbible-பிரசங்கி-4"  element={<Ecclesiastes4 />} />
<Route path="/tamilbible-பிரசங்கி-5"  element={<Ecclesiastes5 />} />
<Route path="/tamilbible-பிரசங்கி-6"  element={<Ecclesiastes6 />} />
<Route path="/tamilbible-பிரசங்கி-7"  element={<Ecclesiastes7 />} />
<Route path="/tamilbible-பிரசங்கி-8"  element={<Ecclesiastes8 />} />
<Route path="/tamilbible-பிரசங்கி-9"  element={<Ecclesiastes9 />} />
<Route path="/tamilbible-பிரசங்கி-10" element={<Ecclesiastes10 />} />
<Route path="/tamilbible-பிரசங்கி-11" element={<Ecclesiastes11 />} />
<Route path="/tamilbible-பிரசங்கி-12" element={<Ecclesiastes12 />} />

<Route path="/Unnathapattu"    element={<Songs />} />
<Route path="/Unnathapattu-1"  element={<Songs1 />} />
<Route path="/Unnathapattu-2"  element={<Songs2 />} />
<Route path="/Unnathapattu-3"  element={<Songs3 />} />
<Route path="/Unnathapattu-4"  element={<Songs4 />} />
<Route path="/Unnathapattu-5"  element={<Songs5 />} />
<Route path="/Unnathapattu-6"  element={<Songs6 />} />
<Route path="/Unnathapattu-7"  element={<Songs7 />} />
<Route path="/Unnathapattu-8"  element={<Songs8 />} />

<Route path="/tamilbible-உன்னதப்பாட்டு"    element={<Songs />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-1"  element={<Songs1 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-2"  element={<Songs2 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-3"  element={<Songs3 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-4"  element={<Songs4 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-5"  element={<Songs5 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-6"  element={<Songs6 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-7"  element={<Songs7 />} />
<Route path="/tamilbible-உன்னதப்பாட்டு-8"  element={<Songs8 />} />

<Route path="/Songs"    element={<Songs />} />
<Route path="/Songs-1"  element={<Songs1 />} />
<Route path="/Songs-2"  element={<Songs2 />} />
<Route path="/Songs-3"  element={<Songs3 />} />
<Route path="/Songs-4"  element={<Songs4 />} />
<Route path="/Songs-5"  element={<Songs5 />} />
<Route path="/Songs-6"  element={<Songs6 />} />
<Route path="/Songs-7"  element={<Songs7 />} />
<Route path="/Songs-8"  element={<Songs8 />} />

<Route path="/Yesaya"    element={<Isaiah />} />
<Route path="/Yesaya-1"  element={<Isaiah1 />} />
<Route path="/Yesaya-2"  element={<Isaiah2 />} />
<Route path="/Yesaya-3"  element={<Isaiah3 />} />
<Route path="/Yesaya-4"  element={<Isaiah4 />} />
<Route path="/Yesaya-5"  element={<Isaiah5 />} />
<Route path="/Yesaya-6"  element={<Isaiah6 />} />
<Route path="/Yesaya-7"  element={<Isaiah7 />} />
<Route path="/Yesaya-8"  element={<Isaiah8 />} />
<Route path="/Yesaya-9"  element={<Isaiah9 />} />
<Route path="/Yesaya-10" element={<Isaiah10 />} />
<Route path="/Yesaya-11" element={<Isaiah11 />} />
<Route path="/Yesaya-12" element={<Isaiah12 />} />
<Route path="/Yesaya-13" element={<Isaiah13 />} />
<Route path="/Yesaya-14" element={<Isaiah14 />} />
<Route path="/Yesaya-15" element={<Isaiah15 />} />
<Route path="/Yesaya-16" element={<Isaiah16 />} />
<Route path="/Yesaya-17" element={<Isaiah17 />} />
<Route path="/Yesaya-18" element={<Isaiah18 />} />
<Route path="/Yesaya-19" element={<Isaiah19 />} />
<Route path="/Yesaya-20" element={<Isaiah20 />} />
<Route path="/Yesaya-21" element={<Isaiah21 />} />
<Route path="/Yesaya-22" element={<Isaiah22 />} />
<Route path="/Yesaya-23" element={<Isaiah23 />} />
<Route path="/Yesaya-24" element={<Isaiah24 />} />
<Route path="/Yesaya-25" element={<Isaiah25 />} />
<Route path="/Yesaya-26" element={<Isaiah26 />} />
<Route path="/Yesaya-27" element={<Isaiah27 />} />
<Route path="/Yesaya-28" element={<Isaiah28 />} />
<Route path="/Yesaya-29" element={<Isaiah29 />} />
<Route path="/Yesaya-30" element={<Isaiah30 />} />
<Route path="/Yesaya-31" element={<Isaiah31 />} />
<Route path="/Yesaya-32" element={<Isaiah32 />} />
<Route path="/Yesaya-33" element={<Isaiah33 />} />
<Route path="/Yesaya-34" element={<Isaiah34 />} />
<Route path="/Yesaya-35" element={<Isaiah35 />} />
<Route path="/Yesaya-36" element={<Isaiah36 />} />
<Route path="/Yesaya-37" element={<Isaiah37 />} />
<Route path="/Yesaya-38" element={<Isaiah38 />} />
<Route path="/Yesaya-39" element={<Isaiah39 />} />
<Route path="/Yesaya-40" element={<Isaiah40 />} />
<Route path="/Yesaya-41" element={<Isaiah41 />} />
<Route path="/Yesaya-42" element={<Isaiah42 />} />
<Route path="/Yesaya-43" element={<Isaiah43 />} />
<Route path="/Yesaya-44" element={<Isaiah44 />} />
<Route path="/Yesaya-45" element={<Isaiah45 />} />
<Route path="/Yesaya-46" element={<Isaiah46 />} />
<Route path="/Yesaya-47" element={<Isaiah47 />} />
<Route path="/Yesaya-48" element={<Isaiah48 />} />
<Route path="/Yesaya-49" element={<Isaiah49 />} />
<Route path="/Yesaya-50" element={<Isaiah50 />} />
<Route path="/Yesaya-51" element={<Isaiah51 />} />
<Route path="/Yesaya-52" element={<Isaiah52 />} />
<Route path="/Yesaya-53" element={<Isaiah53 />} />
<Route path="/Yesaya-54" element={<Isaiah54 />} />
<Route path="/Yesaya-55" element={<Isaiah55 />} />
<Route path="/Yesaya-56" element={<Isaiah56 />} />
<Route path="/Yesaya-57" element={<Isaiah57 />} />
<Route path="/Yesaya-58" element={<Isaiah58 />} />
<Route path="/Yesaya-59" element={<Isaiah59 />} />
<Route path="/Yesaya-60" element={<Isaiah60 />} />
<Route path="/Yesaya-61" element={<Isaiah61 />} />
<Route path="/Yesaya-62" element={<Isaiah62 />} />
<Route path="/Yesaya-63" element={<Isaiah63 />} />
<Route path="/Yesaya-64" element={<Isaiah64 />} />
<Route path="/Yesaya-65" element={<Isaiah65 />} />
<Route path="/Yesaya-66" element={<Isaiah66 />} />

<Route path="/tamilbible-ஏசாயா"    element={<Isaiah />} />
<Route path="/tamilbible-ஏசாயா-1"  element={<Isaiah1 />} />
<Route path="/tamilbible-ஏசாயா-2"  element={<Isaiah2 />} />
<Route path="/tamilbible-ஏசாயா-3"  element={<Isaiah3 />} />
<Route path="/tamilbible-ஏசாயா-4"  element={<Isaiah4 />} />
<Route path="/tamilbible-ஏசாயா-5"  element={<Isaiah5 />} />
<Route path="/tamilbible-ஏசாயா-6"  element={<Isaiah6 />} />
<Route path="/tamilbible-ஏசாயா-7"  element={<Isaiah7 />} />
<Route path="/tamilbible-ஏசாயா-8"  element={<Isaiah8 />} />
<Route path="/tamilbible-ஏசாயா-9"  element={<Isaiah9 />} />
<Route path="/tamilbible-ஏசாயா-10" element={<Isaiah10 />} />
<Route path="/tamilbible-ஏசாயா-11" element={<Isaiah11 />} />
<Route path="/tamilbible-ஏசாயா-12" element={<Isaiah12 />} />
<Route path="/tamilbible-ஏசாயா-13" element={<Isaiah13 />} />
<Route path="/tamilbible-ஏசாயா-14" element={<Isaiah14 />} />
<Route path="/tamilbible-ஏசாயா-15" element={<Isaiah15 />} />
<Route path="/tamilbible-ஏசாயா-16" element={<Isaiah16 />} />
<Route path="/tamilbible-ஏசாயா-17" element={<Isaiah17 />} />
<Route path="/tamilbible-ஏசாயா-18" element={<Isaiah18 />} />
<Route path="/tamilbible-ஏசாயா-19" element={<Isaiah19 />} />
<Route path="/tamilbible-ஏசாயா-20" element={<Isaiah20 />} />
<Route path="/tamilbible-ஏசாயா-21" element={<Isaiah21 />} />
<Route path="/tamilbible-ஏசாயா-22" element={<Isaiah22 />} />
<Route path="/tamilbible-ஏசாயா-23" element={<Isaiah23 />} />
<Route path="/tamilbible-ஏசாயா-24" element={<Isaiah24 />} />
<Route path="/tamilbible-ஏசாயா-25" element={<Isaiah25 />} />
<Route path="/tamilbible-ஏசாயா-26" element={<Isaiah26 />} />
<Route path="/tamilbible-ஏசாயா-27" element={<Isaiah27 />} />
<Route path="/tamilbible-ஏசாயா-28" element={<Isaiah28 />} />
<Route path="/tamilbible-ஏசாயா-29" element={<Isaiah29 />} />
<Route path="/tamilbible-ஏசாயா-30" element={<Isaiah30 />} />
<Route path="/tamilbible-ஏசாயா-31" element={<Isaiah31 />} />
<Route path="/tamilbible-ஏசாயா-32" element={<Isaiah32 />} />
<Route path="/tamilbible-ஏசாயா-33" element={<Isaiah33 />} />
<Route path="/tamilbible-ஏசாயா-34" element={<Isaiah34 />} />
<Route path="/tamilbible-ஏசாயா-35" element={<Isaiah35 />} />
<Route path="/tamilbible-ஏசாயா-36" element={<Isaiah36 />} />
<Route path="/tamilbible-ஏசாயா-37" element={<Isaiah37 />} />
<Route path="/tamilbible-ஏசாயா-38" element={<Isaiah38 />} />
<Route path="/tamilbible-ஏசாயா-39" element={<Isaiah39 />} />
<Route path="/tamilbible-ஏசாயா-40" element={<Isaiah40 />} />
<Route path="/tamilbible-ஏசாயா-41" element={<Isaiah41 />} />
<Route path="/tamilbible-ஏசாயா-42" element={<Isaiah42 />} />
<Route path="/tamilbible-ஏசாயா-43" element={<Isaiah43 />} />
<Route path="/tamilbible-ஏசாயா-44" element={<Isaiah44 />} />
<Route path="/tamilbible-ஏசாயா-45" element={<Isaiah45 />} />
<Route path="/tamilbible-ஏசாயா-46" element={<Isaiah46 />} />
<Route path="/tamilbible-ஏசாயா-47" element={<Isaiah47 />} />
<Route path="/tamilbible-ஏசாயா-48" element={<Isaiah48 />} />
<Route path="/tamilbible-ஏசாயா-49" element={<Isaiah49 />} />
<Route path="/tamilbible-ஏசாயா-50" element={<Isaiah50 />} />
<Route path="/tamilbible-ஏசாயா-51" element={<Isaiah51 />} />
<Route path="/tamilbible-ஏசாயா-52" element={<Isaiah52 />} />
<Route path="/tamilbible-ஏசாயா-53" element={<Isaiah53 />} />
<Route path="/tamilbible-ஏசாயா-54" element={<Isaiah54 />} />
<Route path="/tamilbible-ஏசாயா-55" element={<Isaiah55 />} />
<Route path="/tamilbible-ஏசாயா-56" element={<Isaiah56 />} />
<Route path="/tamilbible-ஏசாயா-57" element={<Isaiah57 />} />
<Route path="/tamilbible-ஏசாயா-58" element={<Isaiah58 />} />
<Route path="/tamilbible-ஏசாயா-59" element={<Isaiah59 />} />
<Route path="/tamilbible-ஏசாயா-60" element={<Isaiah60 />} />
<Route path="/tamilbible-ஏசாயா-61" element={<Isaiah61 />} />
<Route path="/tamilbible-ஏசாயா-62" element={<Isaiah62 />} />
<Route path="/tamilbible-ஏசாயா-63" element={<Isaiah63 />} />
<Route path="/tamilbible-ஏசாயா-64" element={<Isaiah64 />} />
<Route path="/tamilbible-ஏசாயா-65" element={<Isaiah65 />} />
<Route path="/tamilbible-ஏசாயா-66" element={<Isaiah66 />} />
<Route path="/ஏசாயா"    element={<Isaiah />} />
<Route path="/ஏசாயா-1"  element={<Isaiah1 />} />
<Route path="/ஏசாயா-2"  element={<Isaiah2 />} />
<Route path="/ஏசாயா-3"  element={<Isaiah3 />} />
<Route path="/ஏசாயா-4"  element={<Isaiah4 />} />
<Route path="/ஏசாயா-5"  element={<Isaiah5 />} />
<Route path="/ஏசாயா-6"  element={<Isaiah6 />} />
<Route path="/ஏசாயா-7"  element={<Isaiah7 />} />
<Route path="/ஏசாயா-8"  element={<Isaiah8 />} />
<Route path="/ஏசாயா-9"  element={<Isaiah9 />} />
<Route path="/ஏசாயா-10" element={<Isaiah10 />} />
<Route path="/ஏசாயா-11" element={<Isaiah11 />} />
<Route path="/ஏசாயா-12" element={<Isaiah12 />} />
<Route path="/ஏசாயா-13" element={<Isaiah13 />} />
<Route path="/ஏசாயா-14" element={<Isaiah14 />} />
<Route path="/ஏசாயா-15" element={<Isaiah15 />} />
<Route path="/ஏசாயா-16" element={<Isaiah16 />} />
<Route path="/ஏசாயா-17" element={<Isaiah17 />} />
<Route path="/ஏசாயா-18" element={<Isaiah18 />} />
<Route path="/ஏசாயா-19" element={<Isaiah19 />} />
<Route path="/ஏசாயா-20" element={<Isaiah20 />} />
<Route path="/ஏசாயா-21" element={<Isaiah21 />} />
<Route path="/ஏசாயா-22" element={<Isaiah22 />} />
<Route path="/ஏசாயா-23" element={<Isaiah23 />} />
<Route path="/ஏசாயா-24" element={<Isaiah24 />} />
<Route path="/ஏசாயா-25" element={<Isaiah25 />} />
<Route path="/ஏசாயா-26" element={<Isaiah26 />} />
<Route path="/ஏசாயா-27" element={<Isaiah27 />} />
<Route path="/ஏசாயா-28" element={<Isaiah28 />} />
<Route path="/ஏசாயா-29" element={<Isaiah29 />} />
<Route path="/ஏசாயா-30" element={<Isaiah30 />} />
<Route path="/ஏசாயா-31" element={<Isaiah31 />} />
<Route path="/ஏசாயா-32" element={<Isaiah32 />} />
<Route path="/ஏசாயா-33" element={<Isaiah33 />} />
<Route path="/ஏசாயா-34" element={<Isaiah34 />} />
<Route path="/ஏசாயா-35" element={<Isaiah35 />} />
<Route path="/ஏசாயா-36" element={<Isaiah36 />} />
<Route path="/ஏசாயா-37" element={<Isaiah37 />} />
<Route path="/ஏசாயா-38" element={<Isaiah38 />} />
<Route path="/ஏசாயா-39" element={<Isaiah39 />} />
<Route path="/ஏசாயா-40" element={<Isaiah40 />} />
<Route path="/ஏசாயா-41" element={<Isaiah41 />} />
<Route path="/ஏசாயா-42" element={<Isaiah42 />} />
<Route path="/ஏசாயா-43" element={<Isaiah43 />} />
<Route path="/ஏசாயா-44" element={<Isaiah44 />} />
<Route path="/ஏசாயா-45" element={<Isaiah45 />} />
<Route path="/ஏசாயா-46" element={<Isaiah46 />} />
<Route path="/ஏசாயா-47" element={<Isaiah47 />} />
<Route path="/ஏசாயா-48" element={<Isaiah48 />} />
<Route path="/ஏசாயா-49" element={<Isaiah49 />} />
<Route path="/ஏசாயா-50" element={<Isaiah50 />} />
<Route path="/ஏசாயா-51" element={<Isaiah51 />} />
<Route path="/ஏசாயா-52" element={<Isaiah52 />} />
<Route path="/ஏசாயா-53" element={<Isaiah53 />} />
<Route path="/ஏசாயா-54" element={<Isaiah54 />} />
<Route path="/ஏசாயா-55" element={<Isaiah55 />} />
<Route path="/ஏசாயா-56" element={<Isaiah56 />} />
<Route path="/ஏசாயா-57" element={<Isaiah57 />} />
<Route path="/ஏசாயா-58" element={<Isaiah58 />} />
<Route path="/ஏசாயா-59" element={<Isaiah59 />} />
<Route path="/ஏசாயா-60" element={<Isaiah60 />} />
<Route path="/ஏசாயா-61" element={<Isaiah61 />} />
<Route path="/ஏசாயா-62" element={<Isaiah62 />} />
<Route path="/ஏசாயா-63" element={<Isaiah63 />} />
<Route path="/ஏசாயா-64" element={<Isaiah64 />} />
<Route path="/ஏசாயா-65" element={<Isaiah65 />} />
<Route path="/ஏசாயா-66" element={<Isaiah66 />} />

<Route path="/Isaiah"    element={<Isaiah />} />
<Route path="/Isaiah-1"  element={<Isaiah1 />} />
<Route path="/Isaiah-2"  element={<Isaiah2 />} />
<Route path="/Isaiah-3"  element={<Isaiah3 />} />
<Route path="/Isaiah-4"  element={<Isaiah4 />} />
<Route path="/Isaiah-5"  element={<Isaiah5 />} />
<Route path="/Isaiah-6"  element={<Isaiah6 />} />
<Route path="/Isaiah-7"  element={<Isaiah7 />} />
<Route path="/Isaiah-8"  element={<Isaiah8 />} />
<Route path="/Isaiah-9"  element={<Isaiah9 />} />
<Route path="/Isaiah-10" element={<Isaiah10 />} />
<Route path="/Isaiah-11" element={<Isaiah11 />} />
<Route path="/Isaiah-12" element={<Isaiah12 />} />
<Route path="/Isaiah-13" element={<Isaiah13 />} />
<Route path="/Isaiah-14" element={<Isaiah14 />} />
<Route path="/Isaiah-15" element={<Isaiah15 />} />
<Route path="/Isaiah-16" element={<Isaiah16 />} />
<Route path="/Isaiah-17" element={<Isaiah17 />} />
<Route path="/Isaiah-18" element={<Isaiah18 />} />
<Route path="/Isaiah-19" element={<Isaiah19 />} />
<Route path="/Isaiah-20" element={<Isaiah20 />} />
<Route path="/Isaiah-21" element={<Isaiah21 />} />
<Route path="/Isaiah-22" element={<Isaiah22 />} />
<Route path="/Isaiah-23" element={<Isaiah23 />} />
<Route path="/Isaiah-24" element={<Isaiah24 />} />
<Route path="/Isaiah-25" element={<Isaiah25 />} />
<Route path="/Isaiah-26" element={<Isaiah26 />} />
<Route path="/Isaiah-27" element={<Isaiah27 />} />
<Route path="/Isaiah-28" element={<Isaiah28 />} />
<Route path="/Isaiah-29" element={<Isaiah29 />} />
<Route path="/Isaiah-30" element={<Isaiah30 />} />
<Route path="/Isaiah-31" element={<Isaiah31 />} />
<Route path="/Isaiah-32" element={<Isaiah32 />} />
<Route path="/Isaiah-33" element={<Isaiah33 />} />
<Route path="/Isaiah-34" element={<Isaiah34 />} />
<Route path="/Isaiah-35" element={<Isaiah35 />} />
<Route path="/Isaiah-36" element={<Isaiah36 />} />
<Route path="/Isaiah-37" element={<Isaiah37 />} />
<Route path="/Isaiah-38" element={<Isaiah38 />} />
<Route path="/Isaiah-39" element={<Isaiah39 />} />
<Route path="/Isaiah-40" element={<Isaiah40 />} />
<Route path="/Isaiah-41" element={<Isaiah41 />} />
<Route path="/Isaiah-42" element={<Isaiah42 />} />
<Route path="/Isaiah-43" element={<Isaiah43 />} />
<Route path="/Isaiah-44" element={<Isaiah44 />} />
<Route path="/Isaiah-45" element={<Isaiah45 />} />
<Route path="/Isaiah-46" element={<Isaiah46 />} />
<Route path="/Isaiah-47" element={<Isaiah47 />} />
<Route path="/Isaiah-48" element={<Isaiah48 />} />
<Route path="/Isaiah-49" element={<Isaiah49 />} />
<Route path="/Isaiah-50" element={<Isaiah50 />} />
<Route path="/Isaiah-51" element={<Isaiah51 />} />
<Route path="/Isaiah-52" element={<Isaiah52 />} />
<Route path="/Isaiah-53" element={<Isaiah53 />} />
<Route path="/Isaiah-54" element={<Isaiah54 />} />
<Route path="/Isaiah-55" element={<Isaiah55 />} />
<Route path="/Isaiah-56" element={<Isaiah56 />} />
<Route path="/Isaiah-57" element={<Isaiah57 />} />
<Route path="/Isaiah-58" element={<Isaiah58 />} />
<Route path="/Isaiah-59" element={<Isaiah59 />} />
<Route path="/Isaiah-60" element={<Isaiah60 />} />
<Route path="/Isaiah-61" element={<Isaiah61 />} />
<Route path="/Isaiah-62" element={<Isaiah62 />} />
<Route path="/Isaiah-63" element={<Isaiah63 />} />
<Route path="/Isaiah-64" element={<Isaiah64 />} />
<Route path="/Isaiah-65" element={<Isaiah65 />} />
<Route path="/Isaiah-66" element={<Isaiah66 />} />

<Route path="/Eramia"    element={<Jeremiah />} />
<Route path="/Eramia-1"  element={<Jeremiah1 />} />
<Route path="/Eramia-2"  element={<Jeremiah2 />} />
<Route path="/Eramia-3"  element={<Jeremiah3 />} />
<Route path="/Eramia-4"  element={<Jeremiah4 />} />
<Route path="/Eramia-5"  element={<Jeremiah5 />} />
<Route path="/Eramia-6"  element={<Jeremiah6 />} />
<Route path="/Eramia-7"  element={<Jeremiah7 />} />
<Route path="/Eramia-8"  element={<Jeremiah8 />} />
<Route path="/Eramia-9"  element={<Jeremiah9 />} />
<Route path="/Eramia-10" element={<Jeremiah10 />} />
<Route path="/Eramia-11" element={<Jeremiah11 />} />
<Route path="/Eramia-12" element={<Jeremiah12 />} />
<Route path="/Eramia-13" element={<Jeremiah13 />} />
<Route path="/Eramia-14" element={<Jeremiah14 />} />
<Route path="/Eramia-15" element={<Jeremiah15 />} />
<Route path="/Eramia-16" element={<Jeremiah16 />} />
<Route path="/Eramia-17" element={<Jeremiah17 />} />
<Route path="/Eramia-18" element={<Jeremiah18 />} />
<Route path="/Eramia-19" element={<Jeremiah19 />} />
<Route path="/Eramia-20" element={<Jeremiah20 />} />
<Route path="/Eramia-21" element={<Jeremiah21 />} />
<Route path="/Eramia-22" element={<Jeremiah22 />} />
<Route path="/Eramia-23" element={<Jeremiah23 />} />
<Route path="/Eramia-24" element={<Jeremiah24 />} />
<Route path="/Eramia-25" element={<Jeremiah25 />} />
<Route path="/Eramia-26" element={<Jeremiah26 />} />
<Route path="/Eramia-27" element={<Jeremiah27 />} />
<Route path="/Eramia-28" element={<Jeremiah28 />} />
<Route path="/Eramia-29" element={<Jeremiah29 />} />
<Route path="/Eramia-30" element={<Jeremiah30 />} />
<Route path="/Eramia-31" element={<Jeremiah31 />} />
<Route path="/Eramia-32" element={<Jeremiah32 />} />
<Route path="/Eramia-33" element={<Jeremiah33 />} />
<Route path="/Eramia-34" element={<Jeremiah34 />} />
<Route path="/Eramia-35" element={<Jeremiah35 />} />
<Route path="/Eramia-36" element={<Jeremiah36 />} />
<Route path="/Eramia-37" element={<Jeremiah37 />} />
<Route path="/Eramia-38" element={<Jeremiah38 />} />
<Route path="/Eramia-39" element={<Jeremiah39 />} />
<Route path="/Eramia-40" element={<Jeremiah40 />} />
<Route path="/Eramia-41" element={<Jeremiah41 />} />
<Route path="/Eramia-42" element={<Jeremiah42 />} />
<Route path="/Eramia-43" element={<Jeremiah43 />} />
<Route path="/Eramia-44" element={<Jeremiah44 />} />
<Route path="/Eramia-45" element={<Jeremiah45 />} />
<Route path="/Eramia-46" element={<Jeremiah46 />} />
<Route path="/Eramia-47" element={<Jeremiah47 />} />
<Route path="/Eramia-48" element={<Jeremiah48 />} />
<Route path="/Eramia-49" element={<Jeremiah49 />} />
<Route path="/Eramia-50" element={<Jeremiah50 />} />
<Route path="/Eramia-51" element={<Jeremiah51 />} />
<Route path="/Eramia-52" element={<Jeremiah52 />} />

<Route path="/tamilbible-எரேமியா"    element={<Jeremiah />} />
<Route path="/tamilbible-எரேமியா-1"  element={<Jeremiah1 />} />
<Route path="/tamilbible-எரேமியா-2"  element={<Jeremiah2 />} />
<Route path="/tamilbible-எரேமியா-3"  element={<Jeremiah3 />} />
<Route path="/tamilbible-எரேமியா-4"  element={<Jeremiah4 />} />
<Route path="/tamilbible-எரேமியா-5"  element={<Jeremiah5 />} />
<Route path="/tamilbible-எரேமியா-6"  element={<Jeremiah6 />} />
<Route path="/tamilbible-எரேமியா-7"  element={<Jeremiah7 />} />
<Route path="/tamilbible-எரேமியா-8"  element={<Jeremiah8 />} />
<Route path="/tamilbible-எரேமியா-9"  element={<Jeremiah9 />} />
<Route path="/tamilbible-எரேமியா-10" element={<Jeremiah10 />} />
<Route path="/tamilbible-எரேமியா-11" element={<Jeremiah11 />} />
<Route path="/tamilbible-எரேமியா-12" element={<Jeremiah12 />} />
<Route path="/tamilbible-எரேமியா-13" element={<Jeremiah13 />} />
<Route path="/tamilbible-எரேமியா-14" element={<Jeremiah14 />} />
<Route path="/tamilbible-எரேமியா-15" element={<Jeremiah15 />} />
<Route path="/tamilbible-எரேமியா-16" element={<Jeremiah16 />} />
<Route path="/tamilbible-எரேமியா-17" element={<Jeremiah17 />} />
<Route path="/tamilbible-எரேமியா-18" element={<Jeremiah18 />} />
<Route path="/tamilbible-எரேமியா-19" element={<Jeremiah19 />} />
<Route path="/tamilbible-எரேமியா-20" element={<Jeremiah20 />} />
<Route path="/tamilbible-எரேமியா-21" element={<Jeremiah21 />} />
<Route path="/tamilbible-எரேமியா-22" element={<Jeremiah22 />} />
<Route path="/tamilbible-எரேமியா-23" element={<Jeremiah23 />} />
<Route path="/tamilbible-எரேமியா-24" element={<Jeremiah24 />} />
<Route path="/tamilbible-எரேமியா-25" element={<Jeremiah25 />} />
<Route path="/tamilbible-எரேமியா-26" element={<Jeremiah26 />} />
<Route path="/tamilbible-எரேமியா-27" element={<Jeremiah27 />} />
<Route path="/tamilbible-எரேமியா-28" element={<Jeremiah28 />} />
<Route path="/tamilbible-எரேமியா-29" element={<Jeremiah29 />} />
<Route path="/tamilbible-எரேமியா-30" element={<Jeremiah30 />} />
<Route path="/tamilbible-எரேமியா-31" element={<Jeremiah31 />} />
<Route path="/tamilbible-எரேமியா-32" element={<Jeremiah32 />} />
<Route path="/tamilbible-எரேமியா-33" element={<Jeremiah33 />} />
<Route path="/tamilbible-எரேமியா-34" element={<Jeremiah34 />} />
<Route path="/tamilbible-எரேமியா-35" element={<Jeremiah35 />} />
<Route path="/tamilbible-எரேமியா-36" element={<Jeremiah36 />} />
<Route path="/tamilbible-எரேமியா-37" element={<Jeremiah37 />} />
<Route path="/tamilbible-எரேமியா-38" element={<Jeremiah38 />} />
<Route path="/tamilbible-எரேமியா-39" element={<Jeremiah39 />} />
<Route path="/tamilbible-எரேமியா-40" element={<Jeremiah40 />} />
<Route path="/tamilbible-எரேமியா-41" element={<Jeremiah41 />} />
<Route path="/tamilbible-எரேமியா-42" element={<Jeremiah42 />} />
<Route path="/tamilbible-எரேமியா-43" element={<Jeremiah43 />} />
<Route path="/tamilbible-எரேமியா-44" element={<Jeremiah44 />} />
<Route path="/tamilbible-எரேமியா-45" element={<Jeremiah45 />} />
<Route path="/tamilbible-எரேமியா-46" element={<Jeremiah46 />} />
<Route path="/tamilbible-எரேமியா-47" element={<Jeremiah47 />} />
<Route path="/tamilbible-எரேமியா-48" element={<Jeremiah48 />} />
<Route path="/tamilbible-எரேமியா-49" element={<Jeremiah49 />} />
<Route path="/tamilbible-எரேமியா-50" element={<Jeremiah50 />} />
<Route path="/tamilbible-எரேமியா-51" element={<Jeremiah51 />} />
<Route path="/tamilbible-எரேமியா-52" element={<Jeremiah52 />} />


<Route path="/Jeremiah"    element={<Jeremiah />} />
<Route path="/Jeremiah-1"  element={<Jeremiah1 />} />
<Route path="/Jeremiah-2"  element={<Jeremiah2 />} />
<Route path="/Jeremiah-3"  element={<Jeremiah3 />} />
<Route path="/Jeremiah-4"  element={<Jeremiah4 />} />
<Route path="/Jeremiah-5"  element={<Jeremiah5 />} />
<Route path="/Jeremiah-6"  element={<Jeremiah6 />} />
<Route path="/Jeremiah-7"  element={<Jeremiah7 />} />
<Route path="/Jeremiah-8"  element={<Jeremiah8 />} />
<Route path="/Jeremiah-9"  element={<Jeremiah9 />} />
<Route path="/Jeremiah-10" element={<Jeremiah10 />} />
<Route path="/Jeremiah-11" element={<Jeremiah11 />} />
<Route path="/Jeremiah-12" element={<Jeremiah12 />} />
<Route path="/Jeremiah-13" element={<Jeremiah13 />} />
<Route path="/Jeremiah-14" element={<Jeremiah14 />} />
<Route path="/Jeremiah-15" element={<Jeremiah15 />} />
<Route path="/Jeremiah-16" element={<Jeremiah16 />} />
<Route path="/Jeremiah-17" element={<Jeremiah17 />} />
<Route path="/Jeremiah-18" element={<Jeremiah18 />} />
<Route path="/Jeremiah-19" element={<Jeremiah19 />} />
<Route path="/Jeremiah-20" element={<Jeremiah20 />} />
<Route path="/Jeremiah-21" element={<Jeremiah21 />} />
<Route path="/Jeremiah-22" element={<Jeremiah22 />} />
<Route path="/Jeremiah-23" element={<Jeremiah23 />} />
<Route path="/Jeremiah-24" element={<Jeremiah24 />} />
<Route path="/Jeremiah-25" element={<Jeremiah25 />} />
<Route path="/Jeremiah-26" element={<Jeremiah26 />} />
<Route path="/Jeremiah-27" element={<Jeremiah27 />} />
<Route path="/Jeremiah-28" element={<Jeremiah28 />} />
<Route path="/Jeremiah-29" element={<Jeremiah29 />} />
<Route path="/Jeremiah-30" element={<Jeremiah30 />} />
<Route path="/Jeremiah-31" element={<Jeremiah31 />} />
<Route path="/Jeremiah-32" element={<Jeremiah32 />} />
<Route path="/Jeremiah-33" element={<Jeremiah33 />} />
<Route path="/Jeremiah-34" element={<Jeremiah34 />} />
<Route path="/Jeremiah-35" element={<Jeremiah35 />} />
<Route path="/Jeremiah-36" element={<Jeremiah36 />} />
<Route path="/Jeremiah-37" element={<Jeremiah37 />} />
<Route path="/Jeremiah-38" element={<Jeremiah38 />} />
<Route path="/Jeremiah-39" element={<Jeremiah39 />} />
<Route path="/Jeremiah-40" element={<Jeremiah40 />} />
<Route path="/Jeremiah-41" element={<Jeremiah41 />} />
<Route path="/Jeremiah-42" element={<Jeremiah42 />} />
<Route path="/Jeremiah-43" element={<Jeremiah43 />} />
<Route path="/Jeremiah-44" element={<Jeremiah44 />} />
<Route path="/Jeremiah-45" element={<Jeremiah45 />} />
<Route path="/Jeremiah-46" element={<Jeremiah46 />} />
<Route path="/Jeremiah-47" element={<Jeremiah47 />} />
<Route path="/Jeremiah-48" element={<Jeremiah48 />} />
<Route path="/Jeremiah-49" element={<Jeremiah49 />} />
<Route path="/Jeremiah-50" element={<Jeremiah50 />} />
<Route path="/Jeremiah-51" element={<Jeremiah51 />} />
<Route path="/Jeremiah-52" element={<Jeremiah52 />} />

<Route path="/Pulambal"    element={<Lamentations />} />
<Route path="/Pulambal-1"  element={<Lamentations1 />} />
<Route path="/Pulambal-2"  element={<Lamentations2 />} />
<Route path="/Pulambal-3"  element={<Lamentations3 />} />
<Route path="/Pulambal-4"  element={<Lamentations4 />} />
<Route path="/Pulambal-5"  element={<Lamentations5 />} />

<Route path="/tamilbible-புலம்பல்"    element={<Lamentations />} />
<Route path="/tamilbible-புலம்பல்-1"  element={<Lamentations1 />} />
<Route path="/tamilbible-புலம்பல்-2"  element={<Lamentations2 />} />
<Route path="/tamilbible-புலம்பல்-3"  element={<Lamentations3 />} />
<Route path="/tamilbible-புலம்பல்-4"  element={<Lamentations4 />} />
<Route path="/tamilbible-புலம்பல்-5"  element={<Lamentations5 />} />

<Route path="/Lamentations"    element={<Lamentations />} />
<Route path="/Lamentations-1"  element={<Lamentations1 />} />
<Route path="/Lamentations-2"  element={<Lamentations2 />} />
<Route path="/Lamentations-3"  element={<Lamentations3 />} />
<Route path="/Lamentations-4"  element={<Lamentations4 />} />
<Route path="/Lamentations-5"  element={<Lamentations5 />} />

<Route path="/Ezekiel"    element={<Ezekiel />} />
<Route path="/Ezekiel-1"  element={<Ezekiel1 />} />
<Route path="/Ezekiel-2"  element={<Ezekiel2 />} />
<Route path="/Ezekiel-3"  element={<Ezekiel3 />} />
<Route path="/Ezekiel-4"  element={<Ezekiel4 />} />
<Route path="/Ezekiel-5"  element={<Ezekiel5 />} />
<Route path="/Ezekiel-6"  element={<Ezekiel6 />} />
<Route path="/Ezekiel-7"  element={<Ezekiel7 />} />
<Route path="/Ezekiel-8"  element={<Ezekiel8 />} />
<Route path="/Ezekiel-9"  element={<Ezekiel9 />} />
<Route path="/Ezekiel-10" element={<Ezekiel10 />} />
<Route path="/Ezekiel-11" element={<Ezekiel11 />} />
<Route path="/Ezekiel-12" element={<Ezekiel12 />} />
<Route path="/Ezekiel-13" element={<Ezekiel13 />} />
<Route path="/Ezekiel-14" element={<Ezekiel14 />} />
<Route path="/Ezekiel-15" element={<Ezekiel15 />} />
<Route path="/Ezekiel-16" element={<Ezekiel16 />} />
<Route path="/Ezekiel-17" element={<Ezekiel17 />} />
<Route path="/Ezekiel-18" element={<Ezekiel18 />} />
<Route path="/Ezekiel-19" element={<Ezekiel19 />} />
<Route path="/Ezekiel-20" element={<Ezekiel20 />} />
<Route path="/Ezekiel-21" element={<Ezekiel21 />} />
<Route path="/Ezekiel-22" element={<Ezekiel22 />} />
<Route path="/Ezekiel-23" element={<Ezekiel23 />} />
<Route path="/Ezekiel-24" element={<Ezekiel24 />} />
<Route path="/Ezekiel-25" element={<Ezekiel25 />} />
<Route path="/Ezekiel-26" element={<Ezekiel26 />} />
<Route path="/Ezekiel-27" element={<Ezekiel27 />} />
<Route path="/Ezekiel-28" element={<Ezekiel28 />} />
<Route path="/Ezekiel-29" element={<Ezekiel29 />} />
<Route path="/Ezekiel-30" element={<Ezekiel30 />} />
<Route path="/Ezekiel-31" element={<Ezekiel31 />} />
<Route path="/Ezekiel-32" element={<Ezekiel32 />} />
<Route path="/Ezekiel-33" element={<Ezekiel33 />} />
<Route path="/Ezekiel-34" element={<Ezekiel34 />} />
<Route path="/Ezekiel-35" element={<Ezekiel35 />} />
<Route path="/Ezekiel-36" element={<Ezekiel36 />} />
<Route path="/Ezekiel-37" element={<Ezekiel37 />} />
<Route path="/Ezekiel-38" element={<Ezekiel38 />} />
<Route path="/Ezekiel-39" element={<Ezekiel39 />} />
<Route path="/Ezekiel-40" element={<Ezekiel40 />} />
<Route path="/Ezekiel-41" element={<Ezekiel41 />} />
<Route path="/Ezekiel-42" element={<Ezekiel42 />} />
<Route path="/Ezekiel-43" element={<Ezekiel43 />} />
<Route path="/Ezekiel-44" element={<Ezekiel44 />} />
<Route path="/Ezekiel-45" element={<Ezekiel45 />} />
<Route path="/Ezekiel-46" element={<Ezekiel46 />} />
<Route path="/Ezekiel-47" element={<Ezekiel47 />} />
<Route path="/Ezekiel-48" element={<Ezekiel48 />} />

<Route path="/tamilbible-எசேக்கியேல்"    element={<Ezekiel />} />
<Route path="/tamilbible-எசேக்கியேல்-1"  element={<Ezekiel1 />} />
<Route path="/tamilbible-எசேக்கியேல்-2"  element={<Ezekiel2 />} />
<Route path="/tamilbible-எசேக்கியேல்-3"  element={<Ezekiel3 />} />
<Route path="/tamilbible-எசேக்கியேல்-4"  element={<Ezekiel4 />} />
<Route path="/tamilbible-எசேக்கியேல்-5"  element={<Ezekiel5 />} />
<Route path="/tamilbible-எசேக்கியேல்-6"  element={<Ezekiel6 />} />
<Route path="/tamilbible-எசேக்கியேல்-7"  element={<Ezekiel7 />} />
<Route path="/tamilbible-எசேக்கியேல்-8"  element={<Ezekiel8 />} />
<Route path="/tamilbible-எசேக்கியேல்-9"  element={<Ezekiel9 />} />
<Route path="/tamilbible-எசேக்கியேல்-10" element={<Ezekiel10 />} />
<Route path="/tamilbible-எசேக்கியேல்-11" element={<Ezekiel11 />} />
<Route path="/tamilbible-எசேக்கியேல்-12" element={<Ezekiel12 />} />
<Route path="/tamilbible-எசேக்கியேல்-13" element={<Ezekiel13 />} />
<Route path="/tamilbible-எசேக்கியேல்-14" element={<Ezekiel14 />} />
<Route path="/tamilbible-எசேக்கியேல்-15" element={<Ezekiel15 />} />
<Route path="/tamilbible-எசேக்கியேல்-16" element={<Ezekiel16 />} />
<Route path="/tamilbible-எசேக்கியேல்-17" element={<Ezekiel17 />} />
<Route path="/tamilbible-எசேக்கியேல்-18" element={<Ezekiel18 />} />
<Route path="/tamilbible-எசேக்கியேல்-19" element={<Ezekiel19 />} />
<Route path="/tamilbible-எசேக்கியேல்-20" element={<Ezekiel20 />} />
<Route path="/tamilbible-எசேக்கியேல்-21" element={<Ezekiel21 />} />
<Route path="/tamilbible-எசேக்கியேல்-22" element={<Ezekiel22 />} />
<Route path="/tamilbible-எசேக்கியேல்-23" element={<Ezekiel23 />} />
<Route path="/tamilbible-எசேக்கியேல்-24" element={<Ezekiel24 />} />
<Route path="/tamilbible-எசேக்கியேல்-25" element={<Ezekiel25 />} />
<Route path="/tamilbible-எசேக்கியேல்-26" element={<Ezekiel26 />} />
<Route path="/tamilbible-எசேக்கியேல்-27" element={<Ezekiel27 />} />
<Route path="/tamilbible-எசேக்கியேல்-28" element={<Ezekiel28 />} />
<Route path="/tamilbible-எசேக்கியேல்-29" element={<Ezekiel29 />} />
<Route path="/tamilbible-எசேக்கியேல்-30" element={<Ezekiel30 />} />
<Route path="/tamilbible-எசேக்கியேல்-31" element={<Ezekiel31 />} />
<Route path="/tamilbible-எசேக்கியேல்-32" element={<Ezekiel32 />} />
<Route path="/tamilbible-எசேக்கியேல்-33" element={<Ezekiel33 />} />
<Route path="/tamilbible-எசேக்கியேல்-34" element={<Ezekiel34 />} />
<Route path="/tamilbible-எசேக்கியேல்-35" element={<Ezekiel35 />} />
<Route path="/tamilbible-எசேக்கியேல்-36" element={<Ezekiel36 />} />
<Route path="/tamilbible-எசேக்கியேல்-37" element={<Ezekiel37 />} />
<Route path="/tamilbible-எசேக்கியேல்-38" element={<Ezekiel38 />} />
<Route path="/tamilbible-எசேக்கியேல்-39" element={<Ezekiel39 />} />
<Route path="/tamilbible-எசேக்கியேல்-40" element={<Ezekiel40 />} />
<Route path="/tamilbible-எசேக்கியேல்-41" element={<Ezekiel41 />} />
<Route path="/tamilbible-எசேக்கியேல்-42" element={<Ezekiel42 />} />
<Route path="/tamilbible-எசேக்கியேல்-43" element={<Ezekiel43 />} />
<Route path="/tamilbible-எசேக்கியேல்-44" element={<Ezekiel44 />} />
<Route path="/tamilbible-எசேக்கியேல்-45" element={<Ezekiel45 />} />
<Route path="/tamilbible-எசேக்கியேல்-46" element={<Ezekiel46 />} />
<Route path="/tamilbible-எசேக்கியேல்-47" element={<Ezekiel47 />} />
<Route path="/tamilbible-எசேக்கியேல்-48" element={<Ezekiel48 />} />

<Route path="/Daniel"    element={<Daniel />} />
<Route path="/Daniel-1"  element={<Daniel1 />} />
<Route path="/Daniel-2"  element={<Daniel2 />} />
<Route path="/Daniel-3"  element={<Daniel3 />} />
<Route path="/Daniel-4"  element={<Daniel4 />} />
<Route path="/Daniel-5"  element={<Daniel5 />} />
<Route path="/Daniel-6"  element={<Daniel6 />} />
<Route path="/Daniel-7"  element={<Daniel7 />} />
<Route path="/Daniel-8"  element={<Daniel8 />} />
<Route path="/Daniel-9"  element={<Daniel9 />} />
<Route path="/Daniel-10" element={<Daniel10 />} />
<Route path="/Daniel-11" element={<Daniel11 />} />
<Route path="/Daniel-12" element={<Daniel12 />} />

<Route path="/tamilbible-தானியேல்"    element={<Daniel />} />
<Route path="/tamilbible-தானியேல்-1"  element={<Daniel1 />} />
<Route path="/tamilbible-தானியேல்-2"  element={<Daniel2 />} />
<Route path="/tamilbible-தானியேல்-3"  element={<Daniel3 />} />
<Route path="/tamilbible-தானியேல்-4"  element={<Daniel4 />} />
<Route path="/tamilbible-தானியேல்-5"  element={<Daniel5 />} />
<Route path="/tamilbible-தானியேல்-6"  element={<Daniel6 />} />
<Route path="/tamilbible-தானியேல்-7"  element={<Daniel7 />} />
<Route path="/tamilbible-தானியேல்-8"  element={<Daniel8 />} />
<Route path="/tamilbible-தானியேல்-9"  element={<Daniel9 />} />
<Route path="/tamilbible-தானியேல்-10" element={<Daniel10 />} />
<Route path="/tamilbible-தானியேல்-11" element={<Daniel11 />} />
<Route path="/tamilbible-தானியேல்-12" element={<Daniel12 />} />

<Route path="/Osiya"    element={<Hosea />} />
<Route path="/Osiya-1"  element={<Hosea1 />} />
<Route path="/Osiya-2"  element={<Hosea2 />} />
<Route path="/Osiya-3"  element={<Hosea3 />} />
<Route path="/Osiya-4"  element={<Hosea4 />} />
<Route path="/Osiya-5"  element={<Hosea5 />} />
<Route path="/Osiya-6"  element={<Hosea6 />} />
<Route path="/Osiya-7"  element={<Hosea7 />} />
<Route path="/Osiya-8"  element={<Hosea8 />} />
<Route path="/Osiya-9"  element={<Hosea9 />} />
<Route path="/Osiya-10" element={<Hosea10 />} />
<Route path="/Osiya-11" element={<Hosea11 />} />
<Route path="/Osiya-12" element={<Hosea12 />} />
<Route path="/Osiya-13" element={<Hosea13 />} />
<Route path="/Osiya-14" element={<Hosea14 />} />

<Route path="/tamilbible-ஓசியா"    element={<Hosea />} />
<Route path="/tamilbible-ஓசியா-1"  element={<Hosea1 />} />
<Route path="/tamilbible-ஓசியா-2"  element={<Hosea2 />} />
<Route path="/tamilbible-ஓசியா-3"  element={<Hosea3 />} />
<Route path="/tamilbible-ஓசியா-4"  element={<Hosea4 />} />
<Route path="/tamilbible-ஓசியா-5"  element={<Hosea5 />} />
<Route path="/tamilbible-ஓசியா-6"  element={<Hosea6 />} />
<Route path="/tamilbible-ஓசியா-7"  element={<Hosea7 />} />
<Route path="/tamilbible-ஓசியா-8"  element={<Hosea8 />} />
<Route path="/tamilbible-ஓசியா-9"  element={<Hosea9 />} />
<Route path="/tamilbible-ஓசியா-10" element={<Hosea10 />} />
<Route path="/tamilbible-ஓசியா-11" element={<Hosea11 />} />
<Route path="/tamilbible-ஓசியா-12" element={<Hosea12 />} />
<Route path="/tamilbible-ஓசியா-13" element={<Hosea13 />} />
<Route path="/tamilbible-ஓசியா-14" element={<Hosea14 />} />

<Route path="/Yovel"    element={<Joel />} />
<Route path="/Yovel-1"  element={<Joel1 />} />
<Route path="/Yovel-2"  element={<Joel2 />} />
<Route path="/Yovel-3"  element={<Joel3 />} />

<Route path="/tamilbible-யோவேல்"    element={<Joel />} />
<Route path="/tamilbible-யோவேல்-1"  element={<Joel1 />} />
<Route path="/tamilbible-யோவேல்-2"  element={<Joel2 />} />
<Route path="/tamilbible-யோவேல்-3"  element={<Joel3 />} />

<Route path="/Joel"    element={<Joel />} />
<Route path="/Joel-1"  element={<Joel1 />} />
<Route path="/Joel-2"  element={<Joel2 />} />
<Route path="/Joel-3"  element={<Joel3 />} />

<Route path="/Aamos"    element={<Amos />} />
<Route path="/Aamos-1"  element={<Amos1 />} />
<Route path="/Aamos-2"  element={<Amos2 />} />
<Route path="/Aamos-3"  element={<Amos3 />} />
<Route path="/Aamos-4"  element={<Amos4 />} />
<Route path="/Aamos-5"  element={<Amos5 />} />
<Route path="/Aamos-6"  element={<Amos6 />} />
<Route path="/Aamos-7"  element={<Amos7 />} />
<Route path="/Aamos-8"  element={<Amos8 />} />
<Route path="/Aamos-9"  element={<Amos9 />} />

<Route path="/tamilbible-ஆமோஸ்"    element={<Amos />} />
<Route path="/tamilbible-ஆமோஸ்-1"  element={<Amos1 />} />
<Route path="/tamilbible-ஆமோஸ்-2"  element={<Amos2 />} />
<Route path="/tamilbible-ஆமோஸ்-3"  element={<Amos3 />} />
<Route path="/tamilbible-ஆமோஸ்-4"  element={<Amos4 />} />
<Route path="/tamilbible-ஆமோஸ்-5"  element={<Amos5 />} />
<Route path="/tamilbible-ஆமோஸ்-6"  element={<Amos6 />} />
<Route path="/tamilbible-ஆமோஸ்-7"  element={<Amos7 />} />
<Route path="/tamilbible-ஆமோஸ்-8"  element={<Amos8 />} />
<Route path="/tamilbible-ஆமோஸ்-9"  element={<Amos9 />} />

<Route path="/Obadiah"    element={<Obadiah />} />
<Route path="/Obadiah-1"  element={<Obadiah1 />} />

<Route path="/tamilbible-ஒபதியா"    element={<Obadiah />} />
<Route path="/tamilbible-ஒபதியா-1"  element={<Obadiah1 />} />

<Route path="/Yona"    element={<Jonah />} />
<Route path="/Yona-1"  element={<Jonah1 />} />
<Route path="/Yona-2"  element={<Jonah2 />} />
<Route path="/Yona-3"  element={<Jonah3 />} />
<Route path="/Yona-4"  element={<Jonah4 />} />

<Route path="/tamilbible-யோனா"    element={<Jonah />} />
<Route path="/tamilbible-யோனா-1"  element={<Jonah1 />} />
<Route path="/tamilbible-யோனா-2"  element={<Jonah2 />} />
<Route path="/tamilbible-யோனா-3"  element={<Jonah3 />} />
<Route path="/tamilbible-யோனா-4"  element={<Jonah4 />} />

<Route path="/Jonah"    element={<Jonah />} />
<Route path="/Jonah-1"  element={<Jonah1 />} />
<Route path="/Jonah-2"  element={<Jonah2 />} />
<Route path="/Jonah-3"  element={<Jonah3 />} />
<Route path="/Jonah-4"  element={<Jonah4 />} />

<Route path="/Meega"    element={<Micah />} />
<Route path="/Meega-1"  element={<Micah1 />} />
<Route path="/Meega-2"  element={<Micah2 />} />
<Route path="/Meega-3"  element={<Micah3 />} />
<Route path="/Meega-4"  element={<Micah4 />} />
<Route path="/Meega-5"  element={<Micah5 />} />
<Route path="/Meega-6"  element={<Micah6 />} />
<Route path="/Meega-7"  element={<Micah7 />} />

<Route path="/tamilbible-மீகா"    element={<Micah />} />
<Route path="/tamilbible-மீகா-1"  element={<Micah1 />} />
<Route path="/tamilbible-மீகா-2"  element={<Micah2 />} />
<Route path="/tamilbible-மீகா-3"  element={<Micah3 />} />
<Route path="/tamilbible-மீகா-4"  element={<Micah4 />} />
<Route path="/tamilbible-மீகா-5"  element={<Micah5 />} />
<Route path="/tamilbible-மீகா-6"  element={<Micah6 />} />
<Route path="/tamilbible-மீகா-7"  element={<Micah7 />} />

<Route path="/Nahum"    element={<Nahum />} />
<Route path="/Nahum-1"  element={<Nahum1 />} />
<Route path="/Nahum-2"  element={<Nahum2 />} />
<Route path="/Nahum-3"  element={<Nahum3 />} />

<Route path="/tamilbible-நாகூம்"    element={<Nahum />} />
<Route path="/tamilbible-நாகூம்-1"  element={<Nahum1 />} />
<Route path="/tamilbible-நாகூம்-2"  element={<Nahum2 />} />
<Route path="/tamilbible-நாகூம்-3"  element={<Nahum3 />} />

<Route path="/Habakkuk"    element={<Habakkuk />} />
<Route path="/Habakkuk-1"  element={<Habakkuk1 />} />
<Route path="/Habakkuk-2"  element={<Habakkuk2 />} />
<Route path="/Habakkuk-3"  element={<Habakkuk3 />} />

<Route path="/tamilbible-ஆபகூக்"    element={<Habakkuk />} />
<Route path="/tamilbible-ஆபகூக்-1"  element={<Habakkuk1 />} />
<Route path="/tamilbible-ஆபகூக்-2"  element={<Habakkuk2 />} />
<Route path="/tamilbible-ஆபகூக்-3"  element={<Habakkuk3 />} />

<Route path="/Seppaniya"    element={<Zephaniah />} />
<Route path="/Seppaniya-1"  element={<Zephaniah1 />} />
<Route path="/Seppaniya-2"  element={<Zephaniah2 />} />
<Route path="/Seppaniya-3"  element={<Zephaniah3 />} />

<Route path="/Zephaniah"    element={<Zephaniah />} />
<Route path="/Zephaniah-1"  element={<Zephaniah1 />} />
<Route path="/Zephaniah-2"  element={<Zephaniah2 />} />
<Route path="/Zephaniah-3"  element={<Zephaniah3 />} />

<Route path="/tamilbible-செப்பனியா"    element={<Zephaniah />} />
<Route path="/tamilbible-செப்பனியா-1"  element={<Zephaniah1 />} />
<Route path="/tamilbible-செப்பனியா-2"  element={<Zephaniah2 />} />
<Route path="/tamilbible-செப்பனியா-3"  element={<Zephaniah3 />} />

<Route path="/Haggai"    element={<Haggai />} />
<Route path="/Haggai-1"  element={<Haggai1 />} />
<Route path="/Haggai-2"  element={<Haggai2 />} />

<Route path="/tamilbible-ஆகாய்"    element={<Haggai />} />
<Route path="/tamilbible-ஆகாய்-1"  element={<Haggai1 />} />
<Route path="/tamilbible-ஆகாய்-2"  element={<Haggai2 />} />

<Route path="/Sagariya"    element={<Zechariah />} />
<Route path="/Sagariya-1"  element={<Zechariah1 />} />
<Route path="/Sagariya-2"  element={<Zechariah2 />} />
<Route path="/Sagariya-3"  element={<Zechariah3 />} />
<Route path="/Sagariya-4"  element={<Zechariah4 />} />
<Route path="/Sagariya-5"  element={<Zechariah5 />} />
<Route path="/Sagariya-6"  element={<Zechariah6 />} />
<Route path="/Sagariya-7"  element={<Zechariah7 />} />
<Route path="/Sagariya-8"  element={<Zechariah8 />} />
<Route path="/Sagariya-9"  element={<Zechariah9 />} />
<Route path="/Sagariya-10" element={<Zechariah10 />} />
<Route path="/Sagariya-11" element={<Zechariah11 />} />
<Route path="/Sagariya-12" element={<Zechariah12 />} />
<Route path="/Sagariya-13" element={<Zechariah13 />} />
<Route path="/Sagariya-14" element={<Zechariah14 />} />

<Route path="/tamilbible-சகரியா"    element={<Zechariah />} />
<Route path="/tamilbible-சகரியா-1"  element={<Zechariah1 />} />
<Route path="/tamilbible-சகரியா-2"  element={<Zechariah2 />} />
<Route path="/tamilbible-சகரியா-3"  element={<Zechariah3 />} />
<Route path="/tamilbible-சகரியா-4"  element={<Zechariah4 />} />
<Route path="/tamilbible-சகரியா-5"  element={<Zechariah5 />} />
<Route path="/tamilbible-சகரியா-6"  element={<Zechariah6 />} />
<Route path="/tamilbible-சகரியா-7"  element={<Zechariah7 />} />
<Route path="/tamilbible-சகரியா-8"  element={<Zechariah8 />} />
<Route path="/tamilbible-சகரியா-9"  element={<Zechariah9 />} />
<Route path="/tamilbible-சகரியா-10" element={<Zechariah10 />} />
<Route path="/tamilbible-சகரியா-11" element={<Zechariah11 />} />
<Route path="/tamilbible-சகரியா-12" element={<Zechariah12 />} />
<Route path="/tamilbible-சகரியா-13" element={<Zechariah13 />} />
<Route path="/tamilbible-சகரியா-14" element={<Zechariah14 />} />

<Route path="/Zechariah"    element={<Zechariah />} />
<Route path="/Zechariah-1"  element={<Zechariah1 />} />
<Route path="/Zechariah-2"  element={<Zechariah2 />} />
<Route path="/Zechariah-3"  element={<Zechariah3 />} />
<Route path="/Zechariah-4"  element={<Zechariah4 />} />
<Route path="/Zechariah-5"  element={<Zechariah5 />} />
<Route path="/Zechariah-6"  element={<Zechariah6 />} />
<Route path="/Zechariah-7"  element={<Zechariah7 />} />
<Route path="/Zechariah-8"  element={<Zechariah8 />} />
<Route path="/Zechariah-9"  element={<Zechariah9 />} />
<Route path="/Zechariah-10" element={<Zechariah10 />} />
<Route path="/Zechariah-11" element={<Zechariah11 />} />
<Route path="/Zechariah-12" element={<Zechariah12 />} />
<Route path="/Zechariah-13" element={<Zechariah13 />} />
<Route path="/Zechariah-14" element={<Zechariah14 />} />

<Route path="/Malgiya"    element={<Malachi />} />
<Route path="/Malgiya-1"  element={<Malachi1 />} />
<Route path="/Malgiya-2"  element={<Malachi2 />} />
<Route path="/Malgiya-3"  element={<Malachi3 />} />
<Route path="/Malgiya-4"  element={<Malachi4 />} />

<Route path="/tamilbible-மல்கியா"    element={<Malachi />} />
<Route path="/tamilbible-மல்கியா-1"  element={<Malachi1 />} />
<Route path="/tamilbible-மல்கியா-2"  element={<Malachi2 />} />
<Route path="/tamilbible-மல்கியா-3"  element={<Malachi3 />} />
<Route path="/tamilbible-மல்கியா-4"  element={<Malachi4 />} />

<Route path="/Malachi"    element={<Malachi />} />
<Route path="/Malachi-1"  element={<Malachi1 />} />
<Route path="/Malachi-2"  element={<Malachi2 />} />
<Route path="/Malachi-3"  element={<Malachi3 />} />
<Route path="/Malachi-4"  element={<Malachi4 />} />

<Route path="/Matthew"    element={<Matthew />} />
<Route path="/Matthew-1"  element={<Matthew1 />} />
<Route path="/Matthew-2"  element={<Matthew2 />} />
<Route path="/Matthew-3"  element={<Matthew3 />} />
<Route path="/Matthew-4"  element={<Matthew4 />} />
<Route path="/Matthew-5"  element={<Matthew5 />} />
<Route path="/Matthew-6"  element={<Matthew6 />} />
<Route path="/Matthew-7"  element={<Matthew7 />} />
<Route path="/Matthew-8"  element={<Matthew8 />} />
<Route path="/Matthew-9"  element={<Matthew9 />} />
<Route path="/Matthew-10" element={<Matthew10 />} />
<Route path="/Matthew-11" element={<Matthew11 />} />
<Route path="/Matthew-12" element={<Matthew12 />} />
<Route path="/Matthew-13" element={<Matthew13 />} />
<Route path="/Matthew-14" element={<Matthew14 />} />
<Route path="/Matthew-15" element={<Matthew15 />} />
<Route path="/Matthew-16" element={<Matthew16 />} />
<Route path="/Matthew-17" element={<Matthew17 />} />
<Route path="/Matthew-18" element={<Matthew18 />} />
<Route path="/Matthew-19" element={<Matthew19 />} />
<Route path="/Matthew-20" element={<Matthew20 />} />
<Route path="/Matthew-21" element={<Matthew21 />} />
<Route path="/Matthew-22" element={<Matthew22 />} />
<Route path="/Matthew-23" element={<Matthew23 />} />
<Route path="/Matthew-24" element={<Matthew24 />} />
<Route path="/Matthew-25" element={<Matthew25 />} />
<Route path="/Matthew-26" element={<Matthew26 />} />
<Route path="/Matthew-27" element={<Matthew27 />} />
<Route path="/Matthew-28" element={<Matthew28 />} />

<Route path="/tamilbible-மத்தேயு"    element={<Matthew />} />
<Route path="/tamilbible-மத்தேயு-1"  element={<Matthew1 />} />
<Route path="/tamilbible-மத்தேயு-2"  element={<Matthew2 />} />
<Route path="/tamilbible-மத்தேயு-3"  element={<Matthew3 />} />
<Route path="/tamilbible-மத்தேயு-4"  element={<Matthew4 />} />
<Route path="/tamilbible-மத்தேயு-5"  element={<Matthew5 />} />
<Route path="/tamilbible-மத்தேயு-6"  element={<Matthew6 />} />
<Route path="/tamilbible-மத்தேயு-7"  element={<Matthew7 />} />
<Route path="/tamilbible-மத்தேயு-8"  element={<Matthew8 />} />
<Route path="/tamilbible-மத்தேயு-9"  element={<Matthew9 />} />
<Route path="/tamilbible-மத்தேயு-10" element={<Matthew10 />} />
<Route path="/tamilbible-மத்தேயு-11" element={<Matthew11 />} />
<Route path="/tamilbible-மத்தேயு-12" element={<Matthew12 />} />
<Route path="/tamilbible-மத்தேயு-13" element={<Matthew13 />} />
<Route path="/tamilbible-மத்தேயு-14" element={<Matthew14 />} />
<Route path="/tamilbible-மத்தேயு-15" element={<Matthew15 />} />
<Route path="/tamilbible-மத்தேயு-16" element={<Matthew16 />} />
<Route path="/tamilbible-மத்தேயு-17" element={<Matthew17 />} />
<Route path="/tamilbible-மத்தேயு-18" element={<Matthew18 />} />
<Route path="/tamilbible-மத்தேயு-19" element={<Matthew19 />} />
<Route path="/tamilbible-மத்தேயு-20" element={<Matthew20 />} />
<Route path="/tamilbible-மத்தேயு-21" element={<Matthew21 />} />
<Route path="/tamilbible-மத்தேயு-22" element={<Matthew22 />} />
<Route path="/tamilbible-மத்தேயு-23" element={<Matthew23 />} />
<Route path="/tamilbible-மத்தேயு-24" element={<Matthew24 />} />
<Route path="/tamilbible-மத்தேயு-25" element={<Matthew25 />} />
<Route path="/tamilbible-மத்தேயு-26" element={<Matthew26 />} />
<Route path="/tamilbible-மத்தேயு-27" element={<Matthew27 />} />
<Route path="/tamilbible-மத்தேயு-28" element={<Matthew28 />} />

<Route path="/Mark"    element={<Mark />} />
<Route path="/Mark-1"  element={<Mark1 />} />
<Route path="/Mark-2"  element={<Mark2 />} />
<Route path="/Mark-3"  element={<Mark3 />} />
<Route path="/Mark-4"  element={<Mark4 />} />
<Route path="/Mark-5"  element={<Mark5 />} />
<Route path="/Mark-6"  element={<Mark6 />} />
<Route path="/Mark-7"  element={<Mark7 />} />
<Route path="/Mark-8"  element={<Mark8 />} />
<Route path="/Mark-9"  element={<Mark9 />} />
<Route path="/Mark-10" element={<Mark10 />} />
<Route path="/Mark-11" element={<Mark11 />} />
<Route path="/Mark-12" element={<Mark12 />} />
<Route path="/Mark-13" element={<Mark13 />} />
<Route path="/Mark-14" element={<Mark14 />} />
<Route path="/Mark-15" element={<Mark15 />} />
<Route path="/Mark-16" element={<Mark16 />} />

<Route path="/tamilbible-மாற்கு"    element={<Mark />} />
<Route path="/tamilbible-மாற்கு-1"  element={<Mark1 />} />
<Route path="/tamilbible-மாற்கு-2"  element={<Mark2 />} />
<Route path="/tamilbible-மாற்கு-3"  element={<Mark3 />} />
<Route path="/tamilbible-மாற்கு-4"  element={<Mark4 />} />
<Route path="/tamilbible-மாற்கு-5"  element={<Mark5 />} />
<Route path="/tamilbible-மாற்கு-6"  element={<Mark6 />} />
<Route path="/tamilbible-மாற்கு-7"  element={<Mark7 />} />
<Route path="/tamilbible-மாற்கு-8"  element={<Mark8 />} />
<Route path="/tamilbible-மாற்கு-9"  element={<Mark9 />} />
<Route path="/tamilbible-மாற்கு-10" element={<Mark10 />} />
<Route path="/tamilbible-மாற்கு-11" element={<Mark11 />} />
<Route path="/tamilbible-மாற்கு-12" element={<Mark12 />} />
<Route path="/tamilbible-மாற்கு-13" element={<Mark13 />} />
<Route path="/tamilbible-மாற்கு-14" element={<Mark14 />} />
<Route path="/tamilbible-மாற்கு-15" element={<Mark15 />} />
<Route path="/tamilbible-மாற்கு-16" element={<Mark16 />} />

<Route path="/Luke"    element={<Luke />} />
<Route path="/Luke-1"  element={<Luke1 />} />
<Route path="/Luke-2"  element={<Luke2 />} />
<Route path="/Luke-3"  element={<Luke3 />} />
<Route path="/Luke-4"  element={<Luke4 />} />
<Route path="/Luke-5"  element={<Luke5 />} />
<Route path="/Luke-6"  element={<Luke6 />} />
<Route path="/Luke-7"  element={<Luke7 />} />
<Route path="/Luke-8"  element={<Luke8 />} />
<Route path="/Luke-9"  element={<Luke9 />} />
<Route path="/Luke-10" element={<Luke10 />} />
<Route path="/Luke-11" element={<Luke11 />} />
<Route path="/Luke-12" element={<Luke12 />} />
<Route path="/Luke-13" element={<Luke13 />} />
<Route path="/Luke-14" element={<Luke14 />} />
<Route path="/Luke-15" element={<Luke15 />} />
<Route path="/Luke-16" element={<Luke16 />} />
<Route path="/Luke-17" element={<Luke17 />} />
<Route path="/Luke-18" element={<Luke18 />} />
<Route path="/Luke-19" element={<Luke19 />} />
<Route path="/Luke-20" element={<Luke20 />} />
<Route path="/Luke-21" element={<Luke21 />} />
<Route path="/Luke-22" element={<Luke22 />} />
<Route path="/Luke-23" element={<Luke23 />} />
<Route path="/Luke-24" element={<Luke24 />} />

<Route path="/tamilbible-லூக்கா"    element={<Luke />} />
<Route path="/tamilbible-லூக்கா-1"  element={<Luke1 />} />
<Route path="/tamilbible-லூக்கா-2"  element={<Luke2 />} />
<Route path="/tamilbible-லூக்கா-3"  element={<Luke3 />} />
<Route path="/tamilbible-லூக்கா-4"  element={<Luke4 />} />
<Route path="/tamilbible-லூக்கா-5"  element={<Luke5 />} />
<Route path="/tamilbible-லூக்கா-6"  element={<Luke6 />} />
<Route path="/tamilbible-லூக்கா-7"  element={<Luke7 />} />
<Route path="/tamilbible-லூக்கா-8"  element={<Luke8 />} />
<Route path="/tamilbible-லூக்கா-9"  element={<Luke9 />} />
<Route path="/tamilbible-லூக்கா-10" element={<Luke10 />} />
<Route path="/tamilbible-லூக்கா-11" element={<Luke11 />} />
<Route path="/tamilbible-லூக்கா-12" element={<Luke12 />} />
<Route path="/tamilbible-லூக்கா-13" element={<Luke13 />} />
<Route path="/tamilbible-லூக்கா-14" element={<Luke14 />} />
<Route path="/tamilbible-லூக்கா-15" element={<Luke15 />} />
<Route path="/tamilbible-லூக்கா-16" element={<Luke16 />} />
<Route path="/tamilbible-லூக்கா-17" element={<Luke17 />} />
<Route path="/tamilbible-லூக்கா-18" element={<Luke18 />} />
<Route path="/tamilbible-லூக்கா-19" element={<Luke19 />} />
<Route path="/tamilbible-லூக்கா-20" element={<Luke20 />} />
<Route path="/tamilbible-லூக்கா-21" element={<Luke21 />} />
<Route path="/tamilbible-லூக்கா-22" element={<Luke22 />} />
<Route path="/tamilbible-லூக்கா-23" element={<Luke23 />} />
<Route path="/tamilbible-லூக்கா-24" element={<Luke24 />} />

<Route path="/John"    element={<John />} />
<Route path="/John-1"  element={<John1 />} />
<Route path="/John-2"  element={<John2 />} />
<Route path="/John-3"  element={<John3 />} />
<Route path="/John-4"  element={<John4 />} />
<Route path="/John-5"  element={<John5 />} />
<Route path="/John-6"  element={<John6 />} />
<Route path="/John-7"  element={<John7 />} />
<Route path="/John-8"  element={<John8 />} />
<Route path="/John-9"  element={<John9 />} />
<Route path="/John-10" element={<John10 />} />
<Route path="/John-11" element={<John11 />} />
<Route path="/John-12" element={<John12 />} />
<Route path="/John-13" element={<John13 />} />
<Route path="/John-14" element={<John14 />} />
<Route path="/John-15" element={<John15 />} />
<Route path="/John-16" element={<John16 />} />
<Route path="/John-17" element={<John17 />} />
<Route path="/John-18" element={<John18 />} />
<Route path="/John-19" element={<John19 />} />
<Route path="/John-20" element={<John20 />} />
<Route path="/John-21" element={<John21 />} />

<Route path="/Yovan"    element={<John />} />
<Route path="/Yovan-1"  element={<John1 />} />
<Route path="/Yovan-2"  element={<John2 />} />
<Route path="/Yovan-3"  element={<John3 />} />
<Route path="/Yovan-4"  element={<John4 />} />
<Route path="/Yovan-5"  element={<John5 />} />
<Route path="/Yovan-6"  element={<John6 />} />
<Route path="/Yovan-7"  element={<John7 />} />
<Route path="/Yovan-8"  element={<John8 />} />
<Route path="/Yovan-9"  element={<John9 />} />
<Route path="/Yovan-10" element={<John10 />} />
<Route path="/Yovan-11" element={<John11 />} />
<Route path="/Yovan-12" element={<John12 />} />
<Route path="/Yovan-13" element={<John13 />} />
<Route path="/Yovan-14" element={<John14 />} />
<Route path="/Yovan-15" element={<John15 />} />
<Route path="/Yovan-16" element={<John16 />} />
<Route path="/Yovan-17" element={<John17 />} />
<Route path="/Yovan-18" element={<John18 />} />
<Route path="/Yovan-19" element={<John19 />} />
<Route path="/Yovan-20" element={<John20 />} />
<Route path="/Yovan-21" element={<John21 />} />


<Route path="/tamilbible-யோவான்"    element={<John />} />
<Route path="/tamilbible-யோவான்-1"  element={<John1 />} />
<Route path="/tamilbible-யோவான்-2"  element={<John2 />} />
<Route path="/tamilbible-யோவான்-3"  element={<John3 />} />
<Route path="/tamilbible-யோவான்-4"  element={<John4 />} />
<Route path="/tamilbible-யோவான்-5"  element={<John5 />} />
<Route path="/tamilbible-யோவான்-6"  element={<John6 />} />
<Route path="/tamilbible-யோவான்-7"  element={<John7 />} />
<Route path="/tamilbible-யோவான்-8"  element={<John8 />} />
<Route path="/tamilbible-யோவான்-9"  element={<John9 />} />
<Route path="/tamilbible-யோவான்-10" element={<John10 />} />
<Route path="/tamilbible-யோவான்-11" element={<John11 />} />
<Route path="/tamilbible-யோவான்-12" element={<John12 />} />
<Route path="/tamilbible-யோவான்-13" element={<John13 />} />
<Route path="/tamilbible-யோவான்-14" element={<John14 />} />
<Route path="/tamilbible-யோவான்-15" element={<John15 />} />
<Route path="/tamilbible-யோவான்-16" element={<John16 />} />
<Route path="/tamilbible-யோவான்-17" element={<John17 />} />
<Route path="/tamilbible-யோவான்-18" element={<John18 />} />
<Route path="/tamilbible-யோவான்-19" element={<John19 />} />
<Route path="/tamilbible-யோவான்-20" element={<John20 />} />
<Route path="/tamilbible-யோவான்-21" element={<John21 />} />

<Route path="/tamilbible-அப்போஸ்தலர்"    element={<Acts />} />
<Route path="/tamilbible-அப்போஸ்தலர்-1"  element={<Acts1 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-2"  element={<Acts2 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-3"  element={<Acts3 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-4"  element={<Acts4 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-5"  element={<Acts5 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-6"  element={<Acts6 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-7"  element={<Acts7 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-8"  element={<Acts8 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-9"  element={<Acts9 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-10" element={<Acts10 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-11" element={<Acts11 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-12" element={<Acts12 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-13" element={<Acts13 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-14" element={<Acts14 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-15" element={<Acts15 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-16" element={<Acts16 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-17" element={<Acts17 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-18" element={<Acts18 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-19" element={<Acts19 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-20" element={<Acts20 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-21" element={<Acts21 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-22" element={<Acts22 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-23" element={<Acts23 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-24" element={<Acts24 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-25" element={<Acts25 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-26" element={<Acts26 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-27" element={<Acts27 />} />
<Route path="/tamilbible-அப்போஸ்தலர்-28" element={<Acts28 />} />

<Route path="/Acts"    element={<Acts />} />
<Route path="/Acts-1"  element={<Acts1 />} />
<Route path="/Acts-2"  element={<Acts2 />} />
<Route path="/Acts-3"  element={<Acts3 />} />
<Route path="/Acts-4"  element={<Acts4 />} />
<Route path="/Acts-5"  element={<Acts5 />} />
<Route path="/Acts-6"  element={<Acts6 />} />
<Route path="/Acts-7"  element={<Acts7 />} />
<Route path="/Acts-8"  element={<Acts8 />} />
<Route path="/Acts-9"  element={<Acts9 />} />
<Route path="/Acts-10" element={<Acts10 />} />
<Route path="/Acts-11" element={<Acts11 />} />
<Route path="/Acts-12" element={<Acts12 />} />
<Route path="/Acts-13" element={<Acts13 />} />
<Route path="/Acts-14" element={<Acts14 />} />
<Route path="/Acts-15" element={<Acts15 />} />
<Route path="/Acts-16" element={<Acts16 />} />
<Route path="/Acts-17" element={<Acts17 />} />
<Route path="/Acts-18" element={<Acts18 />} />
<Route path="/Acts-19" element={<Acts19 />} />
<Route path="/Acts-20" element={<Acts20 />} />
<Route path="/Acts-21" element={<Acts21 />} />
<Route path="/Acts-22" element={<Acts22 />} />
<Route path="/Acts-23" element={<Acts23 />} />
<Route path="/Acts-24" element={<Acts24 />} />
<Route path="/Acts-25" element={<Acts25 />} />
<Route path="/Acts-26" element={<Acts26 />} />
<Route path="/Acts-27" element={<Acts27 />} />
<Route path="/Acts-28" element={<Acts28 />} />

<Route path="/Apposthalar-Nadapadigal"    element={<Acts />} />
<Route path="/Apposthalar-Nadapadigal-1"  element={<Acts1 />} />
<Route path="/Apposthalar-Nadapadigal-2"  element={<Acts2 />} />
<Route path="/Apposthalar-Nadapadigal-3"  element={<Acts3 />} />
<Route path="/Apposthalar-Nadapadigal-4"  element={<Acts4 />} />
<Route path="/Apposthalar-Nadapadigal-5"  element={<Acts5 />} />
<Route path="/Apposthalar-Nadapadigal-6"  element={<Acts6 />} />
<Route path="/Apposthalar-Nadapadigal-7"  element={<Acts7 />} />
<Route path="/Apposthalar-Nadapadigal-8"  element={<Acts8 />} />
<Route path="/Apposthalar-Nadapadigal-9"  element={<Acts9 />} />
<Route path="/Apposthalar-Nadapadigal-10" element={<Acts10 />} />
<Route path="/Apposthalar-Nadapadigal-11" element={<Acts11 />} />
<Route path="/Apposthalar-Nadapadigal-12" element={<Acts12 />} />
<Route path="/Apposthalar-Nadapadigal-13" element={<Acts13 />} />
<Route path="/Apposthalar-Nadapadigal-14" element={<Acts14 />} />
<Route path="/Apposthalar-Nadapadigal-15" element={<Acts15 />} />
<Route path="/Apposthalar-Nadapadigal-16" element={<Acts16 />} />
<Route path="/Apposthalar-Nadapadigal-17" element={<Acts17 />} />
<Route path="/Apposthalar-Nadapadigal-18" element={<Acts18 />} />
<Route path="/Apposthalar-Nadapadigal-19" element={<Acts19 />} />
<Route path="/Apposthalar-Nadapadigal-20" element={<Acts20 />} />
<Route path="/Apposthalar-Nadapadigal-21" element={<Acts21 />} />
<Route path="/Apposthalar-Nadapadigal-22" element={<Acts22 />} />
<Route path="/Apposthalar-Nadapadigal-23" element={<Acts23 />} />
<Route path="/Apposthalar-Nadapadigal-24" element={<Acts24 />} />
<Route path="/Apposthalar-Nadapadigal-25" element={<Acts25 />} />
<Route path="/Apposthalar-Nadapadigal-26" element={<Acts26 />} />
<Route path="/Apposthalar-Nadapadigal-27" element={<Acts27 />} />
<Route path="/Apposthalar-Nadapadigal-28" element={<Acts28 />} />

<Route path="/Romans"    element={<Romans />} />
<Route path="/Romans-1"  element={<Romans1 />} />
<Route path="/Romans-2"  element={<Romans2 />} />
<Route path="/Romans-3"  element={<Romans3 />} />
<Route path="/Romans-4"  element={<Romans4 />} />
<Route path="/Romans-5"  element={<Romans5 />} />
<Route path="/Romans-6"  element={<Romans6 />} />
<Route path="/Romans-7"  element={<Romans7 />} />
<Route path="/Romans-8"  element={<Romans8 />} />
<Route path="/Romans-9"  element={<Romans9 />} />
<Route path="/Romans-10" element={<Romans10 />} />
<Route path="/Romans-11" element={<Romans11 />} />
<Route path="/Romans-12" element={<Romans12 />} />
<Route path="/Romans-13" element={<Romans13 />} />
<Route path="/Romans-14" element={<Romans14 />} />
<Route path="/Romans-15" element={<Romans15 />} />
<Route path="/Romans-16" element={<Romans16 />} />

<Route path="/tamilbible-ரோமர்"    element={<Romans />} />
<Route path="/tamilbible-ரோமர்-1"  element={<Romans1 />} />
<Route path="/tamilbible-ரோமர்-2"  element={<Romans2 />} />
<Route path="/tamilbible-ரோமர்-3"  element={<Romans3 />} />
<Route path="/tamilbible-ரோமர்-4"  element={<Romans4 />} />
<Route path="/tamilbible-ரோமர்-5"  element={<Romans5 />} />
<Route path="/tamilbible-ரோமர்-6"  element={<Romans6 />} />
<Route path="/tamilbible-ரோமர்-7"  element={<Romans7 />} />
<Route path="/tamilbible-ரோமர்-8"  element={<Romans8 />} />
<Route path="/tamilbible-ரோமர்-9"  element={<Romans9 />} />
<Route path="/tamilbible-ரோமர்-10" element={<Romans10 />} />
<Route path="/tamilbible-ரோமர்-11" element={<Romans11 />} />
<Route path="/tamilbible-ரோமர்-12" element={<Romans12 />} />
<Route path="/tamilbible-ரோமர்-13" element={<Romans13 />} />
<Route path="/tamilbible-ரோமர்-14" element={<Romans14 />} />
<Route path="/tamilbible-ரோமர்-15" element={<Romans15 />} />
<Route path="/tamilbible-ரோமர்-16" element={<Romans16 />} />

<Route path="/1-Corinthians"    element={<OneCorinthians />} />
<Route path="/1-Corinthians-1"  element={<OneCorinthians1 />} />
<Route path="/1-Corinthians-2"  element={<OneCorinthians2 />} />
<Route path="/1-Corinthians-3"  element={<OneCorinthians3 />} />
<Route path="/1-Corinthians-4"  element={<OneCorinthians4 />} />
<Route path="/1-Corinthians-5"  element={<OneCorinthians5 />} />
<Route path="/1-Corinthians-6"  element={<OneCorinthians6 />} />
<Route path="/1-Corinthians-7"  element={<OneCorinthians7 />} />
<Route path="/1-Corinthians-8"  element={<OneCorinthians8 />} />
<Route path="/1-Corinthians-9"  element={<OneCorinthians9 />} />
<Route path="/1-Corinthians-10" element={<OneCorinthians10 />} />
<Route path="/1-Corinthians-11" element={<OneCorinthians11 />} />
<Route path="/1-Corinthians-12" element={<OneCorinthians12 />} />
<Route path="/1-Corinthians-13" element={<OneCorinthians13 />} />
<Route path="/1-Corinthians-14" element={<OneCorinthians14 />} />
<Route path="/1-Corinthians-15" element={<OneCorinthians15 />} />
<Route path="/1-Corinthians-16" element={<OneCorinthians16 />} />

<Route path="/2-Corinthians"    element={<TwoCorinthians />} />
<Route path="/2-Corinthians-1"  element={<TwoCorinthians1 />} />
<Route path="/2-Corinthians-2"  element={<TwoCorinthians2 />} />
<Route path="/2-Corinthians-3"  element={<TwoCorinthians3 />} />
<Route path="/2-Corinthians-4"  element={<TwoCorinthians4 />} />
<Route path="/2-Corinthians-5"  element={<TwoCorinthians5 />} />
<Route path="/2-Corinthians-6"  element={<TwoCorinthians6 />} />
<Route path="/2-Corinthians-7"  element={<TwoCorinthians7 />} />
<Route path="/2-Corinthians-8"  element={<TwoCorinthians8 />} />
<Route path="/2-Corinthians-9"  element={<TwoCorinthians9 />} />
<Route path="/2-Corinthians-10" element={<TwoCorinthians10 />} />
<Route path="/2-Corinthians-11" element={<TwoCorinthians11 />} />
<Route path="/2-Corinthians-12" element={<TwoCorinthians12 />} />
<Route path="/2-Corinthians-13" element={<TwoCorinthians13 />} />

<Route path="/tamilbible-1-கொரிந்தியர்"    element={<OneCorinthians />} />
<Route path="/tamilbible-1-கொரிந்தியர்-1"  element={<OneCorinthians1 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-2"  element={<OneCorinthians2 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-3"  element={<OneCorinthians3 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-4"  element={<OneCorinthians4 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-5"  element={<OneCorinthians5 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-6"  element={<OneCorinthians6 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-7"  element={<OneCorinthians7 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-8"  element={<OneCorinthians8 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-9"  element={<OneCorinthians9 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-10" element={<OneCorinthians10 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-11" element={<OneCorinthians11 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-12" element={<OneCorinthians12 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-13" element={<OneCorinthians13 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-14" element={<OneCorinthians14 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-15" element={<OneCorinthians15 />} />
<Route path="/tamilbible-1-கொரிந்தியர்-16" element={<OneCorinthians16 />} />

<Route path="/tamilbible-2-கொரிந்தியர்"    element={<TwoCorinthians />} />
<Route path="/tamilbible-2-கொரிந்தியர்-1"  element={<TwoCorinthians1 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-2"  element={<TwoCorinthians2 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-3"  element={<TwoCorinthians3 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-4"  element={<TwoCorinthians4 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-5"  element={<TwoCorinthians5 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-6"  element={<TwoCorinthians6 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-7"  element={<TwoCorinthians7 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-8"  element={<TwoCorinthians8 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-9"  element={<TwoCorinthians9 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-10" element={<TwoCorinthians10 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-11" element={<TwoCorinthians11 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-12" element={<TwoCorinthians12 />} />
<Route path="/tamilbible-2-கொரிந்தியர்-13" element={<TwoCorinthians13 />} />

<Route path="/Galatians"    element={<Galatians />} />
<Route path="/Galatians-1"  element={<Galatians1 />} />
<Route path="/Galatians-2"  element={<Galatians2 />} />
<Route path="/Galatians-3"  element={<Galatians3 />} />
<Route path="/Galatians-4"  element={<Galatians4 />} />
<Route path="/Galatians-5"  element={<Galatians5 />} />
<Route path="/Galatians-6"  element={<Galatians6 />} />

<Route path="/tamilbible-கலாத்தியர்"    element={<Galatians />} />
<Route path="/tamilbible-கலாத்தியர்-1"  element={<Galatians1 />} />
<Route path="/tamilbible-கலாத்தியர்-2"  element={<Galatians2 />} />
<Route path="/tamilbible-கலாத்தியர்-3"  element={<Galatians3 />} />
<Route path="/tamilbible-கலாத்தியர்-4"  element={<Galatians4 />} />
<Route path="/tamilbible-கலாத்தியர்-5"  element={<Galatians5 />} />
<Route path="/tamilbible-கலாத்தியர்-6"  element={<Galatians6 />} />

<Route path="/Ephesians"    element={<Ephesians />} />
<Route path="/Ephesians-1"  element={<Ephesians1 />} />
<Route path="/Ephesians-2"  element={<Ephesians2 />} />
<Route path="/Ephesians-3"  element={<Ephesians3 />} />
<Route path="/Ephesians-4"  element={<Ephesians4 />} />
<Route path="/Ephesians-5"  element={<Ephesians5 />} />
<Route path="/Ephesians-6"  element={<Ephesians6 />} />

<Route path="/tamilbible-எபேசியர்"    element={<Ephesians />} />
<Route path="/tamilbible-எபேசியர்-1"  element={<Ephesians1 />} />
<Route path="/tamilbible-எபேசியர்-2"  element={<Ephesians2 />} />
<Route path="/tamilbible-எபேசியர்-3"  element={<Ephesians3 />} />
<Route path="/tamilbible-எபேசியர்-4"  element={<Ephesians4 />} />
<Route path="/tamilbible-எபேசியர்-5"  element={<Ephesians5 />} />
<Route path="/tamilbible-எபேசியர்-6"  element={<Ephesians6 />} />

<Route path="/Philippians"    element={<Philippians />} />
<Route path="/Philippians-1"  element={<Philippians1 />} />
<Route path="/Philippians-2"  element={<Philippians2 />} />
<Route path="/Philippians-3"  element={<Philippians3 />} />
<Route path="/Philippians-4"  element={<Philippians4 />} />

<Route path="/tamilbible-பிலிப்பியர்"    element={<Philippians />} />
<Route path="/tamilbible-பிலிப்பியர்-1"  element={<Philippians1 />} />
<Route path="/tamilbible-பிலிப்பியர்-2"  element={<Philippians2 />} />
<Route path="/tamilbible-பிலிப்பியர்-3"  element={<Philippians3 />} />
<Route path="/tamilbible-பிலிப்பியர்-4"  element={<Philippians4 />} />

<Route path="/Colossians"    element={<Colossians />} />
<Route path="/Colossians-1"  element={<Colossians1 />} />
<Route path="/Colossians-2"  element={<Colossians2 />} />
<Route path="/Colossians-3"  element={<Colossians3 />} />
<Route path="/Colossians-4"  element={<Colossians4 />} />

<Route path="/tamilbible-கொலோசெயர்"    element={<Colossians />} />
<Route path="/tamilbible-கொலோசெயர்-1"  element={<Colossians1 />} />
<Route path="/tamilbible-கொலோசெயர்-2"  element={<Colossians2 />} />
<Route path="/tamilbible-கொலோசெயர்-3"  element={<Colossians3 />} />
<Route path="/tamilbible-கொலோசெயர்-4"  element={<Colossians4 />} />

<Route path="/1-Thessalonians"    element={<OneThessalonians />} />
<Route path="/1-Thessalonians-1"  element={<OneThessalonians1 />} />
<Route path="/1-Thessalonians-2"  element={<OneThessalonians2 />} />
<Route path="/1-Thessalonians-3"  element={<OneThessalonians3 />} />
<Route path="/1-Thessalonians-4"  element={<OneThessalonians4 />} />
<Route path="/1-Thessalonians-5"  element={<OneThessalonians5 />} />

<Route path="/tamilbible-1-தெசலோனிக்கேயர்"    element={<OneThessalonians />} />
<Route path="/tamilbible-1-தெசலோனிக்கேயர்-1"  element={<OneThessalonians1 />} />
<Route path="/tamilbible-1-தெசலோனிக்கேயர்-2"  element={<OneThessalonians2 />} />
<Route path="/tamilbible-1-தெசலோனிக்கேயர்-3"  element={<OneThessalonians3 />} />
<Route path="/tamilbible-1-தெசலோனிக்கேயர்-4"  element={<OneThessalonians4 />} />
<Route path="/tamilbible-1-தெசலோனிக்கேயர்-5"  element={<OneThessalonians5 />} />

<Route path="/2-Thessalonians"    element={<TwoThessalonians />} />
<Route path="/2-Thessalonians-1"  element={<TwoThessalonians1 />} />
<Route path="/2-Thessalonians-2"  element={<TwoThessalonians2 />} />
<Route path="/2-Thessalonians-3"  element={<TwoThessalonians3 />} />

<Route path="/tamilbible-2-தெசலோனிக்கேயர்"    element={<TwoThessalonians />} />
<Route path="/tamilbible-2-தெசலோனிக்கேயர்-1"  element={<TwoThessalonians1 />} />
<Route path="/tamilbible-2-தெசலோனிக்கேயர்-2"  element={<TwoThessalonians2 />} />
<Route path="/tamilbible-2-தெசலோனிக்கேயர்-3"  element={<TwoThessalonians3 />} />

<Route path="/1-Timothy"    element={<OneTimothy />} />
<Route path="/1-Timothy-1"  element={<OneTimothy1 />} />
<Route path="/1-Timothy-2"  element={<OneTimothy2 />} />
<Route path="/1-Timothy-3"  element={<OneTimothy3 />} />
<Route path="/1-Timothy-4"  element={<OneTimothy4 />} />
<Route path="/1-Timothy-5"  element={<OneTimothy5 />} />
<Route path="/1-Timothy-5"  element={<OneTimothy6 />} />

<Route path="/tamilbible-1-தீமோத்தேயு"    element={<OneTimothy />} />
<Route path="/tamilbible-1-தீமோத்தேயு-1"  element={<OneTimothy1 />} />
<Route path="/tamilbible-1-தீமோத்தேயு-2"  element={<OneTimothy2 />} />
<Route path="/tamilbible-1-தீமோத்தேயு-3"  element={<OneTimothy3 />} />
<Route path="/tamilbible-1-தீமோத்தேயு-4"  element={<OneTimothy4 />} />
<Route path="/tamilbible-1-தீமோத்தேயு-5"  element={<OneTimothy5 />} />
<Route path="/tamilbible-1-தீமோத்தேயு-6"  element={<OneTimothy6 />} />

<Route path="/2-Timothy"    element={<TwoTimothy />} />
<Route path="/2-Timothy-1"  element={<TwoTimothy1 />} />
<Route path="/2-Timothy-2"  element={<TwoTimothy2 />} />
<Route path="/2-Timothy-3"  element={<TwoTimothy3 />} />
<Route path="/2-Timothy-4"  element={<TwoTimothy4 />} />

<Route path="/tamilbible-2-தீமோத்தேயு"    element={<TwoTimothy />} />
<Route path="/tamilbible-2-தீமோத்தேயு-1"  element={<TwoTimothy1 />} />
<Route path="/tamilbible-2-தீமோத்தேயு-2"  element={<TwoTimothy2 />} />
<Route path="/tamilbible-2-தீமோத்தேயு-3"  element={<TwoTimothy3 />} />
<Route path="/tamilbible-2-தீமோத்தேயு-4"  element={<TwoTimothy4 />} />

<Route path="/Titus"    element={<Titus />} />
<Route path="/Titus-1"  element={<Titus1 />} />
<Route path="/Titus-2"  element={<Titus2 />} />
<Route path="/Titus-3"  element={<Titus3 />} />

<Route path="/tamilbible-தீத்து"    element={<Titus />} />
<Route path="/tamilbible-தீத்து-1"  element={<Titus1 />} />
<Route path="/tamilbible-தீத்து-2"  element={<Titus2 />} />
<Route path="/tamilbible-தீத்து-3"  element={<Titus3 />} />

<Route path="/Philemon"    element={<Philemon />} />
<Route path="/Philemon-1"  element={<Philemon1 />} />

<Route path="/tamilbible-பிலேமோன்"    element={<Philemon />} />
<Route path="/tamilbible-பிலேமோன்-1"  element={<Philemon1 />} />

<Route path="/Hebrews"    element={<Hebrews />} />
<Route path="/Hebrews-1"  element={<Hebrews1 />} />
<Route path="/Hebrews-2"  element={<Hebrews2 />} />
<Route path="/Hebrews-3"  element={<Hebrews3 />} />
<Route path="/Hebrews-4"  element={<Hebrews4 />} />
<Route path="/Hebrews-5"  element={<Hebrews5 />} />
<Route path="/Hebrews-6"  element={<Hebrews6 />} />
<Route path="/Hebrews-7"  element={<Hebrews7 />} />
<Route path="/Hebrews-8"  element={<Hebrews8 />} />
<Route path="/Hebrews-9"  element={<Hebrews9 />} />
<Route path="/Hebrews-10" element={<Hebrews10 />} />
<Route path="/Hebrews-11" element={<Hebrews11 />} />
<Route path="/Hebrews-12" element={<Hebrews12 />} />
<Route path="/Hebrews-13" element={<Hebrews13 />} />

<Route path="/tamilbible-எபிரெயர்"    element={<Hebrews />} />
<Route path="/tamilbible-எபிரெயர்-1"  element={<Hebrews1 />} />
<Route path="/tamilbible-எபிரெயர்-2"  element={<Hebrews2 />} />
<Route path="/tamilbible-எபிரெயர்-3"  element={<Hebrews3 />} />
<Route path="/tamilbible-எபிரெயர்-4"  element={<Hebrews4 />} />
<Route path="/tamilbible-எபிரெயர்-5"  element={<Hebrews5 />} />
<Route path="/tamilbible-எபிரெயர்-6"  element={<Hebrews6 />} />
<Route path="/tamilbible-எபிரெயர்-7"  element={<Hebrews7 />} />
<Route path="/tamilbible-எபிரெயர்-8"  element={<Hebrews8 />} />
<Route path="/tamilbible-எபிரெயர்-9"  element={<Hebrews9 />} />
<Route path="/tamilbible-எபிரெயர்-10" element={<Hebrews10 />} />
<Route path="/tamilbible-எபிரெயர்-11" element={<Hebrews11 />} />
<Route path="/tamilbible-எபிரெயர்-12" element={<Hebrews12 />} />
<Route path="/tamilbible-எபிரெயர்-13" element={<Hebrews13 />} />

<Route path="/James"    element={<James />} />
<Route path="/James-1"  element={<James1 />} />
<Route path="/James-2"  element={<James2 />} />
<Route path="/James-3"  element={<James3 />} />
<Route path="/James-4"  element={<James4 />} />
<Route path="/James-5"  element={<James5 />} />

<Route path="/tamilbible-யாக்கோபு"    element={<James />} />
<Route path="/tamilbible-யாக்கோபு-1"  element={<James1 />} />
<Route path="/tamilbible-யாக்கோபு-2"  element={<James2 />} />
<Route path="/tamilbible-யாக்கோபு-3"  element={<James3 />} />
<Route path="/tamilbible-யாக்கோபு-4"  element={<James4 />} />
<Route path="/tamilbible-யாக்கோபு-5"  element={<James5 />} />

<Route path="/1-Peter"    element={<OnePeter />} />
<Route path="/1-Peter-1"  element={<OnePeter1 />} />
<Route path="/1-Peter-2"  element={<OnePeter2 />} />
<Route path="/1-Peter-3"  element={<OnePeter3 />} />
<Route path="/1-Peter-4"  element={<OnePeter4 />} />
<Route path="/1-Peter-5"  element={<OnePeter5 />} />

<Route path="/tamilbible-1-பேதுரு"    element={<OnePeter />} />
<Route path="/tamilbible-1-பேதுரு-1"  element={<OnePeter1 />} />
<Route path="/tamilbible-1-பேதுரு-2"  element={<OnePeter2 />} />
<Route path="/tamilbible-1-பேதுரு-3"  element={<OnePeter3 />} />
<Route path="/tamilbible-1-பேதுரு-4"  element={<OnePeter4 />} />
<Route path="/tamilbible-1-பேதுரு-5"  element={<OnePeter5 />} />

<Route path="/tamilbible-2-பேதுரு"    element={<TwoPeter />} />
<Route path="/tamilbible-2-பேதுரு-1"  element={<TwoPeter1 />} />
<Route path="/tamilbible-2-பேதுரு-2"  element={<TwoPeter2 />} />
<Route path="/tamilbible-2-பேதுரு-3"  element={<TwoPeter3 />} />

<Route path="/2-Peter"    element={<TwoPeter />} />
<Route path="/2-Peter-1"  element={<TwoPeter1 />} />
<Route path="/2-Peter-2"  element={<TwoPeter2 />} />
<Route path="/2-Peter-3"  element={<TwoPeter3 />} />

<Route path="/1-John"    element={<OneJohn />} />
<Route path="/1-John-1"  element={<OneJohn1 />} />
<Route path="/1-John-2"  element={<OneJohn2 />} />
<Route path="/1-John-3"  element={<OneJohn3 />} />
<Route path="/1-John-4"  element={<OneJohn4 />} />
<Route path="/1-John-5"  element={<OneJohn5 />} />

<Route path="/1-John"    element={<OneJohn />} />
<Route path="/1-John-1"  element={<OneJohn1 />} />
<Route path="/1-John-2"  element={<OneJohn2 />} />
<Route path="/1-John-3"  element={<OneJohn3 />} />
<Route path="/1-John-4"  element={<OneJohn4 />} />
<Route path="/1-John-5"  element={<OneJohn5 />} />

<Route path="/tamilbible-1-யோவான்"    element={<OneJohn />} />
<Route path="/tamilbible-1-யோவான்-1"  element={<OneJohn1 />} />
<Route path="/tamilbible-1-யோவான்-2"  element={<OneJohn2 />} />
<Route path="/tamilbible-1-யோவான்-3"  element={<OneJohn3 />} />
<Route path="/tamilbible-1-யோவான்-4"  element={<OneJohn4 />} />
<Route path="/tamilbible-1-யோவான்-5"  element={<OneJohn5 />} />

<Route path="/2-John"    element={<TwoJohn />} />
<Route path="/2-John-1"  element={<TwoJohn1 />} />

<Route path="/tamilbible-2-யோவான்"    element={<TwoJohn />} />
<Route path="/tamilbible-2-யோவான்-1"  element={<TwoJohn1 />} />

<Route path="/3-John"    element={<ThreeJohn />} />
<Route path="/3-John-1"  element={<ThreeJohn1 />} />

<Route path="/tamilbible-3-யோவான்"    element={<ThreeJohn />} />
<Route path="/tamilbible-3-யோவான்-1"  element={<ThreeJohn1 />} />

<Route path="/Jude"    element={<Jude />} />
<Route path="/Jude-1"  element={<Jude1 />} />

<Route path="/tamilbible-யூதா"    element={<Jude />} />
<Route path="/tamilbible-யூதா-1"  element={<Jude1 />} />

<Route path="/Revelation"    element={<Revelation />} />
<Route path="/Revelation-1"  element={<Revelation1 />} />
<Route path="/Revelation-2"  element={<Revelation2 />} />
<Route path="/Revelation-3"  element={<Revelation3 />} />
<Route path="/Revelation-4"  element={<Revelation4 />} />
<Route path="/Revelation-5"  element={<Revelation5 />} />
<Route path="/Revelation-6"  element={<Revelation6 />} />
<Route path="/Revelation-7"  element={<Revelation7 />} />
<Route path="/Revelation-8"  element={<Revelation8 />} />
<Route path="/Revelation-9"  element={<Revelation9 />} />
<Route path="/Revelation-10" element={<Revelation10 />} />
<Route path="/Revelation-11" element={<Revelation11 />} />
<Route path="/Revelation-12" element={<Revelation12 />} />
<Route path="/Revelation-13" element={<Revelation13 />} />
<Route path="/Revelation-14" element={<Revelation14 />} />
<Route path="/Revelation-15" element={<Revelation15 />} />
<Route path="/Revelation-16" element={<Revelation16 />} />
<Route path="/Revelation-17" element={<Revelation17 />} />
<Route path="/Revelation-18" element={<Revelation18 />} />
<Route path="/Revelation-19" element={<Revelation19 />} />
<Route path="/Revelation-20" element={<Revelation20 />} />
<Route path="/Revelation-21" element={<Revelation21 />} />
<Route path="/Revelation-22" element={<Revelation22 />} />

<Route path="/tamilbible-வெளிப்படுத்துதல்"    element={<Revelation />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-1"  element={<Revelation1 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-2"  element={<Revelation2 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-3"  element={<Revelation3 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-4"  element={<Revelation4 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-5"  element={<Revelation5 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-6"  element={<Revelation6 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-7"  element={<Revelation7 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-8"  element={<Revelation8 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-9"  element={<Revelation9 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-10" element={<Revelation10 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-11" element={<Revelation11 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-12" element={<Revelation12 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-13" element={<Revelation13 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-14" element={<Revelation14 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-15" element={<Revelation15 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-16" element={<Revelation16 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-17" element={<Revelation17 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-18" element={<Revelation18 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-19" element={<Revelation19 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-20" element={<Revelation20 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-21" element={<Revelation21 />} />
<Route path="/tamilbible-வெளிப்படுத்துதல்-22" element={<Revelation22 />} />

  <Route path="*" element={<Navigate to="/home" />} />
</Routes>
</Suspense>

      </div>
      
      </div>

      <div className='center'>
      <hr className="hr"/>
      </div>
      <footer className="flex-rw">
  
  <ul className="footer-list-top">
    <li>
      <h4 className="footer-list-header">Our Social</h4></li>
    <li><a href='https://www.fb.com/tamilbibleid' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">FACE BOOK</a></li>
    <li><a href='https://www.instagram.com/tamilbible' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">INSTAGRAM</a></li>
    <li><a href='https://www.twitter.com/TamilBibleWords' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">TWITTER</a></li>

    <li><a href='https://www.youtube.com/thamilbible' itemprop="significantLink" target="_blank" className="generic-anchor footer-list-anchor">YOU TUBE</a></li>

    <li><a href='https://www.reddit.com/u/TamilBible' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">REDDIT</a></li>
  </ul>
  <ul className="footer-list-top">
    <li>
      <h4 className="footer-list-header">Contact Us</h4></li>


    <li><a href='tel:+919444888727' target="_blank" className="generic-anchor footer-list-anchor">Dial - 9444888727</a></li>
    <li><a href='tel:+919444414229' target="_blank" className="generic-anchor footer-list-anchor">Dial - 9444414229</a></li>
    <li><a href='https://whatsapp.com/channel/0029Va4izKQCMY0JLgsQtl1h' target="_blank" className="generic-anchor footer-list-anchor">What's App - 9444888727</a></li>
    <li><a href='https://www.t.me/tamilverse' target="_blank" className="generic-anchor footer-list-anchor">TELEGRAM</a></li>
    <li><a href='mailto:admin@tamilbibleverse.com' target="_blank" className="generic-anchor footer-list-anchor" rel="noreferrer"> Mail </a></li>
  </ul>
  <ul className="footer-list-top">
    <li>
      <h4 className="footer-list-header">Quick Links</h4></li>
    <li><a href='/home' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">HOME</a></li>
    <li><a href='/bibleindex' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">BIBLE INDEX</a></li>
    <li><a href='/tamilaudiobible' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">AUDIO BIBLE</a></li>
    <li><a href='/social' target="_blank" className="generic-anchor footer-list-anchor" itemprop="significantLink">SOCIAL</a></li>
    <li><a href='/contact' itemprop="significantLink" target="_blank" className="generic-anchor footer-list-anchor">CONTACT</a></li>
  </ul>

  
  <section className="footer-social-section flex-rw">
      <span className="footer-social-overlap footer-social-connect">
      STAY <span className="footer-social-small">IN</span> TOUCH
      </span>
      <span className="footer-social-overlap footer-social-icons-wrapper">
      <a href="https://www.t.me/tamilverse" className="generic-anchor" rel="noreferrer" target="_blank" title="Telegram" itemprop="significantLink"><IonIcon style={{ fontSize: '24px', color: 'white' }} icon={send} /></a>
      <a href="https://twitter.com/TamilBibleWords" className="generic-anchor" rel="noreferrer" target="_blank" title="Twitter" itemprop="significantLink"><IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoTwitter} /></a>
      <a href="https://www.youtube.com/thamilbible" className="generic-anchor" rel="noreferrer" target="_blank" title="Youtube" itemprop="significantLink"><IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoYoutube} /></a>
      <a href="https://www.facebook.com/tamilbibleverse" className="generic-anchor" rel="noreferrer" target="_blank" title="Facebook" itemprop="significantLink"><IonIcon style={{ fontSize: '24px', color: 'white' }} icon={logoFacebook} /></a>
      </span>
  </section>
  <section className="footer-bottom-section flex-rw">
<div className="footer-bottom-wrapper">   
<i className="fa fa-copyright" >
 
</i> &copy;  tamilbibleverse - 2025 - 2026 <p className="footer-address">
  &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
  <small>
    <a href='https://www.banish.in/'  className="generic-anchor" rel="nofollow">
    <span className='privacy'>Website built by</span>  <span className='yellow_color'> Banish.J </span> </a>
    &ensp;&ensp;&ensp; <a className='generic-anchor' href='tel:+919444414229'> ☎ +91-9444414229 </a>
    &ensp;&ensp;&ensp;<a href='https://www.jegan.me/'  className="generic-anchor" rel="nofollow">
    <span className='privacy'>Website Designed by</span>  <span className='greenish_blue_color'> Jegan.M </span> </a>
    </small></p>
    </div>
    <div className="footer-bottom-wrapper">
    <small>
      <a href="/privacy_policy" className="generic-anchor privacy" rel="nofollow">Privacy Policy</a>
    </small>
      </div>
  </section>
</footer>
      
    <ScrollToTop />
    </div>
    </Router>
    
  );
}

export default App;
