import React from 'react';
import '../.././App.css';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Hosea (){
return (
    <>
    <Helmet>
        <title> The book as the Prophet Hosea in Tamil Bible | ஓசியா  </title>
        <meta
          name="description"
          content="Hosea | ஓசியா   | The book as the Prophet Hosea. It is Hosea’s personal account of his prophetic messages to the children of God and to the world.|
          ஓசியா தீர்க்கதரிசன புஸ்தகம் | Tamil Bible Verse | Tamil Bible |"
        />
      </Helmet>
    <div className='body'>
        <h2 className='chapterh' align="center"> ஓசியா </h2>
        <div className='chapter'>
        <div className='introduction'>
        <p style={{"text-align": "justify"}} className='article content'>
            {/* Introductions of Tamilbible verses */}
			

		<h2>
			ஓசியா
		</h2>
		<h3>
			முகவுரை:
		</h3>
		<p>
			இனி நாம் பார்க்கவிருக்கிற 12 தீர்க்கதரிசிகளின் புத்தகங்களும் சிறிய தீர்க்கதரிசிகளின் புத்தகங்கள் என்று அழைக்கப்படுகின்றன. இந்தத் தீர்க்கதரிசிகள் சிறியவர்கள் என்ற அர்த்தத்தில் அல்ல, இந்தப் புத்தகங்கள் சிறியவைகளாக இருப்பதால், தீர்க்கதரிகளின், சிறிய புத்தகங்கள் என்று இவைகள் பெயரிடப்பட்டுள்ளன. மேலும் இந்தப் புத்தகங்கள் ஒரு குறிப்பிட்ட சம்பவத்தைமாத்திரம் உள்ளடக்குவதாக இருக்கும். பெரிய தீர்க்கதரிசிகளின் புத்தகத்தைப் பார்த்தால், பல வெவ்வேறுபட்ட சம்பவங்களையும், பல ஆண்டுகளுக் கிடையேயான குறிப்புக்களையும் உள்ளடக்கியிருப்பதை நாம் பார்க்கமுடிகிறது. இந்தப் புத்தகங்களைக்குறித்துப் படிக்கும்போது 3 காரியங்களை முன்னிறுத்திப் படித்தல் மிகவும் உதவக்கூடியதாக இருக்கும்.
		</p>
		<ol>
			<li>
				அமைப்பு: தீர்க்கதரிசி எங்கே, எப்போது ஊழியம் செய்தார் என்பதை அமைப்பு விவரிக்கும்
			</li>
			<li>
				பேசிய (எழுதிய) நபர்: புத்தகத்தின் தீர்க்கதரிசியைக்குறித்த குறிப்பையும் நாம் பார்க்க முயற்சிக்கலாம்.
			</li>
			<li>
				செய்தி: தீர்க்கதரிசியின் புத்தகம் கொடுக்கிற செய்தியையும் பார்க்கலாம். அந்தச் செய்தி இந்தநாட்களில் நம்முடைய வாழ்விற்கு எவ்வாறு நடைமுறைப் படுத்தப்பட வேண்டும் என்பதையும் நாம் கற்றுக்கொள்வோமாக!
			</li>
		</ol>
		<p>
			ஓசியா:
		</p>
		<p>
			ஓசியா என்றால், என்னவிதமான நபர் இவர் என்ற அர்த்தமாகும் என பிலோ என்பவர் சொல்கிறார். இந்தப் பெயருக்கு இரட்சிப்பு என்று அர்த்தமாகும் எனவும் மற்றவர்கள் கூறுகிறார்கள்.
		</p>
		<h2>
			1. அமைப்பு:
		</h2>
		<p>
			எசேக்கியா ராஜாவின் ஆரம்பக்காலத்தில் இந்தப் புத்தகம் எழுதப்பட்டது. கி.மு.755 முதல் 710 ஆண்டுகளில் ஓசியா ஊழியம்செய்தார். சிறிய தீர்க்கதரிசிகளின் புத்தகங்களிலே பெரிய புத்தகமாக ஓசியாவின் புத்தகம் இருக்கிறது. இந்தப் புத்தகத்தின் சம்பவங்களின்போது, இஸ்ரவேல் தேசம் மிகவும் செழிப்பை அனுபவித்துக் கொண்டிருந்த நேரமாகும். அப்படிப்பட்ட நேரத்திலும், 2ஆம் திக்லாத் பிலேசர் என்ற ராஜா வடக்கே ஆசீரியாவோடு கூட்டணிக்குள் சென்றுகொண்டிருந்தான். ஓசியாவிடம் செய்யுமாறு தேவன் சொன்ன காரியங்கள், அவன் அவ்வாறு செய்தவைகள், ஓசியாவை ஒரு பலமான தீர்க்கதரிசியாகக் காட்டுகிறது. முதல் 3 அதிகாரங்கள் இந்த உறவு மற்றும் அதன் முக்கியத்துவங்களைச் சுற்றிவருகின்றன.
		</p>
		<h3>
			உதாரணம்:
		</h3>
		<p>
			ஓசி-1: 2 கர்த்தர் ஓசியாவைக்கொண்டு உரைக்கத் தொடங்கின போது, கர்த்தர் ஓசியாவை நோக்கி: நீ போய், ஒரு சோரஸ்திரியையும் சோரப்பிள்ளைகளையும் உன்னிடமாகச் சேர்த்துக்கொள், தேசம் கர்த்தரை விட்டு விலகிச் சோரம்போயிற்று என்றார்.
		</p>
		<p>
			சோரஸ்திரீ என்பது ஒரு விபச்சாரியைக் குறிக்கிறது. அந்த விபச்சாரப்பெண், தன்னைவிட்டுச் சென்று துரோகம்பண்ணுவாள் என்பது கர்த்தருக்கும் ஓசியாவுக்கும் முன்பே தெரிந்திருந்தும், வெறுமனே உருவகமாக மாத்திரமல்ல, அவன் உண்மையிலே அவளை மனைவியாகச் சேர்த்துக்கொண்டு ஒரு கணவனாக இருந்து அவளை நேசிக்கவேண்டும் என்று கட்டளைபெற்றான். இஸ்ரவேல் தேசம் அந்நிய தேவர்களை ஆராதித்தல் என்ற வேசித்தனத்திற்குள் திரும்பத்திரும்பச் சென்றபோதும், கர்த்தர் அந்த தேசத்தை எவ்வாறு நேசிக்கிறார் என்பதை இது விளக்கிக் காட்டியது. தேவன் நம்மையும் எவ்வாறு நேசிக்கிறார் என்பதை யோசித்துப்பார்ப்போமா! பழையஏற்பாட்டில், இஸ்ரவேல் தேசம் தேவனுடைய மனைவிக்குரிய ஸ்தானத்தில் ஒப்பிடப்பட்டிருந்ததை நினைவில்கொள்ளவும்.
		</p>
		<p>
			ஏசா-54: 5-8 உன் சிருஷ்டிகரே உன் நாயகர் சேனைகளின் கர்த்தர் என்பது அவருடைய நாமம், இஸ்ரவேலின் பரிசுத்தா; உன் மீட்பர், அவர் சர்வபூமியின் தேவன் என்னப்படுவார். 6. கைவிடப்பட்டு மனம்நொந்தவளான ஸ்திரியைப்போலவும், இளம்பிராயத்தில் விவாகஞ்செய்து தள்ளப்பட்ட மனைவியைப்போலவும் இருக்கிற உன்னைக் கர்த்தர் அழைத்தார் என்று உன் தேவன் சொல்லுகிறார். 7. இமைப்பொழுது உன்னைக் கைவிட்டேன், ஆனாலும் உருக்கமான இரக்கங்களால் உன்னைச் சேர்த்துக்கொள்வேன். 8. அற்பகாலம் மூண்ட கோபத்தினால் என் முகத்தை இமைப்பொழுது உனக்கு மறைத்தேன், ஆனாலும் நித்திய கிருபையுடன் உனக்கு இரங்குவேன் என்று கர்த்தராகிய உன் மீட்பர் சொல்லுகிறார்.
		</p>
		<p>
			இஸ்ரவேல் தேசம் தேவனுடைய மனைவி என்றும், சபை கிறிஸ்துவின் மணவாட்டி என்று அழைக்கப்படுகிறது.
		</p>
		<h2>
			2. தீர்க்கதரிசி ஓசியா:
		</h2>
		<p>
			ஓசியா ஒரு தீர்க்கதரிசியின் மகனாக இருந்திருக்கவேண்டும் என்று முற்காலத்து யூதர் எழுத்தாளர்கள் நம்புகிறார்கள். அது உண்மையாக இருக்குமெனில், ஒருவேளை ஓசியா எலிசாவின் மகனாக இருந்திருக்கக்கூடும்.
		</p>
		<ul>
			<li>
				எலியா கி.மு.870 முதல் 849 வரை தீர்க்கதரிசனம் உரைத்தார்.
			</li>
			<li>
				எலிசா கி.மு.849 முதல் 797வரை தீர்க்கதரிசனம் உரைத்தார்.
			</li>
			<li>
				எலியா, எலிசாவுக்குப் பிறகு, எழுதப்பட்ட புத்தகத்தை உடைய முதல் தீர்க்கதரிசி யோனா ஆவார் (அவர் கி.மு.775 முதல் 760 வரை தீர்க்கதரிசனம் உரைத்தார்).
			</li>
			<li>
				ஆமோஸூம் கி.மு.760களில் தீர்க்கதரிசனம் உரைத்தவராவார்.
			</li>
			<li>
				ஆமோஸிற்கு அடுத்து, ஓசியா வந்தார்.
			</li>
			<li>
				ஏசாயா மற்றும் மீகாவின் காலத்தோடு ஓசியா இணைந்திருப்பதைப் பார்க்கிறோம்.
			</li>
		</ul>
		<p>
			இஸ்ரவேல் தேசம் (வடராஜ்யம்) மிகுந்த செழிப்பை அனுபவித்து, ஆனால் தேவனைவிட்டு மிகவும் தூரமாகச் சென்று கொண்டிருந்த போது, இந்த தேசத்திற்கு விரோதமாக ஓசியா தீர்க்கதரிசனம் உரைத்தார். அவருடைய தீர்க்கதரிசனத்தின் முடிவிலே, இஸ்ரவேலின் வல்லமையும், செழிப்பும் மிகவும் குறைவுபட்டு, கி.மு.722ல், இஸ்ரவேல் தேசமே இல்லாமல் போனது.
		</p>
		<p>
			ஓசி-1: 1 யூதா தேசத்து ராஜாக்களாகிய உசியா, யோதாம், ஆகாஸ், எசேக்கியா என்பவர்களின் நாட்களிலும், யோவாசின் குமாரனாகிய யெஸ்ரயேலின் ராஜாவாகிய யெரொபெயாம் என்பவனின் நாட்களிலும் பெயோpயின் குமாரனாகிய ஓசியாவுக்கு உண்டான கர்த்தருடைய வசனம்.
		</p>
		<p>
			&Psi; உசியா- கி.மு.790 முதல் 739
		</p>
		<p>
			&Psi; யோதாம்- கி.மு.750 முதல் 732
		</p>
		<p>
			&Psi; ஆகாஸ்- கி.மு.744 முதல் 715
		</p>
		<p>
			&Psi; எசேக்கியா- கி.மு.729-686
		</p>
		<p>
			&Psi;&nbsp; யெரொயொம்- கி.மு.793 முதல் 753 (யெஸ்ரயேல் = இஸ்ரவேலின் ராஜா). ஓசியா கி.மு.753ல் தீர்க்கதரிசனம் உரைக்க ஆரம்பித்து, 729ற்குப் பிறகு முடித்தார்.
		</p>
		<p>
			அதைத்தொடர்ந்து சுமார் கி.மு.735ல் ஓசியா, இஸ்ரவேலிலிருந்து யூதேயாவுக்குப் போயிருந்திருக்க வேண்டும் என்று தோன்றுகிறது. யூதேயாவுக்காக அவர் விண்ணப்பம்செய்ததையும் நாம் பார்க்கிறோம்.
		</p>
		<p>
			ஓசி-4: 15 இஸ்ரவேலே, நீ சோரம்போனாலும், யூதா வாகிலும் அந்தப் பாவத்துக் குள்ளாகாதிருப்பதாக, கில்காலுக்கு வராமலும், பெத்தாவேனுக்குப் போகாமலும் கர்த்தருடைய ஜீவனாணை என்று ஆணையிடாமலும் இருப்பீர்களாக.
		</p>
		<h2>
			3. செய்தி:
		</h2>
		<p>
			ஓசியாவின் புத்தகம், தன்னுடைய ஜனத்திற்கான தேவனுடைய துக்கத்தையும், முரட்டாட்டம் செய்கிற தனது மக்களுக்கு அவருடைய தொடரும் அன்பையும் வெளிப்படுத்திக் காட்டுகிறது. இந்தப் புத்தகம் துக்கத்தோடு ஆரம்பித்து, நம்பிக்கையோடு நிறைவடைகிறது.
		</p>
		<p>
			ஓசியாவின் புத்தகத்தில் காணப்படும் சில சிறந்த வார்த்தைகள்:
		</p>
		<p>
			&dagger;ஓசி-2: 14 இதோ, நான் அவளுக்கு நயங்காட்டி, அவளை வனாந்தரத்தில் அழைத்துக் கொண்டுபோய், அவளோடே பட்சமாய்ப் பேசி,
		</p>
		<p>
			&dagger;ஓசி-2: 19 நித்திய விவாகத்துக்கென்று உன்னை எனக்கு நியமித்துக் கொள்ளுவேன், நீதியும் நியாயமும் கிருபையும் உருக்க இரக்கமுமாய் உன்னை எனக்கு நியமித்துக் கொள்ளுவேன்.
		</p>
		<p>
			&dagger;ஓசி-2: 23 நான் அவளை எனக்கென்று பூமியிலே விதைத்து, இரக்கம் பெறாதிருந்தவளுக்கு இரங்குவேன், என் ஜனம் இல்லாதிருந்தவர் களைநோக்கி நீ என் ஜனமென்று சொல்லுவேன், அவர்கள் என் தேவனே என்பார்கள் என்றார்.
		</p>
		<p>
			&dagger;ஓசி-4: 6 என் ஜனங்கள் அறிவில்லாமை யினால் சங்காரமாகிறார்கள், நீ அறிவை வெறுத்தாய், ஆகையால் நீ என் ஆசாரியனாய் இராதபடிக்கு நானும் உன்னை வெறுத்து விடுவேன், நீ உன் தேவனுடைய வேதத்தை மறந்தாய், ஆகையால் நானும் உன் பிள்ளைகளை மறந்து விடுவேன்.
		</p>
		<p>
			&dagger;ஓசி-4: 9 ஆதலால் ஜனங்களுக்கு எப்படியோ ஆசாரியனுக்கும் அப்படியே, அவர்கள் வழிகளின்படி நான் அவர்களை விசாரித்து, அவர்கள் கிரியைகளின்படி அவர்களுக்குப் பலனளிப்பேன்.
		</p>
		<p>
			&dagger;ஓசி-5: 15 அவர்கள் தங்கள் குற்றங்களை உணர்ந்து, என் முகத்தைத் தேடுமட்டும் நான் என் ஸ்தானத்துக்குத் திரும்பிப் போய்விடுவேன், தங்கள் ஆபத்தில் என்னைக் கருத்தாய்த் தேடுவார்கள்.
		</p>
		<p>
			&dagger;ஓசி-6: 6 பலியை அல்ல இரக்கத்தையும், தகனபலிகளைப் பார்க்கிலும் தேவனை அறிகிற அறிவையும் விரும்புகிறேன்.
		</p>
		<p>
			&dagger; ஓசி-11: 1 இஸ்ரவேல் இளைஞனாயிருந்த போது நான் அவனை நேசித்தேன், எகிப்திலிருந்து என்னுடைய குமாரனை வரவழைத்தேன்.
		</p>
<p>
			&dagger; ஓசி-11: 4 மனுஷரைக் கட்டி இழுக்கிற அன்பின் கயிறுகளால் நான் அவர்களை இழுத்தேன், அவர்கள் கழுத்துகளின்மேல் இருந்த நுகத்தடியை எடுத்துப் போடுகிறவரைப் போல் இருந்து, அவர்கள் பட்சம்சாய்ந்து, அவர்களுக்கு ஆகாரங்கொடுத்தேன்.
		</p>
		<p>
			&dagger; ஓசி-14: 4 நான் அவர்கள் சீர்கேட்டைக் குணமாக்குவேன், அவர்களை மனப்பூர்வமாய்ச் சிநேகிப்பேன்.
		</p>
		<p>
			&dagger; ஓசி-14: 9 கர்த்தருடைய வழிகள் செம்மையானவைகள், நீதிமான்கள் அவைகளில் நடப்பார்கள், பாதகரோவென்றால் அவைகளில் இடறிவிழுவார்கள்.
		</p>
		<h2>
			புதிய ஏற்பாட்டிலே:
		</h2>
		<h5>
			&Psi;
			<span>
				விதைப்பதும் அறுப்பதும்:
			</span>
		</h5>
		<p>
			கலா-6: 7-8 மோசம் போகாதிருங்கள், தேவன் தம்மைப் பரியாசம் பண்ணவொட்டார், மனுஷன் எதை விதைக்கிறானோ அதையே அறுப்பான். 8. தன் மாம்சத்திற்கென்று விதைக்கிறவன் மாம்சத்தினால் அழிவை அறுப்பான், ஆவிக்கென்று விதைக்கிறவன் ஆவியினாலே நித்தியஜீவனை அறுப்பான்.
		</p>
		<p>
			ஓசி-8: 7 அவர்கள் காற்றை விதைத்து, சூறைக்காற்றை அறுப்பார்கள், விளைச்சல் அவர்களுக்கு இல்லை, கதிர் மாவைக் கொடுக்கமாட்டாது, கொடுத்தாலும் அந்நியா; அதை விழுங்குவார்கள்.
		</p>
		<p>
			ஓசி-10: 12 நீங்கள் நீதிக்கென்று விதைவிதையுங்கள், தயவுக்கொத்ததாய் அறுப்புஅறுங்கள், உங்கள் தரிசுநிலத்தைப் பண்படுத்துங்கள், கர்த்தர் வந்து உங்கள்மேல் நீதியை வருஷிக்கப் பண்ணுமட்டும், அவரைத் தேடக் காலமாயிருக்கிறது.
		</p>
		<h5>
			&Psi;
			<span>
				துதிகளின் பலி:
			</span>
		</h5>
		<p>
			எபி-13: 15 ஆகையால், அவருடைய நாமத்தைத் துதிக்கும் உதடுகளின் கனியாகிய ஸ்தோத்திரபலியை அவர்மூலமாய் எப்போதும் தேவனுக்குச் செலுத்தக்கடவோம்.
		</p>
		<p>
			ஓசி-14: 2 வார்த்தைகளைக் கொண்டு கர்த்தரிடத்தில் திரும்புங்கள், அவரை நோக்கி: தேவரீர் எல்லா அக்கிரமத்தையும் நீக்கி, எங்களைத் தயவாய் அங்கீகரித்தருளும், அப்பொழுது நாங்கள் எங்கள் உதடுகளின் காளைகளைச் செலுத்துவோம்.
		</p>
		<p>
			1கொரி-15: 54-55 அழிவுள்ளதாகிய இது அழியாமையையும், சாவுக்கேதுவாகிய இது சாவாமையையும் தரித்துக் கொள்ளும்போது, மரணம் ஜெயமாக விழுங்கப்பட்டது என்று எழுதியிருக்கிற வார்த்தை நிறைவேறும். 55. மரணமே! உன் கூர் எங்கே? பாதாளமே! உன் ஜெயம் எங்கே?
		</p>
		<p>
			ஓசி-13: 14 அவர்களை நான் பாதாளத்தின் வல்லமைக்கு நீங்கலாக்கி மீட்பேன், அவர்களை மரணத்துக்கு நீங்கலாக்கி விடுவிப்பேன், மரணமே, உன் வாதைகள் எங்கே? பாதாளமே, உன் சங்காரம் எங்கே?
		</p>
		<h2>
			ஓசியாவின் தொகுப்பு:
		</h2>
		<p>
			(மொத்தம் 14 அதிகாரங்கள் உள்ளன. இதை 3 தொகுப்புகளாப் பிரிக்கலாம்)
		</p>
		<ol>
			<li>
				அதிகாரங்கள் 1 முதல் 3: பரஸ்திரீயை விவாகம்செய்தல்
			</li>
		</ol>
		<h5>
			1. விவாகமும் 3 பிள்ளைகளும் (1)
		</h5>
		<p>
			அ. யெஸ்ரயேல்: தேவன் விதைப்பார் என்று அர்த்தம். யெகூவின் ஆட்சி முடிந்தது, சகரியா சொல்லப்படுதல் (2ராஜா-15: 10)
		</p>
		<p>
			ஆ. லோருகாமா: இரக்கம் கிடையாது என்று அர்த்தம். தேவனுடைய இரக்கம் முடிவுக்கு வந்துவிட்டது.
		</p>
		<p>
			இ. லோகம்மீ: என் ஜனமல்ல என்று அர்த்தம்.
		</p>
		<p>
			ஆனாலும் 10 முதல் 11 வசனஙக்ளில் நீங்கள் ஜீவனுள்ள தேவனுடைய பிள்ளைகளாக இருப்பீர்கள் என்று சொல்கிறார். தேவனுடைய இரக்கத்தை நாம் இங்கே பார்க்கிறோம்.
		</p>
		<h5>
			2. துரோகம்பண்ணிய மனைவியை தேவன் சரிக்கட்டுதல் (2)
		</h5>
		<p>
			அ. அவருடைய நோக்கமும் தண்டனையும் (2: 2-13)
		</p>
		<p>
			ஆ. அவருடைய புதுப்பித்தலும் இரக்கமும் (2: 14-23)
		</p>
		<p>
			இ. ஈஷி என்றால் கணவன் என்று அர்த்தம். பாகாலி என்றால் அடிமையை நடத்துபவர் என்று அர்த்தம்.
		</p>
		<h5>
			3. ஓசியா மீண்டும் தன் சொந்த மனைவியோடு இணைக்கப்படுதல் (3)
		</h5>
		<p>
			அதிகாரம் 3 இந்தப் முழுப்புத்தகத்தைக் குறித்த தொகுப்பையும் கொடுக்கிறது. ஓசியாவுக்கும் கோமாருக்கும் இடையேயான உறவு, தேவனுக்கும் இஸ்ரவேலுக்கும் இடையான உறவின் உருவகமாக இருக்கிது.
		</p>
		<ol>
			<li>
				அதிகாரங்கள் 4 முதல் 13: விபச்சாரமுள்ள மக்களிடம் தேவன் நடந்துகொள்ளும் விதம்:
			</li>
			<li>
				முறையீடு (4)
			</li>
			<li>
				வாக்குப் பண்ணப்பட்ட நியாயத்தீர்ப்பு (5)
			</li>
			<li>
				மனந் திரும்புதலுக்கான அழைப்பு (6: 1-4)
			</li>
			<li>
				தேவனின் வேதனை (6: 5 முதல் 7)
			</li>
			<li>
				காற்றை விதைத்து, சூறைக்காற்றை அறுத்தல் (8)
			</li>
			<li>
				வெளியேற்றப்படுதல் (9)
			</li>
			<li>
				அசீரியர்கள் இஸ்ரவேலை அழிப்பார்கள் என்ற செய்தி (10)
			</li>
			<li>
				தேவனுடைய சரித்திரப் போராட்டம் (11-13)
			</li>
		</ol>
		<p>
			III. அதிகாரம்-14: புதுப்பித்தலைக்குறித்த தேவனுடைய வாக்குத்தத்தம்.
		</p>
		<p>
			தம்முடைய மக்களுடைய பாவம் தேவனை வேதனைப் படுத்துகிறது. நாம் மனந்திரும்பி தேவனை அறிகிற அறிதலுக்குள் வரவேண்டும் என்று அவர் ஏங்குகிறார். கோமாரைப்போல பிடி வாதமுள்ளவர்களாக நாம் இருந்தபோதிலும், ஓசியாவைப்போல தேவன் நம்மையும் உண்மையாக நேசிக்கிறார்.
		</p>


		<h1>
	THE BOOK OF HOSEA
</h1>
<p>
	Hosea : 1 : 1 identifies the author of the book as the Prophet Hosea. It is Hosea&rsquo;s personal account of his prophetic messages to the children of God and to the world. Hosea is the only prophet of Israel who left any written prophecies which were recorded during the later years of his life.
</p>
<h2>
	Date of Writing
</h2>
<p>
	Hosea, the son of Beeri, prophesied for quite some time, from 785 to 725 B.C. The Book of Hosea was likely written between 755 and 725 B.C.
</p>
<p>
	Purpose of Writing: Hosea wrote this book to remind the Israelites&mdash;and us&mdash;that ours is a loving God whose loyalty to His covenant people is unwavering. In spite of Israel&rsquo;s continual turning to false gods, God&rsquo;s steadfast love is portrayed in the long-suffering husband of the unfaithful wife. Hosea&rsquo;s message is also one of warning to those who would turn their backs on God&rsquo;s love. Through the symbolic presentation of the marriage of Hosea and Gomer, God&rsquo;s love for the idolatrous nation of Israel is displayed in a rich metaphor in the themes of sin, judgment, and forgiving love.
</p>
<h3>
	Key Verses
</h3>
<p>
	Hosea 1:2, "When the LORD began to speak through Hosea, the LORD said to him, 'Go, take to yourself an adulterous wife and children of unfaithfulness, because the land is guilty of the vilest adultery in departing from the LORD.&rsquo;"
</p>
<p>
	Hosea 2:23, &ldquo;I will plant her for myself in the land; I will show my love to the one I called 'Not my loved one.' I will say to those called 'Not my people,' 'You are my people'; and they will say, 'You are my God.'&rdquo;
</p>
<p>
	Hosea 6:6, &ldquo;For I desire mercy, not sacrifice, and acknowledgment of God rather than burnt offerings.&rdquo;
</p>
<p>
	Hosea 14:2-4, &ldquo;Take words with you and return to the LORD. Say to him: 'Forgive all our sins and receive us graciously, that we may offer the fruit of our lips. Assyria cannot save us; we will not mount war-horses. We will never again say "Our gods" to what our own hands have made, for in you the fatherless find compassion.' I will heal their waywardness and love them freely, for my anger has turned away from them.&rdquo;
</p>
<blockquote>
	<p>
		Brief Summary
	</p>
</blockquote>
<p>
	The Book of Hosea can be divided into two parts: (1) Hosea 1:1-3:5 is a description of an adulterous wife and a faithful husband, symbolic of the unfaithfulness of Israel to God through idolatry, and (2) Hosea 4:1-14:9 contains the condemnation of Israel, especially Samaria, for the worship of idols and her eventual restoration.
</p>
<p>
	The first section of the book contains three distinctive poems illustrating how God&rsquo;s children returned time after time to idolatry. God commands Hosea to marry Gomer, but after bearing him three children, she walks away from Hosea to her lovers. The symbolic emphasis can be seen clearly in the first chapter as Hosea compares Israel&rsquo;s actions to turning from a marriage to life as a prostitute. The second section contains Hosea&rsquo;s denunciation of the Israelites but followed by the promises and the mercies of God.
</p>
<p>
	The Book of Hosea is a prophetic accounting of God&rsquo;s relentless love for His children. Since the beginning of time God&rsquo;s ungrateful and undeserving creation has been accepting God&rsquo;s love, grace, and mercy while still unable to refrain from its wickedness.
</p>
<p>
	The last part of Hosea shows how God&rsquo;s love once again restores His children as He forgets their misdeeds when they turn back to Him with a repentant heart. The prophetic message of Hosea foretells the coming of Israel&rsquo;s Messiah 700 years in the future. Hosea is quoted often in the New Testament.
</p>
<h4>
	Foreshadowings
</h4>
<p>
	Hosea 2:23 is the wonderful prophetic message from God to include the Gentiles [non-Jews] as His children as recorded also in Romans 9:25 and 1 Peter 2:10. Gentiles are not originally &ldquo;God&rsquo;s people,&rdquo; but through His mercy and grace, He has provided Jesus Christ, and by faith in Him we are grafted into the tree of His people (Romans 11:11-18). This is an amazing truth about the Church, one that is called a &ldquo;mystery&rdquo; because before Christ, God&rsquo;s people were considered to be the Jews alone. When Christ came, the Jews were temporarily blinded until the &ldquo;full number of the Gentiles has come in&rdquo; (Romans 11:25).
</p>
<h4>
	Practical Application
</h4>
<p>
	The Book of Hosea assures us of God&rsquo;s unconditional love for His people. But it is also a picture of how God is dishonored and angered by the actions of His children. How can a child who is given an abundance of love, mercy, and grace treat a Father with so much disrespect? Yet, we have done just that for centuries. As we consider how the Israelites turned their backs on God, we need to look no further than the mirror in front of us to see a reflection of those same Israelites.
</p>
<p>
	Only by remembering how much God has done for each of us will we be able to avoid rejecting the One who can give us eternal life in glory instead of the hell we deserve. It is essential that we learn to respect our Creator. Hosea has shown us God&rsquo;s heart of loving commitment. When we do sin, if we have a sorrowful heart filled with repentance, then God will bring us back to Himself and show His never-ending love to us (see 1 John 1:9).
</p>
            {/* End Introduction */}
        </p>

        </div>
        <div>
            <div className='chap-link'>
                <div className='btn'> <Link to="/Osiya-1"> 01</Link> </div>
                <div className='btn'> <Link to="/Osiya-2"> 02</Link> </div>
                <div className='btn'> <Link to="/Osiya-3"> 03</Link> </div>
                <div className='btn'> <Link to="/Osiya-4"> 04</Link> </div>
                <div className='btn'> <Link to="/Osiya-5"> 05</Link> </div>
                <div className='btn'> <Link to="/Osiya-6"> 06</Link> </div>
                <div className='btn'> <Link to="/Osiya-7"> 07</Link> </div>
                <div className='btn'> <Link to="/Osiya-8"> 08</Link> </div>
                <div className='btn'> <Link to="/Osiya-9"> 09</Link> </div>
                <div className='btn'> <Link to="/Osiya-10">10</Link> </div>
                <div className='btn'> <Link to="/Osiya-11">11</Link> </div>
                <div className='btn'> <Link to="/Osiya-12">12</Link> </div>
                <div className='btn'> <Link to="/Osiya-13">13</Link> </div>
                <div className='btn'> <Link to="/Osiya-14">14</Link> </div>
            </div>
        </div>
    </div>
    </div>
    </>
);
}

export default Hosea;